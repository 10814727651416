/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { conEvento } from "../Models/Coneccion";
import CopiarEvento from "./From/Copiar";
import EditarEvento from "./From/Edit";
import GestionPagos from "./GestionPagos";
import PagosEvento from "./InformacionPagos";
import EventoParticipantes from "./Participantes";
import VerEvento from "./Ver";
import { Evento, eventoNull } from "../Models";
import { Ruta } from "../../../../Template/Ruta";
import Estadisticas from "./Estadisticas";
import { Capacitacion, CapacitacionNull, conCapacitacion } from "../../Programas/Models";
import { Cargando } from "@victorequena22/component-bootstrap";
import InscribirParticipante from "./Inscribir";
export interface EventoType {
    evento: Evento
    programa: Capacitacion
    update: () => void
}
export var update = () => { };
export const EventoContext = createContext<EventoType>({ evento: eventoNull, programa: CapacitacionNull, update: () => { } });
export default function GestionEvento() {
    let { id, opcion } = useParams<any>();
    if (opcion === 'config' || opcion === 'certificado') return <></>
    const [evento, setevento] = useState<Evento>(eventoNull);
    const [programa, setprograma] = useState<Capacitacion>(CapacitacionNull);
    update = () => {
        conEvento().detalle(id, (e: Evento) => {
            if (JSON.stringify(e) !== JSON.stringify(evento)) setevento(e)
            conCapacitacion().detalle(e.programa, p => {
                if (JSON.stringify(p) !== JSON.stringify(programa)) setprograma(p)
            })
        })
    };
    useEffect(() => update(), []);
    useEffect(() => {
        var interval = setInterval(() => update(), 10000)
        return () => clearInterval(interval);
    }, []);
    return evento.id ? <EventoContext.Provider value={{ evento, programa, update }}>
        <Ruta permiso="EVE_PARTICIPANTES" path="/evento/gestionPagos/:id" component={GestionPagos} />
        <Ruta permiso="EVE_PRECIO" path="/evento/pagos/:id" component={PagosEvento} />
        <Ruta permiso="EVE_PARTICIPANTES" path="/evento/inscribir/:id" component={InscribirParticipante} />
        <Ruta permiso="EVE_PARTICIPANTES" path="/evento/participantes/:id" component={EventoParticipantes} />
        <Ruta permiso="EVE_CREAR" path="/evento/copiar/:id" component={CopiarEvento} />
        <Ruta permiso="EVE_EDITAR" path="/evento/edit/:id" component={EditarEvento} />
        <Ruta permiso="EVENTO" path="/evento/ver/:id" component={VerEvento} />
        <Ruta permiso="EVENTO" path="/evento/estadisticas/:id" component={Estadisticas} />
    </EventoContext.Provider> : <Cargando />
}
export const SeccionConsumer = EventoContext.Consumer;
export function getEvento() {
    return useContext(EventoContext).evento;
}
export function getPrograma() {
    return useContext(EventoContext).programa;
}