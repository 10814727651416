
import { Button, Row, Col } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { setText } from '../../../../Panel/ProtoPanes/DefaultData';
import { getFacilitador } from '../index';
import { Facilitador } from '../../Models';
import Template from '../Template';
export default function Editar() {
    const c = getFacilitador();
    return <Template icon="fa fa-edit" title="EDITAR EL FACILITADOR">
        <Edit data={{ ...c, ...c.data }} />
    </Template>
}
interface Props {
    data: Facilitador
}
class Edit extends Prototipo<Props> {
    constructor(p: any) {
        super(p)
        this.state = { ...p.data, descripcion: setText(p.data.descripcion), Redirect: false };
    }
    render = () => {
        let { setData, error, state, create } = this;
        if (state.Redirect) return <Redirect to={'/facilitador'} />
        return <Form data={state} setData={setData as any} error={error}>
            <Row className='mt-2'><Col xs={12}>
                <Button variant='success' className='float-right' onClick={create}>
                    <i className="fa fa-save"></i> Guardar
                </Button>
            </Col></Row>
        </Form>
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                this.con.actualizar(this.props.data.id, this.getDataSave(), () => {
                    this.procesar = true;
                    toast('FACILITADOR ACTUALIZADO', { type: toast.TYPE.SUCCESS });
                    this.setState({ Redirect: true });
                }, this.setError)
            }
        } else this.forceUpdate();
    }
}