import { useCallback, useMemo } from 'react'
import { Editable, withReact, Slate, ReactEditor, useSlate } from 'slate-react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history';
import { Element } from './Element';
import { Leaf } from './Mark';
import { hotKeysValue } from "@victorequena22/slate-bootstrap";
const SlateProvider = ({ value, setValue, children }: { value: any, setValue: (d: any) => void, children: any }) => {
    const editor = useMemo(() => withHistory(withReact(createEditor() as ReactEditor)), []);
    return <Slate editor={editor} value={value} onChange={setValue}>
        {children}
    </Slate>
}
export default SlateProvider;
export const EditArea = ({ change, value }: { change?: (d: any) => void, value?: any[] }) => {
    const renderElement = useCallback((props: any) => <Element {...props} />, [])
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
    const editor = useSlate();
    return <Editable key={'' + Math.random()}
        id='EditableHTML'
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck style={{ width: '100%', minWidth: '100px', minHeight: '30px' }}
        onChange={change} value={value}
        onKeyDown={(event: any) => hotKeysValue(editor, event)}
    />
}
