import { semaforo } from "@victorequena22/utiles";
import { Conexion, setCambio } from "../../../../Config/Coneccion";

export interface Participante {
    id: number;
    foto: string;
    nombre: string;
    nombre2: string | null;
    nacimiento: string;
    pais: string;
    estado: string;
    ciudad: string;
    direccion: string;
    telefono: string;
    correo: string;
    usuario: string;
    clave: string;
    code: string;
    referido: string;
    Profesión: string;
    Ocupación: string;
    documento: string;
    data: any;
    props: any;
    fecha: string;
    actualizado: string;
    estatus: number;
    eventos: number[];
    programas: number[];
}
export interface ParticipanteFila {
    id: number;//1
    nombre: string;//2
    foto: string;//3
    nacimiento: string;//4
    pais: string;//5
    estado: string;//6
    ciudad: string;//7
    direccion: string;//8
    telefono: string;//9
    correo: string;//10
    estatus: number;//11
    eventos: number[];//12
    programas: number[];//13
}
export const ParticipanteNull: Participante = {
    id: 0,
    nombre: '',
    nombre2: '',
    foto: '',
    nacimiento: '',
    pais: '',
    estado: '',
    ciudad: '',
    direccion: '',
    telefono: '',
    correo: '',
    estatus: 1,
    usuario: '@',
    clave: '',
    code: '',
    referido: '',
    Profesión: '',
    Ocupación: '',
    documento: '',
    data: {},
    props: {},
    fecha: '',
    actualizado: '',
    eventos: [],
    programas: []
}
type parRest = [number, string, string, string, string, string, string, string, string, string, number, number[], number[]];
export function arrayToParticipante(pars: parRest[]): ParticipanteFila[] {
    return pars.map(p => {
        return {
            id: p[0],
            foto: p[1],
            nombre: p[2],
            nacimiento: p[3],
            pais: p[4],
            ciudad: p[5],
            estado: p[6],
            direccion: p[7],
            telefono: p[8],
            correo: p[9],
            estatus: p[10],
            eventos: p[11],
            programas: p[12]
        }
    })
}

class ParticipanteConecction extends Conexion {
    clase = 'Participante';
}
export function conParticipante() {
    return new ParticipanteConecction();
}
export function addOrUpdateParticipante(data: ParticipanteFila[], setData: (d: ParticipanteFila[]) => void, setNot?: () => void) {
    conParticipante().cambios((f: parRest[]) => {
        const rest = arrayToParticipante(f);
        let dataRest = [...data];
        rest.forEach((r) => {
            if (dataRest.some(c => c.id === r.id)) {
                dataRest = dataRest.map(c => c.id === r.id ? r : c);
            } else {
                dataRest = [...dataRest, r];
            }
        })
        setData(dataRest);
    }, setNot);
}

function setCambioParticipante(data: any[], c: ParticipanteFila[]) {
    return setCambio(arrayToParticipante(data), c);
}
export const participanteUpdate = (c: ParticipanteConecction, u: ParticipanteFila[], setData: (c: ParticipanteFila[]) => void) => {
    const s = semaforo()
    if (s.isTurn('Participante')) c.cambios(data => {
        if (data.length) setData(setCambioParticipante(data, u));
        s.next();
    }, () => s.next());
}