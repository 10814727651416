import { AliadoFila, conAliado } from './Aliado'
import { Button, ButtonGroup } from 'react-bootstrap';
import FormUsuario from './FormUsuario';
import Fila from './Fila';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { ListButonLink } from '../../../Components/Button/ListBoton';
export default class ListAliado extends CardBuscador {
    con = conAliado();
    placeholder = "BUSCAR ALIADO";
    rol = -1;
    status = -1; buscarEn = ["nombre", "correo"];
    Title = () => <>
        <FormUsuario user={null} title='NUEVO ALIADO'
            open={this.state.modal} update={() => this.set()}
            close={() => { this.setState({ modal: false }) }} />
        <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR ALIADO</h4>
        <ButtonGroup>
            <ListButonLink permiso='ALI_ROL' tip='ASIGNAR RESPOSABILIDAD' variant="warning" to='/aliado/rol'>
                <i className="fa fa-lock" /> PERMISOS
            </ListButonLink>
            <Button onClick={() => this.setState({ modal: true })} variant='primary'>
                <span className="fa fa-plus"></span> NUEVO ALIADO
            </Button>
        </ButtonGroup>
    </>
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='calc(100% - 710px)' label='codigo'>NOMBRE</Th>
            <Th width='350px' label='correo'>CORREO</Th>
            <Th width='150px'>ROL</Th>
            <Th width='150px'>ESTADO</Th>
            <Th width='110px'>ACCIONES</Th>
        </tr>
    }
    Row = (p: AliadoFila) => <Fila key={'usuario' + p.id + p.estado} d={p} update={() => this.set()} />
    set = () => this.con.lista(itens => this.setState({ itens }));
}