import { VerContry, VerEstado } from "../../../../Components/Contry/Ver";
import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import { findParticipanteFila } from "../../Context/Participante";
import { Interesado } from "../../Programas/Models/Models";
import { StatusChange } from "../StatusChange";
import { ButtonGroup } from "react-bootstrap";
import { getStatus } from "./Enviar";
import { useCallback } from "react";
import { Chat } from "../Chat";

interface Props {
    i: Interesado;
    u: () => void;
    url: string;
}

export default function Fila({ i: { id, estatus: [bg, st] }, url, u }: Props) {
    const { nombre, correo, telefono, pais, estado, ciudad } = findParticipanteFila(id);
    const estatus = useCallback((d: any) => getStatus(url, id, d, u), [id, u, url]);
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='auto'>{correo}</THFila>
        <THFila width='auto'>{telefono}</THFila>
        <THFila width='auto'><VerContry key={pais} pais={pais} /></THFila>
        <THFila width='auto'><VerEstado key={pais + estado} pais={pais} estado={estado} /></THFila>
        <THFila width='auto'>{ciudad}</THFila>
        <THFila width='auto'><span className={`badge bg-${bg}`}>{st}</span></THFila>
        <THInput width='auto'>
            <ButtonGroup>
                <StatusChange bg={bg} status={st} update={estatus} />
                <Chat id={id} url={url} />
            </ButtonGroup>
        </THInput>
    </tr>
}