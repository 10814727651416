/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from "react";
import { Facilitador } from "../../../Models";
import keys from "./Keys";
import { ProgramaContext } from "../../../../Context/Programas";
import { getEspecialidades } from "../../Especialidades";
class keyFacilitador extends keys {
    c: Facilitador;
    constructor(c: Facilitador) {
        super();
        this.c = c;
    }
    getKeys() {
        // especialidades: number[];
        this.addKeyToString('facilitador_nombre', 'EL NOMBRE DEL FACILITADOR', this.c.nombre);
        this.addKey('facilitador_experiencia', 'AÑOS DE EXPERIENCIA DEL FACILITADOR', this.c.experiencia);
        this.addKeyToString('facilitador_descripcion', 'EL DESCRIPCION DEL FACILITADOR', this.getBlock(this.c.descripcion));
        this.addKeyToString('facilitador_programa', 'PROGRAMAS AVALADOS DEL FACILITADOR', this.getProgramas());
        this.addKeyToString('facilitador_estudios', 'ESTUDIOS DEL FACILITADOR', this.getEstudios());
        this.addKey('facilitador_especialidades', 'ESPECIALIDADES DEL FACILITADOR', this.getEspecialidades());
        this.addKey('facilitador_redes', 'REDES SOCIALES DEL FACILITADOR', this.getRedes());
        this.addKeyToString('facilitador_alcance', 'PAISES DE ALCANCE', "Venezuela, Mexico");
        this.addKeyToString('facilitador_pais', 'PAIS DE LA DIRECCION DEL FACILITADOR', "Venezuela");
        this.addKeyToString('facilitador_estado', 'ESTADO O PROVINCIA DEL FACILITADOR', "Lara");
        this.addKeyToString('facilitador_ciudad', 'CIUDAD DEL FACILITADOR', this.c.ciudad);
        this.addKeyToString('facilitador_direccion', 'DIRECCION', this.c.direccion);
        return this.keys;
    }
    getProgramas = () => {
        const { programas: pr } = useContext(ProgramaContext);
        return `<ul>${pr.filter(p => this.c.programas.includes(p.id)).map(e => `<li>${e.nombre}</li>`).join('')}</ul>`;
    }
    getEspecialidades = () => {
        const { especialidades: e } = getEspecialidades();
        return `<ul>${e.filter(([id]) => this.c.especialidades.includes(id))
            .map(([, nombre]) => `<li>${nombre}</li>`).join('')}</ul>`;
    }
    getEstudios = () => `<ul>${this.c.estudios.map(e => `<li><b>${e.grado}:</b> ${e.titulo}</li>`).join('')}</ul>`;
    getRedes = () => this.c.redes.map(e =>
        `<a href='${e.url}' className = "m-1" target="_blank" rel="noreferrer">${this.getIcon(e.icon)}</a>`).join('');
}
export function getKeyFacilitador(c: Facilitador) {
    return new keyFacilitador(c);
}