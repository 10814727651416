import { Button, Row, Col, Card } from 'react-bootstrap';
import { FieldText } from '../../../../../../Components/Field';
import { Html } from '../../../../../Panel/Component/Commons';
import { css } from '@emotion/css';

const set = (s: string) => { }
export function Suscribir({ data: { props, html }, size }: any) {
    const { backgroundColor, img, hideText, cardTitle } = props;
    const { i, l, r, t, h } = cols(size);
    return <div style={{ padding: '60px 0 0 0', background: backgroundColor }}>
        <div className="container" id='Suscribir'>
            <Row>
                <Col md={r}><Row className='d-flex flex-row-reverse text-center'>
                    <Col md={t}>
                        <Row className='m-2 p-2'><Html html={html} /></Row>
                        {h ? <Row className='m-2 p-2'><Html html={hideText} /></Row> : <></>}
                    </Col>
                    {i ? <Col md={i}><img loading="lazy" key={img} src={img} width='100%' alt='panel a' /></Col> : <></>}
                    {/* {r < 12 ? <Banner /> : <></>} */}
                </Row></Col>
                <Col md={l} style={{ padding: '30px 0' }}>
                    <Card className={getCSS(props)}>
                        <Row className='m-2 p-2' >
                            <Col className='input m-2 p-0' md={12}><Html html={cardTitle} /></Col>
                            <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Nombre*" value='' /></Col>
                            <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Correo Electronico*" value='' /></Col>
                            <Col className='input m-2 p-0' md={12}><FieldText style={{ width: '100%' }} size="small" variant="outlined" setData={set} label="Whatsapp*" value='' /></Col>
                            <Col className='boton m-2 p-0' md={12}><Button block variant="primary"><b>Suscribirse</b></Button></Col>
                        </Row>
                    </Card>
                </Col>
                {/* {r === 12 ? <Banner /> : <></>} */}
            </Row>
        </div>
    </div>
}
const cols = (s: any) => {
    switch (s) {
        case '570px': case '780px': return { r: 12, l: 12, i: 10, t: 12, h: false }
        case '990px': case '1080px': return { r: 7, l: 5, i: 0, t: 12, h: true }
        case '1320px': case '1440px': return { r: 8, l: 4, i: 6, t: 6, h: true }
    }
    return { r: 8, l: 4, i: 6, t: 6, h: true }
}
export function getCSS({ colorFF = '#ffffff', colorFC = '#ffffff', colorBC = '#0069d9', colorTC = '#000000', colorBT = '#ffffff', fontC = 'Arial', fontB = 'Arial' }: any) {
    return css`
    background: ${colorFF};
    &>.row>.input>.MuiFormControl-root>.MuiInputBase-root>.MuiOutlinedInput-notchedOutline{
        border-color: ${colorTC} !important;
    }
    &>.row>.input>.MuiFormControl-root>.MuiFormLabel-root{
        background: transparent !important;
    }
    &>.row>.input>.MuiFormControl-root>*,
    &>.row>.input>*{
        background: ${colorFC} !important;
        color: ${colorTC} !important;
        font-family: ${fontC} !important;
        border-color: ${colorTC} !important;
    }
    &>.row>.boton>.btn {
        background: ${colorBC};
        border-color: ${colorBC};
        color: ${colorBT};
        font-family: ${fontB} !important;
    }
    &>.row>.boton>.btn:hover {
        opacity: .7 !important;
    }
    &>.row>.boton>.btn:active {
        opacity: 1 !important;
    }`;
}
// const Banner = () => <Col md={12}>
//     <Card as={Col} xs={12} className='p-2 mt-2'>
//         <Row className="align-items-center align-self-center">
//             <Col md={12} lg={8}>
//                 <Row className="align-items-center align-self-center">
//                     <img loading="lazy" width='120px' className="mx-auto wall-img" src="/static/media/logo-text.svg" alt="lideres en educacion devices - Open English" />
//                     <div className="w-100 d-lg-none pd--t10"></div>
//                     <img loading="lazy" width='120px' className="mx-auto bbc-img" src="/static/media/logo-text.svg" alt="BBC valida OpenEnglish" />
//                     <img loading="lazy" width='120px' className="mx-auto miami-img" src="/static/media/logo-text.svg" alt="lideres en educacion devices - Open English" />
//                 </Row>
//             </Col>
//             <Col md={12} lg={4}>
//                 <Row className="align-items-center align-self-center">
//                     <img loading="lazy" width='120px' className="mx-auto apple-img" src="/static/media/logo-text.svg" alt="lideres en educacion devices - Open English" />
//                     <img loading="lazy" width='120px' className="mx-auto google-img" src="/static/media/logo-text.svg" alt="lideres en educacion devices - Open English" />
//                 </Row>
//             </Col>
//         </Row>
//     </Card>
// </Col>