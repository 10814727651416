
import { Component } from 'react';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Aliado, conAliado, AliadoNull } from './Aliado';
import { ProgramaSelectorMultiple } from '../Programas/SelectorMultiple';
import { EventoSelectorMultiple } from '../Evento/SelectorMultiple';
import { getUpdater } from '../../../Config/Utiles';
import { Cargando } from '@victorequena22/component-bootstrap';
import { ListButonLink } from '../../../Components/Button/ListBoton';
interface Props {
    aliado: Aliado
    update: () => void
}
interface State {
    eventos: number[];
    programas: number[];
}
export default function Asignaciones() {
    const [aliado, update] = getUpdater<Aliado>(conAliado(), AliadoNull);
    return aliado.id ? <Model aliado={aliado} update={update} /> : <Cargando />
}
class Model extends Component<Props, State> {
    constructor(p: any) {
        super(p)
        this.state = {
            eventos: [],
            programas: []
        };
    }
    setData = (data: Partial<State>) => this.setState(data as any);
    render = () => {
        let { setData, state: { programas, eventos }, props: { aliado: { nombre } }, create } = this;
        const s = { height: '50hv' };
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> ASIGNAR RESPONSAVILIDADES </h4>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <ListButonLink className='mb-3' permiso='ALIADO' tip='VOLVER' variant="primary" to='/aliado'>
                                <i className="fa fa-arrow-left" />
                            </ListButonLink> <h3 className='ml-2 text-info w-50'>{nombre}</h3>
                        </Row>
                    </Col>
                    {this.valAccion('PROGRAMA') ? <Col style={s} xs={6}>
                        <ProgramaSelectorMultiple programas={programas} setProgramas={programas => setData({ programas })} />
                    </Col> : <></>}
                    {this.valAccion('EVENTO') ?
                        <Col style={s} xs={6}>
                            <EventoSelectorMultiple eventos={eventos} set={eventos => setData({ eventos })} />
                        </Col> : <></>}
                    <Col xs={12}>
                        <Button variant='success' className='float-right' onClick={create}>
                            <i className="fa fa-save"></i> Guardar
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card >)
    }
    create = () => conAliado().setAsignaciones(this.props.aliado.id, this.getModels(),
        () => toast('GUARDADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS }),
        () => toast('ERROR', { type: toast.TYPE.ERROR }))


    valAccion(accion: string) {
        return ((this.props.aliado.acciones.findIndex(a => a.nombre === accion)) > -1);
    }
    componentDidMount() {
        const eventos = this.getAsignaciones('evento');
        const programas = this.getAsignaciones('programa');
        this.setData({ eventos, programas });
    }
    getAsignaciones(model: string) {
        return this.props.aliado.asignaciones.filter(a => a.model === model).map(a => a.id);
    }
    getModels() {
        let { eventos, programas } = this.state;
        const e = eventos.map(a => ({ id: a, model: 'evento' }));
        const p = programas.map(a => ({ id: a, model: 'programa' }));
        return { asignaciones: [...e, ...p] };
    }

}