import { Panel } from './Panel';
import { Config } from './Config';
import { Single, StateS } from '../../../../../../Panel/ProtoPanes/Single';
import { BlockElement, getText } from '@victorequena22/slate-bootstrap';
import { defaultText } from '../../../../../../Panel/ProtoPanes/DefaultData';
import { conLanding } from '../../../../../../Panel/Models/Landing';
export interface S {
    button: string;
    buttonText: string;
    backgroundColor: string;
    img: string;
    cardTitle: BlockElement[]
    hideText: BlockElement[]
    select: number,
    colorFF: string
    colorFC: string
    colorBC: string
    colorTC: string
    colorBT: string
    fontC: string
    fontB: string
    online: boolean
}
export type State = StateS & S;
export default class Suscribir extends Single<S> {
    con = conLanding();
    getDataSave2 = (): any => {
        const a = this.getDataSave();
        return { ...a, ...this.props.props };
    }
    constructor(p: any) {
        super(p);
        this.init({
            img: "/public/panels/default.jpg",
            button: '#777777',
            buttonText: '#ffffff',
            backgroundColor: '#f0f0f0',
            cardTitle: defaultText,
            hideText: defaultText,
            select: 1,
            colorFF: '#ffffff',
            colorFC: '#ffffff',
            colorBC: '#0069d9',
            colorTC: '#000000',
            colorBT: '#ffffff',
            fontC: 'Arial',
            fontB: 'Arial',
            online: false
        });
    }
    Config = () => <Config save={this.save} setConfig={this.setData} title={this.state.title} setTitle={this.setTitle} config={this.state} />
    Panel = () => <Panel config={this.state} setConfig={this.setData} />
    getDataSave = () => {
        const { backgroundColor, cardTitle, hideText, img, button, title, html, colorFF, colorFC, colorBC, colorTC, colorBT, fontC, fontB, online } = this.state;
        const props = JSON.stringify({ backgroundColor, img, button, cardTitle, hideText, colorFF, colorFC, colorBC, colorTC, colorBT, fontC, fontB, online });
        const data = JSON.stringify({});
        return { title, html: getText(html), data, props, type: 'suscribir2' };
    }
    getText = (): { html: BlockElement[], id: number } => {
        const { html, cardTitle, hideText, select } = this.state;
        switch (select) {
            case 1: return { html, id: 1 };
            case 2: return { html: hideText, id: 3 };
            case 3: return { html: cardTitle, id: 2 };
        }
        return { html, id: 0 };
    }
    setTexto = (text: any[]) => {
        const { select } = this.state;
        switch (select) {
            case 2: this.setData({ hideText: getText(text) }); break;
            case 3: this.setData({ cardTitle: getText(text) }); break;
            default: this.setData({ html: getText(text) }); break;
        }
    }
}