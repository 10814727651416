import { getCSS, getWidth } from './props';
import { EditArea } from '../../Component/SlateCustom'
import { Row, Col, Container } from 'react-bootstrap';
import { State } from './index';

export function Panel({ config: { mapa, color, xs, sm, md, lg, xl, xxl, size }, setConfig }: { config: State, setConfig: (d: Partial<State>) => void }) {
    return <div className="w-100" style={{ background: color.length === 9 ? color.substring(0, color.length - 2) : color }}>
        <div className={getCSS(size)} style={{ zIndex: 100 }}>
            <Container>
                <Row className={getWidth(xs, sm, md, lg, xl, xxl, size)}>
                    <Col style={{ background: color }}>
                        <div style={{ height: '100%', width: '100%' }}><EditArea /></div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className={getCSS(size)} style={{ aspectRatio: '16/6' }}>
            <iframe src={mapa} width="100%" height="100%" style={{ border: 0 }} title='print'></iframe>
        </div>
    </div>
}