export const fontsFamily = [
    "Playball",
    "Miama",
    "LucianSchoenschriftCAT",
    "FREEBSC",
    "FlaemischeKanzleischrift",
    "DancingScript",
    "Cookie",
    "StylishCalligraphy",
    "BillyArgelFont",
    "ANGEL",
    "Amsterdam",
    "Sacramento",
    "Ginga",
    "ChopinScript",
    "BrockScript",
    "AdineKirnberg",
    "AdineKirnbergAlternate",
    "GreatVibes",
    "AlexBrush",
    "AdamScript",
    "Gidole",
    "OPTITorry",
    "TimesNewRoman",
    "TheRanch",
    "TheRanchRoughen",
    "TheRanchScript",
    "TheRanchSketch",
    "ShinyMarkerRegular",
    "Sequencia",
    "PatentedRameshDemo",
    "Magicalnotes",
    "Gorecobra",
    "Warth",
    "Contra",
    "CrackWallRegular",
    "TheBringaRegular",
    "TheBringaRegular2",
    "TheBringaRegular3",
    "Grunger",
    "CollegiateBlackFLF",
    "CollegiateBorderFLF",
    "CollegiateFLF",
    "CollegiateInsideFLF",
    "CollegiateOutlineFLF",
    "FormaDJRBannerMediumTesting",
    "ShinyMarkerRegular",
    "FormaDJRDeckMediumTesting",
    "FormaDJRDisplayMediumTesting",
    "FormaDJRMicroMediumTesting",
    "FormaDJRTextMediumTesting",
    "Arial",
    "FreeMono",
    "FreeSerif",
    "FreeSans",
    "DejaVuSerif",
    "DejaVuSerifCondensed",
    "DejaVuSansMono",
    "DejaVuSans",
    "DejaVuSansCondensed",
    "DejaVuSans-ExtraLight",
    "AbrazoScriptSSiNormal",
].sort();