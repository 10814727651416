import { BlockElement, setText } from "@victorequena22/slate-bootstrap";

class BlockToHTML {

    msn: BlockElement[];
    fonts = [];

    constructor(data: BlockElement[]) {
        this.msn = setText(data);
    }

    private Element(element: any) {
        let css = this.cssElement(element);
        let children = this.children(element.children);
        return `<div style='${css}'>${children}</div>`;
    }

    private getFontSize(type: string) {
        switch (type) {
            case 'heading-one': return 26;
            case 'heading-two': return 22;
            case 'heading-tre': return 18;
            case 'heading-for': return 14;
            default: return 10;
        }
    }


    private addProps(leaf: any, prop: any, val: any) {
        return leaf.prop && leaf.prop ? val : '';
    }

    private backgroundColor(back: any) {
        let rest = back !== '#00000000' ? back : '';
        if (rest !== '') {
            return "background-color: back";
        }
        return '';
    }

    private children(children: any[]) {
        return children.map(c => this.leaf(c));
    }

    public getHTML() {
        return this.msn.map(c => this.Element(c)).join('');
    }

    private cssElement(e: any) {
        let font = (this.getFontSize(e.type) / 10);
        let tabs = e.tabs * 2;
        let t = tabs;
        let s = '';
        if (e.list !== 'paragraph') {
            t = tabs + font;
            let listType = e.list === 'bulleted-list' ? 'disc' : 'square';
            s = `display: 'list-item'; listStyleType: ${listType}; `;
        }
        let width = (t + 0.1) + 'rem';
        return " float: right; margin: 0 0 0 0; padding: 0 0 0 0;"
            + `font-size: ${font}rem; min-height: ${font}rem;`
            + "text-align: $e.align; "
            + `width: calc(100% - ${width})`
            + s;
    }

    private leaf(leaf: any) {
        let h = leaf.fontSize ? (leaf.fontSize / 10) : '';
        let back = leaf.backgroundColor ? this.backgroundColor(leaf.backgroundColor) : '';
        let css = h !== '' ? `font-size: ${h}rem; ` : '';
        css += leaf.color ? `color:${leaf.color};` : '';
        css += leaf.fontFamily ? `font-family:${leaf.fontFamily}` : '';
        css += this.addProps(leaf, 'bold', "font-weight:bold;");
        css += this.addProps(leaf, 'italic', "font-style:italic;");
        css += this.addProps(leaf, 'underline', "font-decoration:underline;");
        css += back;
        return `<span style='${css};width: fit - content;'>${leaf.text}</span>`;
    }
}
export function getBlockToHTML(b: any) {
    return new BlockToHTML(b);
}