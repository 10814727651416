
import { Button, Row, Col, Card } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { eventoNull } from '../../Models/Model';
import { conEvento } from '../../Models/Coneccion';
export default class NuevoEvento extends Prototipo<any> {
    constructor(p: any) {
        super(p)
        this.state = { ...eventoNull, Redirect: false };
    }
    render = () => {
        let { setData, error, state, create } = this;
        if (state.Redirect) return <Redirect to={'/evento'} />
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVO EVENTO </h4>
            </Card.Header>
            <Form data={state} setData={setData as any} error={error}>
                <Row className='mt-2'><Col xs={12}>
                    <Button variant='success' className='float-right' onClick={create}>
                        <i className="fa fa-save"></i> Guardar
                    </Button>
                </Col></Row>
            </Form>
        </Card >)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                this.con.nuevo(this.getDataSave(), () => {
                    this.procesar = true;
                    toast('EVENTO CREADO', { type: toast.TYPE.SUCCESS });
                    this.setState({ Redirect: true });
                }, this.setError)
            }
        } else this.forceUpdate();
    }

    update = (_p: any, s: any) => {
        if (this.state.programa !== s.programa && this.state.cohorte === 0)
            conEvento().cohorte(this.state.programa, cohorte => {
                this.setData({ cohorte });
            })
    }
}