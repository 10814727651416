import { useContext, ChangeEvent, useEffect } from 'react';
import { FormControl } from 'react-bootstrap';
import { ProgramaContext } from '../Context/Programas';
interface Props {
    change: (r: number) => void,
    defaultValue: number,
    width?: string
    tipo?: 'TODOS LAS ' | 'SELECCIONAR'
    isInvalid?: boolean
}
export default function ProgramaSelector({ change, defaultValue, width, tipo = 'TODOS LAS ', isInvalid }: Props) {
    const set = (e: ChangeEvent<HTMLSelectElement>) => change(parseInt(e.currentTarget.value));
    const { programas, actualizar } = useContext(ProgramaContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => actualizar(), []);
    return <FormControl onClick={actualizar} as='select' style={{ width }} onChange={set} value={defaultValue} isInvalid={isInvalid}>
        <option value='0'>{tipo} PROGRAMA</option>
        {programas.filter(c => c.estatus > 0).map((c, i) => <option key={i} value={c.id}>{c.nombre}</option>)}
    </FormControl>
}