import { semaforo } from '@victorequena22/utiles';
import { Conexion2, setCambio } from '../../../../Config/Coneccion';
import { EventoLista, Evento } from './Model';
class EventoConecction extends Conexion2<Evento, EventoLista> {
    clase = 'Evento';
    delete = (id: number, setData: (d: any) => void, setError: (d: any) => void) => {
        this.get(`/api/Evento/delete/${id}`, setData, setError);
    }
    publicar = (id: number, setData: (d: any) => void, setError?: (d: any) => void) => {
        this.get(`/api/Evento/publicar/${id}`, setData, setError);
    }
    cohorte = (programa: number, setData: (d: any) => void, setError?: (d: any) => void) => {
        this.get(`/api/Evento/cohorte/${programa}`, setData, setError);
    }
    participantes(id: number, data: any, setData: (d: any) => void, setError?: (d: any) => void) {
        this.post(`/api/Evento/participantes/${id}`, data, setData, setError);
    }
    aprobar(data: any, setData: (d: any) => void, setError?: (d: any) => void) {
        this.post(`/api/Evento/aprobar`, data, setData, setError);
    }
    reprobar(data: any, setData: (d: any) => void, setError?: (d: any) => void) {
        this.post(`/api/Evento/reprobar`, data, setData, setError);
    }
    finalizar(data: any, setData: (d: any) => void, setError?: (d: any) => void) {
        this.post(`/api/Evento/finalizar`, data, setData, setError);
    }
    preinscribir(id: number, participante: number, setData: (d: any) => void, setError?: (d: any) => void) {
        this.post(`/api/Evento/inscritos/preinscribir/${id}`, { participante }, setData, setError);
    }
    inscribir(id: number, participante: number, setData: (d: any) => void, setError?: (d: any) => void) {
        this.post(`/api/Evento/inscritos/inscribir/${id}`, { participante }, setData, setError);
    }
    eliminar(id: number, participante: number, setData: (d: any) => void, setError?: (d: any) => void) {
        this.post(`/api/Evento/inscritos/eliminar/${id}`, { participante }, setData, setError);
    }
}
export function conEvento() {
    return new EventoConecction();
}
export function arrayToEvento(f: any[]): EventoLista[] {
    return f.map((a) => {
        return {
            id: a[0],
            short: a[1],
            codigo: a[2],
            programa: a[3],
            cohorte: a[4],
            nombre: a[5],
            descripcion: a[6],
            fecha: a[7],
            inicio: a[8],
            final: a[9],
            pais: a[10],
            ciudad: a[11],
            estado: a[12],
            direccion: a[13],
            estatus: a[14],
            usuario: a[15],
            publicacion: a[16],
            lista: a[17],
            facilitadores: a[18],
            avales: a[19],
            tags: a[20]
        }
    });
}
function setCambioEvento(data: any[], c: EventoLista[]) {
    return setCambio(arrayToEvento(data), c);
}
export const eventoUpdate = (c: EventoConecction, u: EventoLista[], setData: (c: EventoLista[]) => void) => {
    const s = semaforo()
    if (s.isTurn('Evento')) c.cambios(data => {
        if (data.length) setData(setCambioEvento(data, u));
        s.next();
    }, () => s.next());
}