// import { CSSProperties } from 'react';
import { Card, Col } from 'react-bootstrap';
// import { TextToHTML } from "@victorequena22/slate-bootstrap";
import { State } from '../index';
import { CapacitacionFila } from '../../../../Capacitacion/Programas/Models';
interface Props {
    iten: CapacitacionFila,
    config: State
}
export function Itens({ config: { gap, size }, iten: { short } }: Props) {
    const s1 = ['990px', '1080px', '780px'], s2 = ['1440px', '1320px'];
    const xs = s2.some(s => size === s) ? 3 : s1.some(s => size === s) ? 4 : 6;
    return <Col style={{ margin: '0 0 0 0', padding: '0 0 0 0' }} xs={xs}>
        <Card style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={short} />
        </Card>
    </Col >
}
