import React from "react"
import { Button, Table } from "react-bootstrap"
import { useState, useCallback } from "react"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { ControlNumber, ControlText } from "@victorequena22/component-bootstrap"
import { conAval } from "../../Models"
interface Props {
    update?: () => void
}
export function NuevoAval({ update }: Props) {
    const [nombre, setNombre] = useState('');
    const [costo, setCosto] = useState(0);
    const nuevo = useCallback(() => {
        if (nombre === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR });
        } else {
            conAval().nuevo({ nombre, costo }, () => {
                if (update) update();
                setNombre(''); setCosto(0);
                toast('ACTUALIZADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre, costo]);
    return <>
        <label>NUEVA AVAL</label>
        <Table>
            <tr>
                <th style={{ width: "calc(100% - 200px)" }}>
                    <ReactTooltip />
                    <ControlText before='NOMBRE' setData={setNombre} value={nombre} />
                </th>
                <th style={{ width: "230px" }}>
                    <ControlNumber before='COSTO' setData={setCosto} value={costo} />
                </th>
                <th style={{ width: "145px" }}>
                    <Button data-tip="GUARDAR" variant="primary" onClick={nuevo}>
                        <i className="fa fa-edit"></i> GUARDAR
                    </Button>
                </th>
            </tr>
        </Table>
    </>
}