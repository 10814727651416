/* eslint-disable jsx-a11y/anchor-is-valid */
import { MobileUserAgent } from '@victorequena22/utiles'
import MenuIten from './MenuIten'
import { Link } from "react-router-dom"
import { useContext } from 'react';
import { MenuContext } from './MenuContext';

export default function Menu() {
    const { open } = useContext(MenuContext);
    return <aside className="main-sidebar sidebar-dark-primary" style={{ backgroundColor: '#2196f3', maxHeight: 'calc(100vh - 57px)' }}>
        <Link to="/" className="brand-link" style={{ backgroundColor: '#363738', width: '100%', height: '57px' }}>
            <img width={33} height={33} src="/logos/logo.png" alt="logo" className="brand-image" />
            <img src="/logos/texto.png" alt="letras" className="brand-image brand-text" style={{ transitionDelay: '.2s' }} />
        </Link>
        <div className="sidebar">
            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">
                            <i className="nav-icon fa fa-home"></i> <p>INICIO</p>
                        </Link>
                    </li>
                    <MenuIten to="/programa" permiso="PROGRAMA">
                        <i className="nav-icon fas fa-books"></i> <p> PROGRAMAS</p>
                    </MenuIten>
                    <MenuIten to="/facilitador" permiso="FACILITADOR">
                        <i className="nav-icon fas fa-chalkboard-teacher"></i> <p> FACILITADORES</p>
                    </MenuIten>
                    <MenuIten to="/aliado" permiso="ALIADO">
                        <i className="nav-icon fas fa-user"></i> <p> ALIADO</p>
                    </MenuIten>
                    <MenuIten to="/media" permiso="MEDIA">
                        <i className="nav-icon fas fa-image"></i> <p>MEDIA</p>
                    </MenuIten>
                    <MenuIten to="/evento" permiso="EVENTO">
                        <i className="nav-icon fas fa-file-certificate"></i> <p> EVENTOS</p>
                    </MenuIten>
                    <MenuIten to="/pago" permiso="PANEL">
                        <i className="nav-icon fas fa-money-bill-wave"></i> <p>PAGOS RECIBIDOS</p>
                    </MenuIten>
                    <MenuIten to="/participante" permiso="FACILITADOR">
                        <i className="nav-icon fas fa-users-class"></i> <p> PARTICIPANTES</p>
                    </MenuIten>
                    <MenuIten to="/panel" permiso="PANEL">
                        <i className="nav-icon fas fa-browser"></i> <p>HAZTULANDING</p>
                    </MenuIten>
                    <MenuIten to="/Contacto/subs" permiso="PANEL">
                        <i className="nav-icon fas fa-comment-alt-smile"></i> <p>CONTACTOS</p>
                    </MenuIten>
                    <MenuIten to="/usuarios" permiso="USUARIO">
                        <i className="nav-icon fa fa-users"></i><p> USUARIOS</p>
                    </MenuIten>
                    <MenuIten to="/reporte" permiso="REPORTE">
                        <i className="nav-icon fa fa-chart-line"></i><p> REPORTES</p>
                    </MenuIten>
                    <MenuIten to="/config" permiso="CONFIG">
                        <i className="nav-icon fa fa-wrench"></i><p> CONFIGURACIÓN</p>
                    </MenuIten>
                    {MobileUserAgent() ? <li className="nav-item mt-2" onClick={open}>
                        <a className="nav-link fa-2x" >
                            <i className="fas fa-caret-down fa-rotate-90" /> <p>CERRAR</p>
                        </a>
                    </li> : <></>}
                </ul>
            </nav>
        </div>
    </aside>
}