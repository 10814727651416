import { useState, useContext } from 'react'
import { PreviewSize } from './PreviewSize'
import { Row, Card } from 'react-bootstrap';
import { SizeViewConteiner, sizeWidth } from '../../Panel/Component';
import ReactTooltip from 'react-tooltip';
import { PanelContext } from '../../Panel/Context/Panel';
import { Opciones } from '../Utils';
import { MenuTemplate, MenuZise } from '../../Panel/Utiles';
import { PageContext } from '../PageContext';
export function Ver() {
    const { panels } = useContext(PanelContext);
    const { page } = useContext(PageContext);
    const [size, setSize] = useState<sizeWidth>('1080px');
    return <>
        <Card style={{ backgroundColor: '#ccc', padding: '7px 7px 7px 7px' }}>
            <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
                <MenuTemplate />
                <MenuZise setSize={setSize} size={size} />
            </Row>
        </Card>
        <ReactTooltip key='tooltip' />
        <SizeViewConteiner size={size} h1='calc(100vh - 210px)' h2='calc(100vh - 210px)'>
            {panels.filter(({ pagina, estado }) => (page === pagina) && (estado === 0 || estado === 1))
                .sort((a, b) => a.indice - b.indice)
                .map(p => <div id={`panel${p.id}${p.indice}`} className='w-100 m-0 p-0'>
                    <Opciones panel={p} />
                    <PreviewSize key={p.type + p.id} panel={p} size={size} />
                </div>)}
        </SizeViewConteiner>
    </>
}