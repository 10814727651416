import { Row, Col } from "react-bootstrap";
import { Encuesta } from "./Encuesta";
import { Ciudad } from "./Ciudad";
import { Estados } from "./Estado";

export default function Graficos() {
    return <Row>
        <Col md={6}><Encuesta /></Col>
        <Col md={6}><Ciudad /></Col>
        <Col md={6}><Estados /></Col>
    </Row>
}