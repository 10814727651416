import { Card } from "react-bootstrap"
import { State } from './From'
import { EditArea } from "./Stale";
export default function Preview({ config: { data, id, top, left, right, bottom }, edit }: { config: State, edit?: { id: number } }) {
    let img = edit !== undefined ? `/api/Evento/imgCertificado/${edit.id}` : '';
    if (data) img = data;
    return <Card className="ml-auto mr-auto" style={{
        height: '834px', width: '1080px', border: '1px solid #555', overflowY: 'auto',
        padding: `${top}px ${right}px ${bottom}px ${left}px`,
        marginBottom: 0, overflowX: 'hidden', backgroundImage: `url(${img})`
    }}><EditArea /></Card>
}