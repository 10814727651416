import { InputText } from "@victorequena22/component-bootstrap";
import { Button, Table } from "react-bootstrap";
import { useState } from "react";

interface Esturdio { grado: string, titulo: string, id: number }
function CreateEstudio({ add, next }: { add: (d: Esturdio) => void, next: number }) {
    const [grado, setGrado] = useState('');
    const [titulo, setTitulo] = useState('');
    return <tr>
        <td>
            <InputText value={grado} setData={setGrado} />
        </td>
        <td>
            <InputText value={titulo} setData={setTitulo} />
        </td>
        <td>
            <Button variant='success' onClick={() => { add({ id: next, grado, titulo }); setGrado(''); setTitulo(''); }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}

export function EstudioList({ estudios, setData }: { estudios: Esturdio[], setData: (d: Esturdio[]) => void }) {
    const mx = Math.max(...estudios.map(s => s.id), 0);
    const change = (i: number, s: Esturdio) => {
        setData(estudios.map((o, j) => j !== i ? o : s));
    }
    return <Table striped hover>
        <thead>
            <tr>
                <th style={{ width: '25%' }}>GRADO</th>
                <th>TITULO</th>
                <th style={{ width: '32px' }}></th>
            </tr>
        </thead>
        <tbody>
            <CreateEstudio next={mx + 1} add={s => setData([...estudios, s])} />
            {estudios.map((s, i) => <tr key={i}>
                <td>
                    <InputText value={s.grado} setData={(grado) => change(i, { ...s, grado })} />
                </td>
                <td>
                    <InputText value={s.titulo} setData={(titulo) => change(i, { ...s, titulo })} />
                </td>
                <td>
                    <Button variant='danger' onClick={() => setData(estudios.filter((s, j) => j !== i))}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </td>
            </tr>)}
        </tbody>
    </Table>
}