import { Conexion, don } from "../../Config/Coneccion"

export default interface Usuario {
    id_usuario: number,
    usuario: string,
    nivel: number,
    fecha: string,
    rol: string,
    permisos: string[]
}
export const userNull: Usuario = {
    id_usuario: -1,
    usuario: '',
    nivel: 0,
    fecha: '',
    rol: '',
    permisos: []
}
export interface UsuarioFila {
    codigo: number,
    nombre: string,
    correo: string,
    clave?: string
    rol: string
    nivel: number
    estado: number
}

export interface Rol {
    id: number,
    nombre: string
    permisos: Permisos[]
}
export interface Permisos {
    id: number,
    nombre: string
}
class UsuarioConexion extends Conexion {
    clase = 'Usuario';
    persistencia = 1000000;
    listaRoles(setData: (d: any) => void) {
        this.get('/api/Usuario/listar_roles', setData)
    }
    listaPermisos(setData: (d: any) => void) {
        this.get('/api/Usuario/listar_permisos', setData)
    }
    PermisosRol(cod: number, setData: (d: any) => void) {
        this.get('/api/Usuario/detalles_rol/' + cod, setData)
    }
    nuevoRol(data: any, setData?: (d: any) => void) {
        this.post(don + '/api/' + this.clase + '/nuevo_rol', data, setData)
    }
    actualizarRol(codigo: string | number, data: any, setData?: (d: any) => void) {
        this.post(don + '/api/' + this.clase + '/actualizar_rol/' + codigo, data, setData);
    }
    clave(codigo: string | number, data: any, setData?: (d: any) => void, setError?: (d: any) => void) {
        this.post(don + '/api/' + this.clase + '/clave/' + codigo, data, setData, setError);
    }

}
export function conUsuario() {
    return new UsuarioConexion();
}