import { Row, Container, Col } from 'react-bootstrap';
import { Html } from '../Component/Commons';
import { Icon2 } from '../Component/Icons/IconSelector';
function Itens({ config: { card, border1, border2, back: iback, orientation, font }, iten }: any) {
    if (iten === undefined) return <></>;
    const { id, html, props: { icon, color, back, aux, fill } } = iten;
    const style = {
        border: (border1) ? `5px solid ${fill}` : undefined,
        background: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px',
    }
    return <div style={style} className={`d-flex flex-${((orientation === '' && !(id % 2)) || (orientation !== '' && id % 2)) ? 'row' : 'row-reverse'}`}>
        <div className="d-flex justify-content-center">
            <Icon2 icon={icon} font={font} fill={fill} back={iback} border={border2} aux={aux} />
        </div>
        <div className="caption  d-flex align-items-center w-100 h-100 p-1">
            <Html style={{ color }} html={html} />
        </div>
    </div>
}
const RowItens = ({ left, right }: { left: JSX.Element, right: JSX.Element }) => {
    return <Row><Col className='m-2'>{left}</Col><Col className='m-2'>{right}</Col></Row>
}
export function Servicio2({ data: { id, itens, props } }: any) {
    const Rows: JSX.Element[] = [];
    for (let i = 0; i < itens.length; i += 2) {
        Rows.push(<RowItens
            left={<Itens key={`iten${id}${itens[i].id}`} iten={itens[i]} config={props} />}
            right={<Itens key={`iten${id}${itens[i + 1].id}`} iten={itens[i + 1]} config={props} />} />)
    }
    return <Row className='m-0' style={{ background: props.color, padding: '10px 0 10px 0' }}>
        <Container>{Rows}</Container>
    </Row>
}