import Paralax1 from '../../../../Panel/Landing/Paralax1';
import Paralax2 from '../../../../Panel/Landing/Paralax2';
import Video from '../../../../Panel/Landing/Video';
import Galeria from '../../../../Panel/Landing/Galeria';
import Carrusel from '../../../../Panel/Landing/Carrusel';
import Servicio from '../../../../Panel/Landing/Servicio';
import Servicio2 from '../../../../Panel/Landing/Servicio2';
import Preguntas from '../../../../Panel/Landing/Preguntas';
import Title from '../../../../Panel/Landing/Title';
import Imagen from '../../../../Panel/Landing/Imagen';
import Cargos from '../../../../Panel/Landing/Cargos';
import Mapa from '../../../../Panel/Landing/Mapa';
import ImagenSola from '../../../../Panel/Landing/ImagenSola';
import { Cargando } from '@victorequena22/component-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { conLanding } from '../../../../Panel/Models/Landing';
import { Template2 } from '../Template';
import { getFacilitador } from '../index';
import Eventos from '../../../../PanelProxi/Landing/Eventos';
import Programas from '../../../../PanelProxi/Landing/Programas';
import Testimonio from '../../../../Panel/Landing/Testimonio';
export default function EditarPanel() {
    const { id } = getFacilitador();
    let { mid } = useParams<{ mid: any }>();
    const [panel, setPanel] = useState<any>([])
    useEffect(() => { conLanding().detalle(mid, setPanel); }, [mid])
    return <Template2 icon="fas fa-browser" title="EDITAR PANEL" back={`/facilitador/landing/${id}`}>
        <Edit panel={panel} />
    </Template2>
}
function Edit({ panel: l }: { panel: any }) {
    switch (l.type) {
        case 'cargos': return <Cargos key={'panel' + l.type} data={l} />
        case 'carrusel': return <Carrusel key={'panel' + l.type} data={l} />
        case 'galeria': return <Galeria key={'panel' + l.type} data={l} />
        case 'imagen': return <Imagen key={'panel' + l.type} data={l} />
        case 'imagenSola': return <ImagenSola key={'panel' + l.type} data={l} />
        case 'paralax1': return <Paralax1 key={'panel' + l.type} data={l} />
        case 'paralax2': return <Paralax2 key={'panel' + l.type} data={l} />
        case 'preguntas': return <Preguntas key={'panel' + l.type} data={l} />
        case 'servivio1': return <Servicio key={'panel' + l.type} data={l} />
        case 'servivio2': return <Servicio2 key={'panel' + l.type} data={l} />
        case 'titulo': return <Title key={'panel' + l.type} data={l} />
        case 'video': return <Video key={'panel' + l.type} data={l} />
        case 'mapa': return <Mapa key={'panel' + l.type} data={l} />
        case 'eventos': return <Eventos key={'panel' + l.type} data={l} />
        case 'programas': return <Programas key={'panel' + l.type} data={l} />
        case 'testimonio': return <Testimonio key={'panel' + l.type} data={l} />
        default: return <Cargando />
    }
}