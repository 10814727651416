
import { useContext } from 'react';
import { MenuIten, Menus } from '../../../Components/Contens';
import { Ruta } from '../../../Template/Ruta';
import { SeccionContext, SeccionType } from '../../../Template/Seccion';
import Reporte from './Reporte';
export function MenuImpresiones() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    return <Menus title='CONFIGURACIONES DE IMPRESIÓN'>
        <MenuIten p={Permisos} to='/config/reportes' permiso='REPORTE' >REPORTES</MenuIten>
    </Menus>
}
export function RutasImpresiones() {
    return <>
        <Ruta path="/config/reportes" component={Reporte} permiso='REPORTE' />
    </>
}