import ReactPlayer from 'react-player'
import { Invert } from '../../Component/Commons';
import { State } from './index'

export function Panel({ config, width }: { config: State, width: number }) {
    const { video, backgroundColor } = config;
    return <div style={{ padding: '60px 0', background: backgroundColor }}>
        <div className="container">
            <Invert width={width} config={config}>
                <ReactPlayer width='100%' controls url={video} />
            </Invert>
        </div>
    </div>
}