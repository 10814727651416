import React from "react"
import Chart from "react-google-charts"
type val = string | number
export interface PieProps {
    title: string
    values: val[][]
    size?: string
    slices?: { color: string }[]
    legend?: string
}
export default function Torta({ legend = 'none', title, values, size = '18rem', slices = [{ color: '#dc3545' }, { color: '#ffc107' }, { color: '#28a745' }] }: PieProps) {
    return <Chart
        width={size}
        height={size}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[['ESTADOS', 'CANT'], ...values]}
        options={{ title, legend, slices }}
        rootProps={{ 'data-testid': '3' }}
    />

}

