import { InputArea } from "@victorequena22/component-bootstrap";
import { formatoFecha, numberFormat } from "@victorequena22/utiles";
import { useContext, useState } from "react";
import { Button, ButtonGroup, Col, FormGroup, FormLabel, Image, Row } from "react-bootstrap";
import { ListButonClick } from "../../../../Components/Button/ListBoton";
import { MetodosPagosContext } from "../../Context/MetodosPago";
import { metodoNull } from "../MetodosPago/Models";
import { MetodoMostrar } from "../MetodosPago/Mostrar";
import { conPago, Pago } from "../Models";
interface Props {
    pago: Pago
    select?: (d: number) => void
    update?: (d?: number) => void
}
export default function VerPago({ pago: { banco, dolar, estatus, fecha, monto, metodo, precio, nota: n, referencia, id }, select, update }: Props) {
    const [nota, setNota] = useState(n)
    const { MetodosPagos } = useContext(MetodosPagosContext);
    const metodoPago = MetodosPagos.find(m => m.id === metodo)
    const actualizar = () => { if (update) update() }
    return <Row>
        <Col md={4}>
            <MetodoMostrar precio={precio} dolar={dolar} metodo={metodoPago ? metodoPago : metodoNull} setData={select} />
        </Col>
        <Col as='label' md={4}><Image fluid src={`/api/Pagos/capture/${id}`} /></Col>
        <Col md={4}><Row>
            <Col as={FormGroup} md={12}><Row>
                <Col xs={4}><b>FECHA:</b></Col>
                <Col xs={8}>{formatoFecha(fecha)}</Col>
            </Row></Col>
            <Col as={FormGroup} md={12}><Row>
                <Col xs={4}><b>BANCO:</b></Col>
                <Col xs={8}>{banco}</Col>
            </Row></Col>
            <Col as={FormGroup} md={12}><Row>
                <Col xs={4}><b>REFERENCIA:</b></Col>
                <Col xs={8}>{referencia}</Col>
            </Row></Col>
            <Col as={FormGroup} md={12}><Row>
                <Col className="text-right" xs={8}><b>MONTO DE LA OPERACIÓN:</b></Col>
                <Col className="text-right" xs={4}>{numberFormat(monto)}</Col>
            </Row></Col>
            {(metodoPago && metodoPago.dolar) ? <></> : <>
                <Col as={FormGroup} md={12}><Row>
                    <Col className="text-right" xs={8}><b>TASA DE CAMBIO:</b></Col>
                    <Col className="text-right" xs={4}>{numberFormat(dolar)}</Col>
                </Row></Col>
                <Col as={FormGroup} md={12}><Row>
                    <Col className="text-right" xs={8}><b>MONTO EN DOLARES:</b></Col>
                    <Col className="text-right" xs={4}>{numberFormat(monto / dolar)}</Col>
                </Row></Col>
            </>}
            <Col as={FormGroup} md={12}>
                <FormLabel>Nota</FormLabel>
                <InputArea value={nota} setData={setNota} />
            </Col>
            <Col as={FormGroup} md={12}>
                {estatus === 1 ? <ButtonGroup className="btn-block">
                    <ListButonClick permiso="PAG_APROBAR" click={() => conPago().reprobar(id, nota, actualizar)} variant="danger">RECHAZAR</ListButonClick>
                    <ListButonClick permiso="PAG_APROBAR" click={() => conPago().aprobar(id, nota, actualizar)} variant="success">APROBAR</ListButonClick>
                </ButtonGroup> :
                    <Button onClick={() => conPago().nota(id, nota, actualizar)} variant="info" block> ACTUALIZAR NOTA</Button>}
            </Col></Row>
        </Col >
    </Row >
}