import { Row, Col } from "react-bootstrap";
import { Encuesta } from "./Encuesta";
import { Ciudad } from "./Ciudad";

export default function Graficos() {
    return <Row>
        <Col>
            <Encuesta />
        </Col>
        <Col>
            <Ciudad />
        </Col>
    </Row>
}