import { Evento, TypeAuxiliar } from "../../../../Evento/Models";
import keys from "../../Landing/Keys/Keys";
class keyEvento extends keys {
    evento: Evento;
    norma: TypeAuxiliar;
    consideracion: TypeAuxiliar;
    constructor(e: Evento, n: TypeAuxiliar, c: TypeAuxiliar) {
        super();
        this.evento = e;
        this.norma = n;
        this.consideracion = c;
    }
    getKeys() {
        this.addKey('evento_cohorte', 'LA COHORTE DEL EVENTO', this.evento.cohorte);
        this.addKeyToString('evento_codigo', 'EL CODIGO DEL EVENTO', this.evento.codigo);
        this.addKeyToString('evento_nombre', 'EL NOMBRE DEL EVENTO', this.evento.nombre);
        this.addKeyToString('evento_fecha', 'LA FECHA DEL EVENTO', this.evento.fecha);
        this.addKeyToString('evento_direccion', 'LA DIRECCION DEL EVENTO', this.evento.direccion);
        this.addKeyToString('evento_pais', 'EL PAIS DEL EVENTO', 'VENEZUELA');
        this.addKeyToString('evento_estado', 'EL ESTADO DEL EVENTO', 'LARA');
        this.addKeyToString('evento_ciudad', 'LA CIUDAD DEL EVENTO', 'BARQUISIMETO');
        this.addKeyToString('evento_descripcion', 'LA DESCRIPCION DEL EVENTO', this.evento.descripcion);
        this.addKeyToString('evento_consideracion', 'LA CONSIDERACION DEL EVENTO', this.getBlock(this.consideracion.texto));
        this.addKeyToString('evento_norma', 'LA NORMA DEL EVENTO', this.getBlock(this.norma.texto));
        this.addKeyToString('evento_precios', 'LOS PRECIOS DEL EVENTO', this.getPrecios());
        this.addKeyToString('evento_avales', 'LOS AVALES DEL EVENTO', `<ul><li>Grupo Zonum</li><li>Europa Campus</li></ul>`);
        return this.keys;
    }
    getPrecios() {
        return `<ul>${this.evento.precios
            .map(p => `<li>${p.nombre} <b className='ml-auto'>${p.precio}$</b></li>`)
            .join('')}</ul>`;
    }

}
export function getKeyEvento(e: Evento, n: TypeAuxiliar, c: TypeAuxiliar) {
    return new keyEvento(e, n, c);
}
