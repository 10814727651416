import { useState, createContext, useEffect, useCallback, useContext } from 'react';
import { semaforo } from '@victorequena22/utiles';
import { LandingUpdate, conLanding } from '../../../../Panel/Models/Landing';
import { Panel } from '../../../../Panel/Models';
import { getPrograma } from '../index';
export interface LandingType {
    panels: Panel[];
    actualizar: () => void;
}
export const LandingContext = createContext<LandingType>({ panels: [], actualizar: () => { } })
export default function LandingProvider({ children }: { children: any }) {
    const { id } = getPrograma();
    const [panels, setPanels] = useState<Panel[]>([]);
    const [con] = useState(conLanding());
    const actualizar = useCallback(() => LandingUpdate(con, 'programa', id, panels, setPanels), [con, id, panels]);
    useEffect(() => { let interval = setInterval(actualizar, 50); return () => clearInterval(interval); }, [actualizar]);
    useEffect(() => { const s = semaforo(); s.add('Landing'); return () => s.clean('Landing'); }, []);
    return <LandingContext.Provider value={{ panels, actualizar }}>{children}</LandingContext.Provider>
}
export const SeccionConsumer = LandingContext.Consumer;

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useLanding = () => useContext(LandingContext);