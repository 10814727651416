import ReactTooltip from 'react-tooltip';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { THFila } from "@victorequena22/tabla-bootstrap"
import { conFacilitador, FacilitadorFila } from './Models';

interface Props {
    data: FacilitadorFila
    update: () => void
}

export function Fila({ data: { id, nombre, descripcion, experiencia, estatus: e }, update }: Props) {
    const a = (new Date()).getFullYear();
    return <tr>
        <THFila tip={descripcion} width='auto'>{nombre}</THFila>
        <THFila width='100px' align='right'>{a - experiencia}</THFila>
        <THFila width='100px'><span className={`badge badge-${e ? 'success' : 'danger'}`}>{e ? 'ACTIVO' : 'INACTIVO'}</span></THFila>
        <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <Button as={Link} data-tip="EDITAR" variant="primary" to={'/facilitador/edit/' + id}>
                    <i className="fa fa-edit" />
                </Button>
                <ReactTooltip type='light' html />
                <Button data-tip="ELIMINAR" variant="danger" onClick={() => conFacilitador().cancelar(id, update)} >
                    <i className="fa fa-ban" />
                </Button>
            </ButtonGroup>
        </th>
    </tr >
}