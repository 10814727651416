import { Conexion } from '../../../../Config/Coneccion';
class NormaConecction extends Conexion {
    clase = 'Evento/Norma';
}
var norma: NormaConecction;
export function conNorma() {
    if (norma === undefined) norma = new NormaConecction();
    return norma;
}
class ConsideracionConecction extends Conexion {
    clase = 'Evento/Consideracion';
}
var consideraciones: ConsideracionConecction;
export function conConsideracion() {
    if (consideraciones === undefined) consideraciones = new ConsideracionConecction();
    return consideraciones;
}
class AvalConecction extends Conexion {
    clase = 'Evento/Aval';
}
var aval: AvalConecction;
export function conAval() {
    if (aval === undefined) aval = new AvalConecction();
    return aval;
}
class PublicidadConecction extends Conexion {
    clase = 'Evento/Publicidad';
}
var publicidad: PublicidadConecction;
export function conPublicidad() {
    if (publicidad === undefined) publicidad = new PublicidadConecction();
    return publicidad;
}