import { Panel } from './Panel';
import { Config } from './Config';
import { css } from '@emotion/css';
import Slider from '@mui/material/Slider'
import { Single, StateS } from '../../ProtoPanes/Single';
import { getText } from '../../ProtoPanes/DefaultData';
export type sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export interface area {
    span: number
    offset: number
}
export interface S {
    image: string;
    color: string;
    xs: area
    sm: area
    md: area
    lg: area
    xl: area
    xxl: area
}
export type State = StateS & S;
export class Paralax2 extends Single<S> {
    constructor(p: any) {
        super(p);
        this.init({
            image: '/public/panels/default.jpg', color: '#ffffffaa',
            xs: { span: 100, offset: 0 },
            sm: { span: 90, offset: 10 },
            md: { span: 100, offset: 33 },
            lg: { span: 100, offset: 30 },
            xl: { span: 100, offset: 30 },
            xxl: { span: 100, offset: 30 }
        });
    }
    Config = () => <Config save={this.save} setConfig={this.setData} title={this.state.title} setTitle={this.setTitle} config={this.state} />
    Panel = () => <Panel config={this.state} setConfig={this.setData}/>
    Top = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [a, { span, offset }] = this.getArea()
        return <Slider className={css`&> .MuiSlider-thumb{border-radius: 0;
            border-top-left-radius: 10px;transform: rotate(45deg);}`}
            onChange={(e: any, d: any) => this.setSpanW(d)} max={100} min={0}
            step={1} value={[span, offset]} style={{ width: '100%' }} />
    }
    setArea = ({ span, offset }: Partial<area>) => {
        const [a, b] = this.getArea();
        const c: area = {
            span: span !== undefined ? span : b.span,
            offset: offset !== undefined ? offset : b.offset
        }
        this.setState({ [a]: c } as any);
    }
    getArea = () => {
        const { size, xs, sm, md, lg, xl, xxl } = this.state;
        return ((size === '570px') ? ['xs', xs] :
            (size === '780px') ? ['sm', sm] :
                (size === '990px') ? ['md', md] :
                    (size === '1080px') ? ['lg', lg] :
                        (size === '1320px') ? ['xl', xl] : ['xxl', xxl]) as [sizes, area];
    }
    setSpanW = ([offset, span]: number[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [a, b] = this.getArea();
        if (span - offset < 30) {
            if (b.offset !== offset) {
                if (offset > 70) { span = 100; offset = 70; }
                else if (span < 100) span = offset + 30;
            } else if (b.span !== span) {
                if (span < 30) { span = 30; offset = 0 }
                else if (offset > 0) offset = span - 30;
            }
        }
        this.setArea({ offset, span });
    }
    getDataSave = () => {
        const { color, xs, sm, md, lg, xl, xxl, image, title, html} = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, xs, sm, md, lg, xl, xxl, image});
        return { title, html:getText(html), data, props, type: 'paralax2' };
    }
}
