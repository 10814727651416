import React from "react";
import { toast } from "react-toastify";
import { MetodoPago, partialPago } from "../MetodosPago/Models";
import { From } from "./From";
import { conPago } from "../Models";
interface State extends partialPago {
    error?: any
}
interface Props {
    evento: number
    dolar: number
    precio: number
    metodo: MetodoPago
    update: () => void
    setMetodo: (n: number) => void
    participante: number
}
export default class NuevoPago extends React.Component<Props, State> {
    procesar = true;
    constructor(p: any) {
        super(p)
        this.state = { banco: '', captura: '', fecha: '', monto: 0, referencia: '', error: {} }
    }
    render = () => {
        const { state: s, setData, save, props: { metodo, setMetodo } } = this;
        return <From setMetodo={setMetodo} metodo={metodo} save={save} data={s} setData={setData} error={s.error} />
    }
    setData = (p: Partial<State>) => {
        const error: any = this.state.error;
        Object.keys(p).forEach(label => {
            error[label] = undefined;
        });
        this.setState({ error });
        this.setState(p as any);
    };
    success = () => {
        this.procesar = true;
        this.props.update();
        this.props.setMetodo(0);
        toast('Registrado', { type: toast.TYPE.SUCCESS });
    }
    save = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                conPago().nuevo(this.getData(), this.success, this.setError)
            }
        } else this.forceUpdate();
    };
    validar = () => {
        const error: any = {};
        let procesar = true;
        const { captura, fecha, monto, banco, referencia } = this.state;
        if (captura === '') {
            procesar = false;
            error.captura = 'Debe subir la captura de la operacion';
        }
        if (fecha === '') {
            procesar = false;
            error.fecha = 'Debe ingresar la fecha de la operacion';
        }
        if (monto === 0) {
            procesar = false;
            error.monto = 'Debe ingresar el monto de la operacion';
        }
        if (referencia === '') {
            procesar = false;
            error.referencia = 'Debe ingresar la referencia de la operacion';
        }
        if (banco === '') {
            procesar = false;
            error.banco = 'Debe ingresar la referencia de la operacion';
        }
        this.setState({ error });
        return procesar;
    }
    getData() {
        const { captura, fecha, monto, referencia, banco } = this.state;
        const { metodo: { id }, precio, evento, dolar, participante } = this.props;
        return { captura, fecha, monto, referencia, banco, precio, dolar, evento, metodo: id, participante };
    }
    setError = (err: any[]) => {
        this.procesar = true;
        const error: any = {};
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            } else {
                Object.keys(e).forEach(t => error[t] = e[t])
            }
        })
        this.setData({ error });
        this.forceUpdate();
    }
}