
import { Row, Col } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { Capacitacion, conCapacitacion } from '../../Models';
import { ImageSelector } from '../../../../Media/Selector';
import { ControlArea, ControlNumber, ControlText } from "@victorequena22/component-bootstrap";
import { ColFeedSelector } from "@victorequena22/component-bootstrap";
import { Texto } from "@victorequena22/slate-bootstrap";
import { Component } from 'react';
import CategoriaSelector from '../../Categorias/Selector';
import { getText } from '../../../../Panel/ProtoPanes/DefaultData';
import SeleccionarTags from '../../../../../Components/Selectors/Seleccionar';
import { getColors } from '../../../../../Template/Colores';
interface Props {
    data: Capacitacion,
    error: any,
    setData: (data: Partial<Capacitacion>) => void,
    children?: any;
    codigo?: string;
}
export function Form({ codigo: c, data: { codigo, short, categoria, nombre, descripcion, horas, tags, resumen, contenido }, setData, error, children }: Props) {
    const colors = getColors();
    return <>
        <Row>
            <ReactTooltip />
            <Col style={{ minWidth: '20%' }} as={Row}>
                <ImageSelector img={short ? short : ''} setImg={short => setData({ short })} />
            </Col>
            <Col style={{ minWidth: '80%' }} as={Row}>
                <Col xs={3} className='mt-2'>
                    {c ? <>CÓDIGO: {c}</> :
                        <ControlText only='alphanumeric' upper maxLength={6} before='CÓDIGO' value={codigo} setData={setData} update='codigo' error={error} />
                    }</Col>
                <ColFeedSelector setData={d => setData({ categoria: d })} valor={categoria} col={9} Selector={CategoriaSelector} error={error['categoria']} />
                <Col xs={8} className='mt-2'>
                    <ControlText maxLength={100} before='NOMBRE' value={nombre} setData={setData} update='nombre' error={error} />
                </Col>
                <Col xs={4} className='mt-2'>
                    <ControlNumber before='HORAS ACADEMICAS' value={horas} setData={setData} update='horas' error={error} />
                </Col>
                <Col xs={12} className='mt-2'>
                    <label>DESCRIPCIÓN</label>
                    <ControlArea maxLength={300} value={descripcion} setData={setData} update='descripcion' error={error} />
                </Col>
                <Col xs={12} className='mt-2'>
                    <SeleccionarTags tags={tags} setTags={tags => setData({ tags })} />
                </Col>
            </Col>
        </Row>
        <Row><Col xs={12} className='mt-2'>
            <label>RESUMEN</label>
            <Texto colores={colors} error={error['resumen']} value={resumen} onChange={r => setData({ resumen: r })} />
        </Col></Row>
        <Row><Col xs={12} className='mt-2'>
            <label>CONTENIDO</label>
            <Texto colores={colors} error={error['contenido']} value={contenido} onChange={c => setData({ contenido: c })} />
        </Col></Row>
        {children}
    </>
}

export class Prototipo<p> extends Component<p, Capacitacion & { Redirect: boolean }> {
    error: any = [];
    procesar = true;
    con = conCapacitacion();
    validar = () => {
        this.error = [];
        let procesar = true;
        let { categoria, nombre, descripcion, horas } = this.state;
        if (descripcion === '') {
            this.error['descripcion'] = 'DEBE COLOCAR UNA DESCRIPCION';
            procesar = false;
        } if (nombre === '') {
            this.error['nombre'] = 'DEBE COLOCAR UN NOMBRE';
            procesar = false;
        } if (categoria === 0) {
            this.error['categoria'] = 'DEBE SELECCIONAR UNA CATEGORIA';
            procesar = false;
        } if (horas === 0) {
            this.error['horas'] = 'DEBE COLOCAR UNA CANTIDAD DE HORAS DE HORAS ACADEMICAS';
            procesar = false;
        }
        return procesar;
    }
    getDataSave = () => {
        const { codigo, short, categoria, nombre, descripcion, horas, tags, resumen, contenido } = this.state;
        return { codigo, short, categoria, nombre, descripcion, horas, tags, resumen: getText(resumen), contenido: getText(contenido), data: '{}', lista: '{}', props: '{}' };
    }
    setError = (err: any[]) => {
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            } else {
                Object.keys(e).forEach(t => this.error[t] = e[t])
            }
        })
        this.procesar = true;
        this.forceUpdate();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
}