import { Shor } from "../../../../../Panel/Models";
import { getBlockToHTML } from "../Utils/BlockToHTML";
export default class keys {
    keys: Shor[] = []
    getKeys() {
        return this.keys;
    }
    addKey(clave: string, descripcion: string, html: any) {
        this.keys.push({ clave, descripcion, html, data: {}, estatus: 1, id: 0, type: '' });
    }
    addKeyToString(clave: string, descripcion: string, html: string) {
        this.addKey(clave, descripcion, html);
        this.addKey(`${clave}_lower`, `${descripcion} EN MINUSCULAS`, html.toLowerCase());
        this.addKey(`${clave}_upper`, `${descripcion} EN MAYUSCULAS`, html.toUpperCase());
        this.addKey(`${clave}_uc`, `${descripcion} SOLO LA PRIMERA LETRA DE CADA PALABRA EN MAYUSCULAS`, html.toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase()));
    }
    getBlock(block: any[]) {
        return getBlockToHTML(block).getHTML();
    }
}