import { Col, Row } from "react-bootstrap";
import Certificados from "./Menus/Certificados";
import { Exports } from "./Menus/Exports";
import { getEvento } from "../index";
import { Copi } from "../Ver/Exports";
import { useEffect, useState } from "react";
import Iniciar from "./Menus/Iniciar";
import Finalizar from "./Menus/Finalizar";
import Filtros from "./Menus/Filtros";
import Template from "../Template";
import { getParticipantes } from "../../../Context/Participante";
import Tabla from "./Tabla";
export default function EventoParticipantes() {
    const { estatus, certificado: c, participantes: p } = getEvento();
    const l = getParticipantes();
    const [select, setSelect] = useState('all');
    const [certificado, setCertificado] = useState(c);
    const [error, setError] = useState<any>({});
    useEffect(() => setCertificado(c), [c])
    const f = p.filter(e => {
        switch (select) {
            case 'all': return true;
            case 'app': return e.status === 3;
            case 'rep': return e.status === 0;
            case 'eva': return e.status === 2;
            default: return false;
        }
    })
    return <Template icon="fa fa-users-class" title='EVALUAR PARTICIPANTES'>
        <Row>
            <Certificados errors={error} certificado={certificado} setCertificado={setCertificado} />
            {estatus > 1 ? <Copi /> : <></>}
            <Exports />
        </Row>
        {estatus === 1 ? <Row className='m-3'><Copi /></Row> : <></>}
        {estatus > 1 ? <Filtros select={select} setSelect={setSelect} /> : <></>}
        <Row className='m-3'>
            <Tabla itens={l.filter(e => f.some(k => k.id === e.id))} />
        </Row>
        <Row className='m-3'><Col className='text-right'>
            <Iniciar certificado={certificado} setErrors={setError} />
            <Finalizar />
        </Col></Row>
    </Template>
}