import { InputText } from "@victorequena22/component-bootstrap"
import { useCallback, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { Datos as d } from "../Models/Model"

interface Props {
    data: d[]
    setData: (d: d[]) => void
}
export default function Datos({ data, setData }: Props) {
    const add = useCallback((d: d) => setData([...data, d]), [data, setData]);
    const change = useCallback((i: number, d: d) => setData(data.map((o, j) => j !== i ? d : o)), [data, setData]);
    const remove = useCallback((i: number) => setData(data.filter((s, j) => j !== i)), [data, setData]);
    return <Table striped hover>
        <thead>
            <tr>
                <th>DESCRIPCIÓN</th>
                <th>INFORMACIÓN</th>
                <th style={{ width: '80px' }}></th>
            </tr>
        </thead>
        <tbody>
            <Add add={add} />
            {data.map((s, i) => <Fila key={'data' + i} data={s} i={i} setData={change} remove={remove} />)}
        </tbody>
    </Table>
}
interface PropsFila {
    i: number
    data: d
    setData: (i: number, d: d) => void
    remove: (i: number) => void
}
function Fila({ data: { descripcion, dato }, i, setData, remove }: PropsFila) {
    return <tr key={i}>
        <td>
            <InputText upper value={descripcion} setData={(descripcion) => setData(i, { dato, descripcion })} />
        </td>
        <td>
            <InputText upper value={dato} setData={(dato) => setData(i, { descripcion, dato })} />
        </td>
        <td>
            <Button variant='danger' onClick={() => remove(i)}>
                <i className="fa fa-trash"></i>
            </Button>
        </td>
    </tr>
}
function Add({ add }: { add: (d: d) => void }) {
    const [descripcion, setDescripcion] = useState('');
    const [dato, setDato] = useState('');
    return <tr>
        <td>
            <InputText value={descripcion} setData={setDescripcion} />
        </td>
        <td>
            <InputText value={dato} setData={setDato} />
        </td>
        <td className='text-right'>
            <Button variant='success' onClick={() => {
                add({ descripcion, dato });
                setDescripcion(''); setDato('');
            }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}