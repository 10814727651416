import { semaforo } from '@victorequena22/utiles';
import { Conexion, setCambio } from '../../../../Config/Coneccion';
import { FacilitadorFila } from './Model';
class facilitadorConecction extends Conexion {
    clase = 'Facilitador';
    especialidades = (set: (a: any) => void) => {
        this.get('/api/Facilitador/especialidad/lista', set, () => { });
    }
    nuevoEspecialidad = (nombre: string, set: (a: any) => void) => {
        this.post('/api/Facilitador/especialidad/nuevo', { nombre }, set, () => { });
    }
}
export const facilitadorUpdate = (c: facilitadorConecction, u: FacilitadorFila[], setData: (c: FacilitadorFila[]) => void) => {
    const s = semaforo()
    if (s.isTurn('Facilitador')) c.cambios(data => {
        if (data.length) setData(setCambio(data, u));
        s.next();
    }, () => s.next());
}
export function conFacilitador() {
    return new facilitadorConecction();
}