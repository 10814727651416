import { CSSProperties } from 'react';
import { css } from '@emotion/css';
export const imagen: CSSProperties = { display: 'block', width: '100%', height: '100%', };

export function content(
    color: string, font: string, borderColor: string, border: number, radius: number, disable: boolean) {
    return css`
        position: absolute;
        padding: 1rem 1rem 1rem 1rem;
        color: ${font};
        ${disable ? '' : `
            border: ${border}px solid ${borderColor};
            background: ${color};
            border-radius: ${radius}px;
        `}
        `;
}
//top right bottom left
export const posicion: CSSProperties[] = [
    //posicion arriba izquierda
    /* x 0 0 */
    /* 0 0 0 */
    /* 0 0 0 */
    { maxWidth: '50%', inset: '20px calc(100% - 20px) auto auto', transform: 'translateX(100%)' },
    //posicion arriba centro
    /* 0 x 0 */
    /* 0 0 0 */
    /* 0 0 0 */
    { maxWidth: 'calc(100% - 80px)', inset: '20px 50% auto auto', transform: 'translateX(50%)' },
    //posicion arriba derecha
    /* 0 0 x */
    /* 0 0 0 */
    /* 0 0 0 */
    { maxWidth: '50%', inset: '20px auto auto calc(100% - 20px)', transform: 'translateX(-100%)' },
    //posicion medio izquierda
    /* 0 0 0 */
    /* x 0 0 */
    /* 0 0 0 */
    { maxWidth: '50%', inset: '50% 30% auto 20px', transform: 'translateY(-50%)' },
    //posicion medio centro
    /* 0 0 0 */
    /* 0 x 0 */
    /* 0 0 0 */
    { maxWidth: 'calc(100% - 80px)', inset: '50% 50% auto auto', transform: 'translate(50%, -50%)' },
    //posicion medio derecha
    /* 0 0 0 */
    /* 0 0 x */
    /* 0 0 0 */
    { maxWidth: '50%', inset: '50% auto auto calc(100% - 20px)', transform: 'translate(-100%, -50%)' },
    //posicion abajo izquierda
    /* 0 0 0 */
    /* 0 0 0 */
    /* x 0 0 */
    { maxWidth: '50%', inset: 'auto calc(100% - 20px) 30px auto', transform: 'translateX(100%)' },
    //posicion abajo centro
    /* 0 0 0 */
    /* 0 0 0 */
    /* 0 x 0 */
    { maxWidth: 'calc(100% - 80px)', inset: 'auto 50% 30px auto', transform: 'translateX(50%)' },
    //posicion abajo derecha
    /* 0 0 0 */
    /* 0 0 0 */
    /* 0 0 x */
    { maxWidth: '50%', inset: 'auto auto 30px calc(100% - 20px)', transform: 'translateX(-100%)' },
]

