import { isLine } from "./OnLineCheker";
export type setData<p = any> = (d: p) => void;
// export const don = 'http://talentoback.test';
export const don = '';
// export const don = 'https://admin.talentoeinnovacion.net';

class Prototipo {
    clase = '';
    state = '';
    init = true;
    getCache = (url: string, setData: setData) => {
        const cache = localStorage.getItem(url);
        if (cache !== null) setData(JSON.parse(cache));
    }
    setCache = (path: string, data: any) => {
        localStorage.removeItem(path);
        localStorage.setItem(path, JSON.stringify(data));
    }
    getProxi = async (path: string) => new Promise<any>(async (resolve, reject) => {
        const token = localStorage.getItem('Authorization');
        const url = don + '/api/' + this.clase + path;
        try {
            const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            const data = await response.json();
            if (data.error) reject(data.error);
            resolve(data);
        } catch (err) {
            resolve(err);
        }
    });
    getProxiCache = async (path: string, setData: setData, setError?: setData) => {
        const url = don + '/api/' + this.clase + path;
        this.getCache(url, setData);
        const token = localStorage.getItem('Authorization');
        try {
            const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            const data = await response.json();
            if (data.error && setError) setError(data.error);
            setData(data);
            this.setCache(url, data);
        } catch (err) {
            if (setError) setError(err);
        }
    }
    get = async (url: string, setData: setData, setError?: setData) => {
        const token = localStorage.getItem('Authorization');
        try {
            const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            const data = await response.json();
            if (data.error && setError) setError(data.error);
            setData(data);
        } catch (err) {
            if (setError) setError(err);
        }
    }
    postProxi = (path = '', obj = {}, success = (d: any) => { }, error = (d: any) => { }) => {
        const url = don + '/api/' + this.clase + path;
        this.post(url, obj, success, error)
    }
    post = (url = '', obj: any = {}, success = (d: any) => { }, error = (d: any) => { }) => {
        let token = localStorage.getItem('Authorization');
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open("POST", url);
        if (token !== null)
            xhr.setRequestHeader('Authorization', 'Bearer ' + token)
        xhr.onreadystatechange = (e) => {
            if (xhr.readyState !== 4) {
                return;
            }
            if (xhr.status === 200) {
                if (xhr.response.errores) error(xhr.response.errores);
                else success(xhr.response);
            } else {
                if (isLine())
                    error(xhr);
            }
        };
        xhr.send(this.FormDataFotmat(obj));
    }
    FormDataFotmat = (o: any) => {
        const formData = new FormData();
        Object.keys(o).forEach(key => {
            switch (typeof o[key]) {
                case 'object':
                    formData.append(key, JSON.stringify(o[key]));
                    break;
                default:
                    formData.append(key, o[key]);
            }
        });
        return formData;
    };
}
export class Conexion<P = any, L = any> extends Prototipo {
    cambios = (setData: setData<L[]>, setNot?: () => void) => {
        this.getProxiCache('/cambios/' + this.state.replace(' ', '/'),
            data => {
                if (data.data.length !== 0) {
                    this.state = data.fecha;
                    setData(data.data);
                }
                if (setNot) setNot()
            }, setNot);
    }
    lista = async (setData: setData<L[]>) => {
        try {
            const data = await this.getProxi('/lista');
            if (Array.isArray(await data)) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    detalle = async (codigo: string | number, setData: setData<P>) => {
        try {
            const data = await this.getProxi('/detalles/' + codigo);
            setData(await data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    cancelar = async (codigo: string | number, setData?: setData<P>) => {
        try {
            const data = await this.getProxi('/cancelar/' + codigo);
            if (setData) setData(data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    nuevo = (data: P, setData?: setData<P>, setError?: setData) => {
        this.post(don + '/api/' + this.clase + '/nuevo', data, setData, setError)
    }
    actualizar = (codigo: string | number, data: P, setData?: setData<P>, setError?: setData) => {
        this.post(don + '/api/' + this.clase + '/actualizar/' + codigo, data, setData, setError);
    }
}
export class Conexion2<P = any, L = any> extends Conexion<P, L> {
    cambiosData = (data: keyof L, setData: setData<L[]>, setNot?: setData) => {
        this.postProxi('/cambios/' + this.state.replace(' ', '/'), { data }, setData, setNot);
    }
    listaData = (data: keyof L, setData: setData<L[]>, setNot?: setData) => {
        this.postProxi('/lista', { data }, setData, setNot);
    }
    detalleData = (codigo: string | number, data: (keyof P)[], setData: setData<P>, setNot?: setData) => {
        this.postProxi('/detalles/' + codigo, { data }, setData, setNot);
    }
}
class Commos extends Conexion {
    clase = 'Commons';
    tags = async (setData: setData) => {
        this.get('/api/Commons/tags', setData);
    }
}
export function conCommos() {
    return new Commos();
}
export function setCambio(data: any[], c: any[]) {
    const push = data.filter(d => {
        const i = c.findIndex(c => c.id === d.id);
        if (i > -1) {
            c[i] = d;
            return false;
        }
        return true;
    });
    return [...c, ...push];
}