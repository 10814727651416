
import { Link } from "react-router-dom";
import Empresa from '../../App/Config/Empresa';
import ListaRoles from '../../App/Config/Rol';
import { useContext } from 'react';
import { SeccionContext, SeccionType } from "../../Template/Seccion";
import { Ruta } from "../../Template/Ruta";
import { MenuIten, Menus } from "../../Components/Contens";
export function MenuGenerales() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    return <Menus title='CONFIGURACIONES GENERALES'>
        <Link className='btn btn-default' to='/config/empresa'>EMPRESA</Link>
        <MenuIten p={Permisos} to='/config/roles' permiso='CON_ROLES' >ROLES</MenuIten>
    </Menus>
}
export function RutasGenerales() {
    return <>
        <Ruta permiso="CON_EMPRESA" path="/config/empresa" component={Empresa} />
        <Ruta permiso="CON_ROLES" path="/config/roles" component={ListaRoles} />
    </>
}