import { Modal, Row, Col } from 'react-bootstrap';
import { ParticipanteFila } from './Models';
import { VerContry, VerEstado } from '../../../Components/Contry/Ver';
interface Props {
    open: boolean
    participante: ParticipanteFila
    close: () => void
}
export default function VerParticipante({ participante: { nombre, telefono, correo, ciudad, direccion, pais, estado }, open, close }: Props) {
    return <Modal animation show={open} size='xl' centered onHide={close}>
        <Modal.Header className='bg-primary' closeButton>
            <h4 className='mr-auto'>DATOS DEL PARTICIPANTE</h4>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12}><b>NOMBRE:</b> {nombre}</Col>
                <Col md={4}><b>TELEFONO:</b> {telefono}</Col>
                <Col md={8}><b>CORREO:</b> {correo}</Col>
                <Col md={4}><VerContry key={pais} pais={pais} /></Col>
                <Col md={4}><VerEstado key={pais + estado} pais={pais} estado={estado} /></Col>
                <Col md={4}><b>CIUDAD:</b> {ciudad}</Col>
                <Col md={12}><b>DIRECCION:</b> {direccion}</Col>
            </Row>
        </Modal.Body>
    </Modal>
}
