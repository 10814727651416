import { CardConfig, RowConfig, TitleCard } from "@victorequena22/component-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFacilitador } from '../../index';

export default function MenuTemplate() {
    const { id } = getFacilitador()
    return <CardConfig width='37px'>
        <RowConfig>
            <Button data-place='bottom' data-tip='CREAR UN NUEVO PANEL' as={Link}
                to={`/facilitador/landing/${id}/crear`} size='sm' variant='success'>
                <i className='fas fa-plus' />
            </Button>
        </RowConfig>
        <TitleCard>*</TitleCard>
    </CardConfig>
}