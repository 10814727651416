
import { Row, Col, Card, FormGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { Component, useCallback, useContext, useEffect } from 'react';
import { ImageSelector } from '../../../../Media/Selector';
import { ControlArea, ControlDate, ControlNumber, ControlText } from "@victorequena22/component-bootstrap";
import { Evento } from '../../Models/Model';
import { conEvento } from '../../Models/Coneccion';
import { ColFeedSelector } from "@victorequena22/component-bootstrap";
import ProgramaSelector from '../../../Programas/Selector';
import { AvalSelectorMultiple } from '../../Parametros/Aval/SelectorMultiple';
import { FacilitadorSelectorMultiple } from '../../../Facilitadores/SelectorMultiple';
import NormaSelector from '../../Parametros/Normas/Selector';
import ConsideracionesSelector from '../../Parametros/Consideracion/Selector';
import SeleccionarTags from '../../../../../Components/Selectors/Seleccionar';
import { ProgramaContext, findProgram } from '../../../Context/Programas';
import { Title, MemuEvento } from '../Menu';
import { Direccion } from './Direccion';
interface Props {
    data: Evento,
    error: any,
    setData: (data: Partial<Evento>) => void,
    children?: any;
    codigo?: string;
}
export function Form({ codigo: c, data, setData, error, children }: Props) {
    const { herencia, norma, inicio, final, consideracion, avales, facilitadores, codigo, tags, nombre, descripcion, short, cohorte, fecha, programa } = data;
    const { programas } = useContext(ProgramaContext);
    const { tags: h2 } = findProgram(programa)
    const genCodigo = useCallback(() => {
        if (codigo === '') {
            const cap = programas.find(c => c.id === programa);
            if (cap !== undefined) setData({ codigo: cap.codigo })
        }
    }, [codigo, programas, setData, programa]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => genCodigo(), [programa])
    const padding = '0 7.5px 0 7.5px'
    return <Card.Body style={{ minHeight: 'calc(100vh - 210px)' }}>
        <ReactTooltip />
        <Title key={`title${data.id}`} />
        <Row className='d-md-flex flex-row-reverse'>
            {c ? <Col md={3}><MemuEvento key={`menu${data.id}`} /></Col> : <></>}
            <Col md={c ? 9 : 12}>
                <Row>
                    <Col style={{ minWidth: '20%' }} as={Row}>
                        <ImageSelector img={short ? short : ''} setImg={short => setData({ short })} />
                    </Col>
                    <Col style={{ minWidth: '80%' }} as={Row}>
                        <Col xs={3}>{c ? <>CÓDIGO: {c}</> :
                            <ControlText only='alphanumeric' upper maxLength={20} before='CODIGO' value={codigo} setData={setData} update='codigo' error={error} />
                        }</Col>
                        <Col xs={9}>
                            <ControlText maxLength={150} before='NOMBRE' value={nombre} setData={setData} update='nombre' error={error} />
                        </Col>
                        <Col xs={9} className='mt-2' as={FormGroup} hasValidation={error['programa'] ? true : false} >
                            <ProgramaSelector change={d => setData({ programa: c ? programa : d })}
                                defaultValue={programa} tipo='SELECCIONAR' isInvalid={!!error['programa']} />
                        </Col>
                        <Col xs={3} className='mt-2'>
                            <ControlNumber decimal={0} before='COHORTE' value={cohorte} update='cohorte' setData={setData} error={error} />
                        </Col>
                        <div style={{ padding, width: 'calc(100% - 30rem)' }} className='mt-2'>
                            <ControlText maxLength={100} before='FECHA' value={fecha} setData={setData} update='fecha' error={error} />
                        </div>
                        <div style={{ padding, width: '15rem' }} className='mt-2'>
                            <ControlDate before='INICIO' value={inicio} update='inicio' setData={setData} error={error} />
                        </div>
                        <div style={{ padding, width: '15rem' }} className='mt-2'>
                            <ControlDate before='FINAL' value={final} update='final' setData={setData} error={error} />
                        </div>
                        <Direccion data={data} setData={setData} error={error} />
                        <Col xs={12} className='mt-2'>
                            <SeleccionarTags key='etiquetasEventos' herencia={herencia.length ? herencia : h2} tags={tags} setTags={tags => setData({ tags })} />
                        </Col>
                    </Col>
                    <Col xs={12} className='mt-1'>
                        <label>DESCRIPCIÓN</label>
                        <ControlArea className='mt-1' maxLength={500} value={descripcion} setData={setData} update='descripcion' error={error} />
                    </Col>
                    <ColFeedSelector setData={d => setData({ norma: d })} valor={norma} col={6} Selector={NormaSelector} error={error['norma']} />
                    <ColFeedSelector setData={d => setData({ consideracion: d })} valor={consideracion} col={6} Selector={ConsideracionesSelector} error={error['consideracion']} />
                    <Col xs={6} className='mt-2'>
                        <FacilitadorSelectorMultiple facilitadores={facilitadores} setFacilitadores={facilitadores => setData({ facilitadores })} />
                    </Col>
                    <Col xs={6} className='mt-2'>
                        <AvalSelectorMultiple avals={avales} setavals={avales => setData({ avales })} />
                    </Col>
                </Row>
                {children}
            </Col>
        </Row >
    </Card.Body >
}
export class Prototipo<p> extends Component<p, Evento & { Redirect: boolean, cargando?: boolean }> {
    error: any = [];
    procesar = true;
    con = conEvento();
    validar = () => {
        this.error = [];
        let procesar = true;
        // const { norma, consideracion, avales, facilitadores, codigo, tags, estado, ciudad, nombre, descripcion, short, pais, direccion, cohorte, fecha, programa } = this.state;

        return procesar;
    }
    getDataSave: () => any = () => {
        const { norma, consideracion, avales, inicio, final, facilitadores, codigo, tags, estado, ciudad, nombre, descripcion, short, pais, direccion, cohorte, fecha, programa } = this.state;
        return { norma, consideracion, avales, inicio, final, facilitadores, codigo, tags, estado, ciudad, nombre, descripcion, short, pais, direccion, cohorte, fecha, programa };
    }
    setError = (err: any[]) => {
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            } else {
                Object.keys(e).forEach(t => this.error[t] = e[t])
            }
        })
        this.setState({ cargando: false })
        this.procesar = true;
        this.forceUpdate();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
    update(p: any, s: Evento) { }
}