var estado = (table: string) => { };
export function setEstadoUpdater(line: (table: string) => void) {
    estado = line;
}
export function setEstado(table: string) {
    estado(table);
}

export function IE({ id, estatus }: { id: number, estatus: number }) {
    return `${id}${estatus}`;
}
export function IEL(d: { id: number, estatus: number }[]) {
    return d.map(IE).join('');
}