import { InputDate, InputNumber, InputText } from "@victorequena22/component-bootstrap";
import { useCallback, useState } from "react";
import { Button, ButtonGroup, Dropdown, Row, Table } from "react-bootstrap";
import { THInput } from "@victorequena22/tabla-bootstrap";
import { conPrecios, Precios as pre } from "../../Models/Precio";
import { getEvento, update } from "../index";
export default function Precios() {
    const evento = getEvento();
    return <>
        <Row><h5 className='text-info'>PRECIOS</h5></Row>
        <Table className="w-100">
            <tr><th>NOMBRE</th><th align="right">PRECIO</th><th align="center">INICIO</th><th align="center">FIN</th></tr>
            <Crear />
            {evento.precios.map(p => <Fila data={p} />)}
        </Table>
    </>


}
function Fila({ data: { nombre: n, inicio: i, final: f, precio: p, id, estatus } }: { data: pre }) {
    const [nombre, setNombre] = useState(n);
    const [inicio, setInicio] = useState(i);
    const [final, setFinal] = useState(f);
    const [precio, setPrecio] = useState(p);
    const save = useCallback(() => {
        conPrecios().actualizar(id, { nombre, precio, inicio, final }, update)
    }, [nombre, precio, inicio, final, id])
    const deletes = useCallback(() => { conPrecios().delete(id, update) }, [id])
    const statusChage = useCallback((estatus: number) => { conPrecios().statusChage(id, estatus, update) }, [id])
    const variant = ['dark', 'warning', 'light', 'info', 'info', 'info']
    return <tr>
        <THInput width='auto'>
            <InputText value={nombre} setData={setNombre} />
        </THInput>
        <THInput width='150px'>
            <InputNumber value={precio} setData={setPrecio} />
        </THInput>
        <THInput width='200px'>
            <InputDate value={inicio} setData={setInicio} />
        </THInput>
        <THInput width='200px'>
            <InputDate value={final} setData={setFinal} />
        </THInput>
        <td width='140px'>
            <ButtonGroup>
                <Dropdown>
                    <Dropdown.Toggle variant={variant[estatus]} id="dropdown-basic">
                        <i className="fa fa-eye" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0 m-0">
                        <Dropdown.Item className="bg-dark" onClick={() => statusChage(0)}>OCULTAR</Dropdown.Item>
                        <Dropdown.Item className="bg-warning" onClick={() => statusChage(1)}>VISIBLE SOLO EN EL RANGO DE FECHAS</Dropdown.Item>
                        <Dropdown.Item className="bg-light" onClick={() => statusChage(2)}>SIEMPRE VISIBLE</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button variant="success" onClick={save}>
                    <i className="fa fa-save" />
                </Button>
                <Button variant="danger" onClick={deletes}>
                    <i className="fa fa-ban" />
                </Button>
            </ButtonGroup>
        </td>
    </tr>
}
function Crear() {
    const { id } = getEvento();
    const [nombre, setNombre] = useState('');
    const [inicio, setInicio] = useState('');
    const [final, setFinal] = useState('');
    const [precio, setPrecio] = useState(0);
    return <tr>
        <THInput width='auto'>
            <InputText value={nombre} setData={setNombre} />
        </THInput>
        <THInput width='150px'>
            <InputNumber value={precio} setData={setPrecio} />
        </THInput>
        <THInput width='200px'>
            <InputDate value={inicio} setData={setInicio} />
        </THInput>
        <THInput width='200px'>
            <InputDate value={final} setData={setFinal} />
        </THInput>
        <td width='140px'>
            <Button variant="success"
                onClick={() => conPrecios().nuevo({ evento: id, nombre, precio, inicio, final }, () => {
                    update(); setNombre(''); setInicio(''); setFinal(''); setPrecio(0);
                })}>
                <i className="fa fa-save" />
            </Button>
        </td>
    </tr>
}

