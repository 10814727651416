
import { Button, Card } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Form, Prototipo } from '../Auxiliar/Form';
import { toast } from 'react-toastify';
import { auxiliarNull, conConsideracion } from '../../Models';
export default class NuevaConsideracion extends Prototipo<any> {
    con = conConsideracion();
    constructor(p: any) {
        super(p)
        this.state = { ...auxiliarNull, Redirect: false };
    }
    render = () => {
        let { setData, error, state, create } = this;
        if (state.Redirect) return <Redirect to='/evento/config/consideraciones' />
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVO CONSIDERACIÓN </h4>
            </Card.Header>
            <Form data={state} setData={setData as any} error={error}>
                <Button block variant='success' className='float-right' onClick={create}>
                    <i className="fa fa-save"></i> Guardar
                </Button>
            </Form>
        </Card >)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                this.con.nuevo(this.getDataSave(), () => {
                    this.procesar = true;
                    toast('CONSIDERACIÓN CREADA', { type: toast.TYPE.SUCCESS });
                    this.setState({ Redirect: true });
                }, this.setError)
            }
        } else this.forceUpdate();
    }
}