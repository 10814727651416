import { MetodoPago } from '../../Pagos/MetodosPago/Models/Model';
import { defaultBlock } from "@victorequena22/slate-bootstrap"
import { BlockElement } from "../../../Panel/ProtoPanes/DefaultData"
import { Precios } from "./Precio"
import { Interesado, Suscriptores } from '../../Programas/Models/Models';

export interface TypeAuxiliar {
    id: number,
    nombre: string,
    texto: BlockElement[],
    estatus: number
}
export interface TypeAuxiliarLista {
    id: number,
    nombre: string,
    estatus: number
}
export const auxiliarNull: TypeAuxiliar = {
    id: 0,
    nombre: '',
    texto: defaultBlock,
    estatus: 0
}
export interface Costo {
    id: number,
    nombre: string,
    costo: number,
    estatus: number
}
export const costoNull = {
    id: 0,
    nombre: '',
    costo: 0,
    estatus: 0
}

export interface EventoLista {
    id: number;
    short?: string;
    cohorte: number;
    codigo: string;
    programa: number;
    nombre: string;
    descripcion: string;
    fecha: string;
    inicio: string;
    final: string;
    pais: string;
    estado: string;
    ciudad: string;
    direccion: string;
    estatus: number;
    publicacion: number;
    usuario: number;
    lista: any;
    facilitadores: number[];
    avales: number[];
    tags: number[];
}
export interface Participante {
    id: number;
    status: number;
}
export interface Encuesta {
    participante: number;
    respuesta: string;
}
export interface Evento extends EventoLista {
    precios: Precios[];
    certificado: number;
    herencia: number[];
    metodos: MetodoPago[];
    participantes: Participante[];
    encuesta: Encuesta[];
    consideracion: number;
    norma: number;
    props: any;
    data: any;
    interesados: Interesado[];
    suscriptores: Suscriptores[];
}
export const eventoNull: Evento = {
    id: 0,
    precios: [],
    metodos: [],
    encuesta: [],
    certificado: 0,
    short: '',
    cohorte: 0,
    codigo: '',
    programa: 0,
    nombre: '',
    descripcion: '',
    fecha: '',
    inicio: '',
    final: '',
    pais: '',
    estado: '',
    ciudad: '',
    direccion: '',
    estatus: 0,
    publicacion: 0,
    usuario: 0,
    participantes: [],
    lista: {},
    tags: [],
    herencia: [],
    facilitadores: [],
    avales: [],
    consideracion: 0,
    norma: 0,
    props: {},
    data: {},
    interesados: [],
    suscriptores: []
}