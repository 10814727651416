/* eslint-disable react-hooks/rules-of-hooks */

import { Route } from "react-router-dom";
import MenuCreacion from "./MenuCreacion";
import { getPrograma } from "../index";
import Crear from "./Crear";
import EditarPanel from "./Editar";
import ShortProvider from "../../../../Panel/Context/Shorts";
import { Evento, conEvento, conConsideracion, conNorma, eventoNull, auxiliarNull } from '../../../Evento/Models';
import LandingProvider from "./Context";
import { Ver } from "./Ver";
import { findCategoria } from "../../../Context/Categorias";
import { getKeyPrograma } from "../Landing/Keys/Programa";
import { getKeyCategoria } from "../Landing/Keys/Categoria";
import { getEventos } from "../../../Context/Eventos";
import { useEffect, useState } from "react";
import { getKeyEvento } from "./Keys/Evento";
export default function EventoProgama() {
    const p = getPrograma();
    Math.floor(Math.random() * 10);
    const s = findCategoria(p.categoria)
    const k = getKeyPrograma(p).getKeys();
    const d = getKeyCategoria(s).getKeys();
    const e = getEvento(p.id);
    const n = getNorma(e.norma);
    const c = getConsideraciones(e.consideracion);
    const h = getKeyEvento(e, n, c).getKeys();
    return <ShortProvider lista={[...k, ...d, ...h]}><LandingProvider>
        <Route path={`/programa/evento/${p.id}`} exact><Ver /></Route>
        <Route path={`/programa/evento/${p.id}/crear`} exact><MenuCreacion /></Route>
        <Route path={`/programa/evento/${p.id}/crear/:type`}><Crear /></Route>
        <Route path={`/programa/evento/${p.id}/edit/:mid`}><EditarPanel /></Route>
    </LandingProvider></ShortProvider >
}
const getEvento = (id: number) => {
    const e = getEventos().find(e => e.programa === id);
    const [r, set] = useState<Evento>(eventoNull);
    useEffect(() => {
        if (e) conEvento().detalle(e.id, (e: Evento) => {
            if (JSON.stringify(e) !== JSON.stringify(r)) set(e)
        })
    }, [e, r]);
    return r;
}
const getNorma = (id: number) => {
    const [n, set] = useState(auxiliarNull);
    useEffect(() => {
        if (id) conNorma().detalle(id, (e: any) => {
            if (JSON.stringify(e) !== JSON.stringify(n)) set(e)
        })
    }, [id, n]);
    return n;
}
const getConsideraciones = (id: number) => {
    const [c, set] = useState(auxiliarNull);
    useEffect(() => {
        if (id) conConsideracion().detalle(id, (e: any) => {
            if (JSON.stringify(e) !== JSON.stringify(c)) set(e)
        })
    }, [id, c]);
    return c;
}