
import { CardConfig, RowConfig, TitleCard } from '@victorequena22/component-bootstrap';
import { State } from './index'
import { CardMain, ButtonColor, Guardar, SizeViewSelector, Title } from '../../../../../../Panel/Component';
import { TBElement } from '../../../../../../Panel/Component/SlateCustom';
import { CropModal } from '../../../../../../Media/CropModal';
import Formulario from '../../Utils/Formulario';
interface Props {
    save: () => void,
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, img, backgroundColor } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='256px' >
            <RowConfig>
                <Title setData={setTitle} title={title} width='250px' />
            </RowConfig>
            <RowConfig top={2}>
                <CropModal aspect={1} img={img} setImg={(img) => setConfig({ img })} />
                <ButtonColor disableAlpha tip='COLOR DE FONDO DEL PANEL'
                    color={backgroundColor} className='ml-2' gradiend
                    setData={backgroundColor => setConfig({ backgroundColor })} >
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <TBElement />
        <Formulario config={config} setConfig={setConfig} />
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain >
}