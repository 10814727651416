import { Button, Card } from 'react-bootstrap';
import { Redirect, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Form, Prototipo } from '../Auxiliar/Form';
import { toast } from 'react-toastify';
import { conNorma, TypeAuxiliar } from '../../Models';
import { Cargando } from '@victorequena22/component-bootstrap';
export default function EditNormas() {
    let { id } = useParams<{ id: any }>();
    const [c, set] = useState<any>({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { conNorma().detalle(id, set); }, [])
    if (c.id) return <Edit data={c} />
    return <Cargando />
}
interface Props {
    data: TypeAuxiliar
}
class Edit extends Prototipo<Props> {
    con = conNorma();
    random = Math.random();
    constructor(p: Props) {
        super(p);
        this.state = { ...p.data, Redirect: false };
        this.random = Math.random();
    }
    render = () => {
        const { setData, error, state, create, random } = this;
        if (state.Redirect) return <Redirect to='/evento/config/normas' />
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> ACTUALIZAR NORMA {state.id} </h4>
            </Card.Header>
            <Form key={`norma${random}`} data={state} setData={setData as any} error={error}>
                <Button variant='success' block className='float-right' onClick={create}>
                    <i className="fa fa-save"></i> Guardar
                </Button>
            </Form>
        </Card >)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                this.con.actualizar(this.props.data.id, this.getDataSave(), () => {
                    this.procesar = true;
                    toast('NORMA ACTUALIZADA', { type: toast.TYPE.SUCCESS });
                    this.setState({ Redirect: true });
                }, this.setError)
            }
        } else this.forceUpdate();
    }
}