import { useEffect, useContext } from 'react'
import { Card, Modal, Row } from 'react-bootstrap';
import { Panel } from '../../Panel/Models';
import { PanelContext } from '../../Panel/Context/Panel';
import { Slect } from '../Utils';
import { PreviewSize } from './PreviewSize';
import { MenuCreacion } from '../../Panel/Utiles';
import Seled from '../../Panel/Utiles/SelectedCreatePAnel';
export default function Selector() {
    return <Card>
        <Card.Header className='bg-info'><h4><b>SECCIONAR EL TIPO DE PANEL</b></h4></Card.Header>
        <Card.Body>
            <Row><h3 className='ml-2 text-info'>Paneles por defecto</h3></Row>
            <MenuCreacion /><hr />
            <Row><h3 className='ml-2 text-info'>Mis Paneles</h3></Row>
            <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
                <Seled to="/panel/eventos" src="/static/panels/var/4.jpg" title="EVENTOS" />
                <Seled to="/panel/programas" src="/static/panels/var/4.jpg" title="PROGRAMAS" />
            </Row>
        </Card.Body>
    </Card>
}
export function SelectorPanel({ set, filter }: { set: (da: Panel) => void, filter?: (p: Panel) => boolean }) {
    const { panels, updatePanel } = useContext(PanelContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updatePanel, [])
    const p = filter ? panels.filter(filter) : panels;
    return <>{p.map(p => <>
        <Slect panel={p} select={set} />
        <div className='w-100'>
            <PreviewSize key={p.type + p.id} panel={p} size='1080px' />
        </div>
    </>)}</>
}
export function SelecPanelModal({ set, open, close, filter }: { filter?: (p: Panel) => boolean, set: (d: any) => void, open: boolean, close: () => void }) {
    return <Modal size='xl' show={open} onHide={close} centered className=' modal-warning' >
        <Modal.Header style={{ borderRadius: 0 }} closeButton>
            <h4 className="m-0 font-weight-bold mr-auto align-self-center">
                <span><b>SELECCIONAR PANEL</b></span>
            </h4>
        </Modal.Header>
        <Modal.Body >
            <Row style={{ padding: "10px 10px 10px 10px" }}>
                <SelectorPanel set={p => { set(p); close() }} filter={filter} />
            </Row>
        </Modal.Body>
    </Modal>
}




