import { InputText } from "@victorequena22/component-bootstrap";
import { CardMain, SizeViewSelector, Title, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { TBElement } from '../../Component/SlateCustom'
import { NotaMenu } from '../../Component/Commons';
import { State } from './index'
interface Props { save: () => void, title: string; config: State; setTitle: (d: string) => void; setConfig: (d: any) => void }
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, backgroundColor, texto, video } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='340px' >
            <RowConfig>
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <InputText data-tip='LINK DEl VIDEO' style={{ height: '24px' }} className='form-control-sm' setData={(s) => setConfig({ video: setLink(s) })} value={video} />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor gradiend tip='COLOR DE FONDO DEL PANEL' className='ml-1' style={{ width: 32 }} color={backgroundColor} setData={(backgroundColor) => setConfig({ backgroundColor })}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <NotaMenu config={config} setConfig={setConfig} />
        {texto ? <TBElement /> : <></>}
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain>
}
function setLink(s: string) {
    if (s.indexOf('youtube') > -1)
        s = s.replace('watch?v=', 'embed/');
    if (s.indexOf('drive.google.com/file/d') > -1)
        s = s.replace('view?usp=sharing', 'preview');
    return s;
}