import React from 'react'
import { FormControl } from 'react-bootstrap';
import { conUsuario, Rol } from '../../Usuarios/Usuario';

interface Props {
    change: (r: number) => void
    defaultValue: number
    isInvalid?: boolean
}
interface State {
    roles: Rol[] | 'carro'
}
export default class RolSelector extends React.Component<Props, State>{
    constructor(p: Props) {
        super(p);
        this.state = { roles: 'carro' };
    }
    setUsers(c: Rol[]) {
        this.setState({ roles: c })
    }
    render() {
        return <FormControl as='select' onChange={this.setRol.bind(this)} 
        value={this.props.defaultValue} isInvalid={this.props.isInvalid}>
            <option value={-1}>SELECCIONAR ROL</option>
            {(this.state.roles !== 'carro') ? this.state.roles.map(c => {
                return <option key={"unids" + c.id} value={c.id}>{c.nombre}</option>
            }) : <></>}
        </FormControl>
    }
    componentDidMount() {
        this.set();
    }
    setRol(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let value = Rol.currentTarget.value as string
        let r = parseInt(value)
        this.props.change(r)
    }
    set() {
        conUsuario().listaRoles(this.setUsers.bind(this));
    }

}