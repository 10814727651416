import { EditArea } from '../../Component/SlateCustom'
import { Navbar, Nav, Container } from 'react-bootstrap';
import { getCSS } from './props';
import { State } from './index';
export function Panel({ config: { height, orientacion, backgroundColor, color, backgroundColorHover, colorHover, img, size }, setData }: { config: State, setData: (s: Partial<State>) => void }) {
    return <Navbar expand="lg" className={getCSS(backgroundColor, color, backgroundColorHover, colorHover)}>
        <Container>
            <div className='d-flex navbar-brand'>
                <img height={height + 'px'} className="img-responsive" src={img} alt="Jango" />
                <div>
                    <EditArea />
                </div>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`m${orientacion ? 'l' : 'r'}-auto`}>

                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}


