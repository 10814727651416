import React from 'react';
import { Modal, Row, Button, Col } from 'react-bootstrap'
import { ControlText } from "@victorequena22/component-bootstrap";
import { conRol } from '../Aliado';
import { SelectorPermisos } from './SelectorPermisos';
interface Props {
    open: boolean
    close: () => void
    actualizar: () => void
}
interface State {
    nombre: string
    acciones: number[]
}
export default class NewRol extends React.Component<Props, State> {
    con = conRol();
    state = { acciones: [], nombre: '' };
    render() {
        const { props: { close, open }, state: { acciones } } = this;
        return <Modal size='xl' animation centered show={open} onHide={close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                    NUEVO ROL
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <ControlText before="MONBRE DEL ROL" update="nombre" setData={this.setState.bind(this)} value={this.state.nombre} /><br />
                        <SelectorPermisos selecteds={acciones} Click={this.click.bind(this)} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.save.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal >
    }
    click(id: number) {
        const { acciones } = this.state;
        if (acciones.every(p => p !== id)) this.setState({ acciones: [...acciones, id] });
        else this.setState({ acciones: acciones.filter(p => p !== id) });
    }
    save() {
        let { nombre, acciones } = this.state;
        this.con.nuevo({ nombre, acciones }, () => {
            this.props.actualizar()
            this.props.close()
        });
    }
}