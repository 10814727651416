import React from "react"
import { Button, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { InputArea, InputText } from "@victorequena22/component-bootstrap";
import { ImageSelector } from '../../../Media/Selector';
import { Categoria, categoriaNull, conCategoria } from "./Models";
interface Props {
    update?: () => void
}
export default class NuevoCategoria extends React.Component<Props, Categoria> {
    con = conCategoria()
    state = categoriaNull;
    render() {
        let { nombre, short, codigo } = this.state
        return <>
            <label>NUEVA CATEGORIA</label>
            <Table>
                <tr>
                    <td rowSpan={3} style={{ width: "160px" }}>
                        <ImageSelector img={short} setImg={short => this.setState({ short })} />
                    </td>
                    <td rowSpan={3} style={{ width: "calc(100% - 300px)" }}>
                        <ReactTooltip />
                        <InputArea style={{ height: "130px" }} maxLength={150} next={this.nuevo} placeholder='NOMBRE' upper setData={this.setState.bind(this)} value={nombre} update="nombre" />
                    </td>
                    <td style={{ width: "300px" }}>
                        <InputText maxLength={20} next={this.nuevo} placeholder='URL' upper setData={this.setState.bind(this)} value={codigo} update="codigo" />
                    </td>
                </tr>
                <tr><td style={{ width: "150px" }}></td></tr>
                <tr>
                    <td style={{ width: "150px" }}>
                        <Button block variant="success" onClick={this.nuevo}>
                            <i className="fa fa-save"></i> GUARDAR
                        </Button>
                    </td>
                </tr>
            </Table></>
    }
    nuevo = () => {
        let { nombre, codigo } = this.state;
        if (nombre === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } if (codigo === '') {
            toast('DEBE COLOCAR UN CODIGO', { type: toast.TYPE.ERROR })
        } else {
            let { update } = this.props;
            this.con.nuevo(this.state, () => {
                if (update) update();
                this.setState(categoriaNull);
                toast('CREADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS })
            });
        }
    }
}