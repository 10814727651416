import { SVGR, SVGL, d } from '../EditPanel/Paralax1/SVG';
import '../EditPanel/Paralax1/style.css';
import { Html } from '../Component/Commons';

export function Paralax1({ size, data: { props: { color, img, ori, variant }, html } }: any) {
    return <div style={{ backgroundColor: color }} className="box">
        <div className={`paralax ${d.some(a => a === size) ? 'min' : 'max'}`}>
            <div style={{ float: ori ? 'left' : 'right', backgroundImage: `url(${img})` }} className="c-bg-parallax c-feature-bg c-content" />
            <div className="c-content-area c-content-ori"></div>
            <div style={{ background: `${color}`, float: ori ? 'right' : 'left' }} className="c-feature-content c-ori">
                <SVGL size={size} variant={variant} fill={color} ori={ori} />
                <div style={{ background: `${color}`, float: ori ? 'right' : 'left' }} className="c-content-v-center w-100 d-flex justify-content-center">
                    <div className="c-wrapper w-100 h-100  d-flex flex-row align-items-center">
                        <Html className='c-body w-100' html={html} />
                    </div>
                </div>
            </div>
            <SVGR size={size} variant={variant} fill={color} ori={ori} />
        </div>
    </div>
}