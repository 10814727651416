import React from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import FormUsuario from "./FormUsuario"
import FormClave from "./FormClave"
import { THFila } from "@victorequena22/tabla-bootstrap"
import { conUsuario, UsuarioFila } from "./Usuario"
interface Props {
    d: UsuarioFila
    update?: () => void
}
interface State extends UsuarioFila {
    edit: boolean,
    cambiar_clave: boolean
}
export class FilaUsuario extends React.Component<Props, State> {
    con = conUsuario()
    constructor(p: Props) {
        super(p)
        this.state = { ...p.d, edit: false, cambiar_clave: false}
    }
    render() {
        let { nombre, correo, rol, estado, edit, cambiar_clave } = this.state;
        return <tr>
            <THFila width="calc(100% - 680px)">
                {nombre}
            </THFila>
            <THFila width="350px">
                {correo}
            </THFila>
            <THFila width="150px">
                {rol}
            </THFila>
            <THFila width="70px">
                {this.getStatus()}
            </THFila>
            <th style={{ width: "110px" }}>
                <ButtonGroup className="float-right">
                    <Button data-tip="EDITAR" variant="primary"
                        onClick={() => { this.setState({ edit: true }) }}>
                        <i className="fa fa-edit"></i>
                    </Button>
                    <FormUsuario
                        user={this.state}
                        title={'EDITAR USUARIO DE ' + nombre}
                        open={edit}
                        close={() => { this.setState({ edit: false }) }}
                        add={this.props.update} 
                        />
                        <FormClave
                        user={this.state}
                        title={'CAMBIAR CONTRASEÑA ' + nombre}
                        open={cambiar_clave}
                        close={() => { this.setState({ cambiar_clave: false }) }}
                        add={this.props.update} />
                    <Button data-tip="CAMBIAR CONTRASEÑA" variant="warning"
                        onClick={() => { this.setState({ cambiar_clave: true }) }}>
                        <i className="fa fa-lock" ></i>
                        </Button>
                    
                    {(estado === 1) ?
                        <Button data-tip="ELIMINAR" variant="danger"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-ban"></i>
                        </Button> : <Button data-tip="ACTIVAR" variant="success"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-check"></i> </Button>
                       }
                     
                </ButtonGroup>
            </th>
        </tr>
    }
    getStatus() {
        if (this.state.estado === 1)
            return <span className="badge badge-success">ACTIVO</span>
        return <span className="badge badge-danger">INACTIVO</span>
    }
    cancelar() {
        let { update, d: { codigo } } = this.props;
        this.con.cancelar(codigo, update);
    }


}