import { Col, Image, Row, Accordion, Card } from "react-bootstrap";
import Template from "../Template";
import { getFacilitador } from "../index";
import { TextToHTML } from "@victorequena22/slate-bootstrap";
import { UseIcon } from "@victorequena22/icon-selector-bootstrap";
import { ProgramaContext } from "../../../Context/Programas";
import { useContext } from "react";
import ReactTooltip from 'react-tooltip';
import { getEspecialidades } from "../Especialidades";

export default function VerFacilitador() {
    const f = getFacilitador();
    const { especialidades: e } = getEspecialidades();
    const { programas: pr } = useContext(ProgramaContext);
    return <Template icon="fa fa-eye" title="INFORMACIÓN DEL FACILITADOR">
        <ReactTooltip />
        <Row>
            <Col md={4}>
                <Col md={12}><Image fluid src={f.foto} /></Col>
                <Col md={12}>
                    <label>REDES SOCIALES</label>
                    <Col xs={12}>
                        {f.redes.map(e => <a key={e.url} href={e.url} className="m-1" data-tip={e.nombre} target="_blank" rel="noreferrer">
                            <UseIcon width='2.5rem' height='2.5rem' icon={e.icon} fill='currentcolor' />
                        </a>)}
                    </Col>
                </Col>
                <Col md={12} className="mt-2">
                    <label>AREAS DE EXPERIENCIA</label>
                    <ul className="mb-2">
                        {e.filter(([id]) => f.especialidades.includes(id)).map(([id, nombre]) =>
                            <li key={id}>{nombre}</li>)}
                    </ul>
                </Col>
            </Col>
            <Col md={8} as={Row}>
                <Col md={7}><b>Nonbre: </b>{f.nombre}</Col>
                <Col md={5}><b>Años de experiencia: </b>{(new Date()).getFullYear() - f.experiencia}</Col>
                <Col md={12}>
                    <TextToHTML text={f.descripcion} />
                </Col>

                <Col md={12}>
                    <Card>
                        <AC title="PROGRAMAS QUE AVALA" primero>
                            <ul>{pr.filter(p => f.programas.includes(p.id))
                                .map(e => <li key={e.codigo}><b>{e.codigo}</b> {e.nombre}</li>)}</ul>
                        </AC>
                        <AC title="ESTUDIOS REALIZADOS">
                            <ul>{f.estudios.map(e => <li key={e.titulo}><b>{e.grado}:</b> {e.titulo}</li>)}</ul>
                        </AC>
                        <AC title="EXPERIENCIA LABORAL">
                            <ul>{f.estudios.map(e => <li key={e.titulo}><b>{e.grado}:</b> {e.titulo}</li>)}</ul>
                        </AC>
                        <AC title="TRABAJOS REALIZADOS">
                            <ul>{f.estudios.map(e => <li key={e.titulo}><b>{e.grado}:</b> {e.titulo}</li>)}</ul>
                        </AC>
                        <AC title="PUBLICACIONES REALIZADAS">
                            <ul>{f.estudios.map(e => <li key={e.titulo}><b>{e.grado}:</b> {e.titulo}</li>)}</ul>
                        </AC>
                        <AC title="PREMIOS RECIBIDOS">
                            <ul>{f.estudios.map(e => <li key={e.titulo}><b>{e.grado}:</b> {e.titulo}</li>)}</ul>
                        </AC>
                    </Card>
                </Col>

                {/* <Col md={12}>
                    <label>PROGRAMAS QUE AVALA</label>
                    <ul>{pr.filter(p => f.programas.includes(p.id))
                        .map(e => <li key={e.codigo}><b>{e.codigo}</b> {e.nombre}</li>)}</ul>
                </Col>
                <Col md={12}>
                    <label>ESTUDIOS REALIZADOS</label>
                    <ul>{f.estudios.map(e => <li key={e.titulo}><b>{e.grado}:</b> {e.titulo}</li>)}</ul>
                </Col> */}
            </Col>
        </Row>
    </Template >
}

function AC({ title, children, primero }: { children: any, title: string, primero?: boolean }) {
    return <Accordion defaultActiveKey='0'>
        <Accordion.Toggle as={Card.Header} eventKey='a' style={{ backgroundColor: 'rgba(113, 113, 113,.3)', borderRadius: primero ? undefined : 0 }}>
            <Row>
                <label>{title}</label>
            </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='a'>
            <Card.Body>
                {children}
            </Card.Body>
        </Accordion.Collapse>
    </Accordion>
}