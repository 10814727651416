import { Panel } from './Panel';
import { Config } from './Config';
import { css } from '@emotion/css';
import Slider from '@mui/material/Slider'
import { Single, StateS } from '../../ProtoPanes/Single';
import { sizeName } from '../../Component';
import { getText } from '../../ProtoPanes/DefaultData';
export interface S {
    input: string;
    inputText: string;
    button: string;
    childColor: string;
    buttonText: string;
    backgroundColor: string;
    textColor: string;
    texto: boolean;
    ori: boolean;
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
}
export type State = StateS & S;
export class Contact extends Single<S> {
    constructor(p: any) {
        super(p);
        this.init({
            texto: true, ori: true,
            inputText: '#444444', input: '#cccccc',
            button: '#777777', buttonText: '#ffffff', childColor: '#f0f0f000',
            backgroundColor: '#f0f0f0', textColor: '#ffffff',
            xs: 100, sm: 100, md: 50, lg: 50, xl: 50, xxl: 40,
        });
    }
    Config = () => <Config save={this.save} setConfig={this.setData} title={this.state.title} setTitle={this.setTitle} config={this.state} />
    Panel = () => <Panel width={this.getArea()[1]} config={this.state} />
    Top = () => {
        const { texto } = this.state;
        if (texto) return <div className="container">
            <Slider className={css`&> .MuiSlider-thumb{border-radius: 0;
            border-top-left-radius: 10px;transform: rotate(45deg);}`}
                onChange={(e: any, d: any) => this.setArea(d)} max={100} min={0}
                step={1} value={this.getArea()[1]} style={{ width: '100%' }} />
        </div>
        return <></>
    }
    setArea = (b: number) => {
        const [a] = this.getArea();
        const max = (a === 'sm' || a === 'md' || a === 'xs') ? 55 : 65;
        const min = (a === 'sm' || a === 'md' || a === 'xs') ? 45 : (a === 'lg') ? 40 : 35;
        if (b > max) this.setState({ [a]: 100 } as any);
        else if (b > min - 1) this.setState({ [a]: b } as any);
        else this.setState({ [a]: min } as any);
    }
    getArea = () => {
        const { size, xs, sm, md, lg, xl, xxl } = this.state;
        return ((size === '570px') ? ['xs', xs] :
            (size === '780px') ? ['sm', sm] :
                (size === '990px') ? ['md', md] :
                    (size === '1080px') ? ['lg', lg] :
                        (size === '1320px') ? ['xl', xl] : ['xxl', xxl]) as [sizeName, number];
    }
    getDataSave = () => {
        const { ori, texto, childColor, textColor, buttonText, backgroundColor, input, inputText, button, xs, sm, md, lg, xl, xxl, title, html } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ xs, sm, md, lg, xl, xxl, ori, texto, buttonText, childColor, textColor, backgroundColor, input, inputText, button });
        return { title, html: getText(html), data, props, type: 'contacto' };
    }
}