import { useEffect, useState } from "react";
import { ButtonGroup, Button, Col, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VerContry, VerEstado } from "../../../../../../../Components/Contry/Ver";
import { ParticipanteFila } from "../../../../../Participantes/Models";
import { conEvento } from "../../../../Models";

export default function UploadCertificado({ user: { id: participante, nombre, correo, telefono, direccion, estado, ciudad, pais }, actualizar }: { user: ParticipanteFila, actualizar: () => void }) {
    const { id } = useParams<{ id: any }>();
    const [img, setImg] = useState('');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    useEffect(() => setError(''), [img]);
    return <>
        <Modal size="xl" show={open} onHide={() => setOpen(false)}>
            <Modal.Body>
                <Row>
                    <Col md={3}>
                        <h5 className='m-1 mb-3 font-weight-bold text-primary'>
                            DATOS DEL PARTICIPANTE
                        </h5>
                        <Col md={12} style={{ height: 445 }}>
                            <p className='m-1 p-0'><b>NOMBRE:</b> {nombre}</p>
                            <p className='m-1 p-0'><b>TELEFONO:</b> {telefono}</p>
                            <p className='m-1 p-0'><b>CORREO:</b> {correo}</p>
                            <p className='m-1 p-0'><b>PAIS:</b> <VerContry key={pais} pais={pais} /></p>
                            <p className='m-1 p-0'><b>ESTADO:</b> <VerEstado key={pais + estado} pais={pais} estado={estado} /></p>
                            <p className='m-1 p-0'><b>CIUDAD:</b> {ciudad}</p>
                            <p className='m-1 p-0'><b>DIRECCION:</b> {direccion}</p>
                        </Col>
                        <Col md={12}>
                            <p className='m-1 p-0'><b>ALTO:</b> 834px</p>
                            <p className='m-1 p-0'><b>ANCHO:</b> 1080px</p>
                            <p className='m-1 p-0'><b>PESO MAXIMO:</b> 1MB</p>
                        </Col>
                        <Button as='label' className='mt-auto' variant="primary" block>
                            <input style={{ display: "none" }} type="file" accept='image/*'
                                onChange={(e) => setImage(e, setImg)} />
                            <i className="fa fa-upload" /> SELECCIONAR ARCHIVO
                        </Button>
                    </Col>
                    <Col md={9}>
                        <img style={{ border: error === '' ? '1px solid black' : '1px solid red' }}
                            src={img} width={810} height={626} alt='img cert' />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={8}><h5 className='m-1 mb-3 font-weight-bold text-danger'>{error}</h5></Col>
                    <Col xs={4}>
                        <ButtonGroup className="float-right">
                            <Button variant='success' onClick={() => {
                                conEvento().aprobar({ img, id, participante }, actualizar, (e) => {
                                    setError(e.img);
                                });
                            }}>
                                <i className="fa fa-save"></i> APROBAR
                            </Button>
                            <Button variant='danger' onClick={() => { setOpen(false); setImg(''); }}>
                                <i className="fa fa-save"></i> CANCELAR
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        <Button style={{ padding: '3px 3px 3px 3px' }} variant="primary" onClick={() => setOpen(true)}>
            <i className="fa fa-upload" />
        </Button>
    </>
}
function ToString(file: any, setData: (d: string) => void) {
    let reader = new FileReader()
    reader.onloadend = () => setData(reader.result as string);
    reader.readAsDataURL(file);
}
const setImage = (e: React.ChangeEvent<HTMLInputElement>, setData: (d: string) => void) => {
    let f = e.currentTarget.files;
    if (f !== null) ToString(f[0], setData);
}