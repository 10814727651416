import { InputText } from "@victorequena22/component-bootstrap";
import { Button, Table } from "react-bootstrap";
import { useState } from "react";
import InputYear from "./InputYear";
const max = (new Date()).getFullYear();
interface Trabajos { inicio: string, final: string, cargo: string, id: number }
function CreateTrabajos({ add, next }: { add: (d: Trabajos) => void, next: number }) {
    const [inicio, setInicio] = useState((max - 1) + '');
    const [final, setFinal] = useState('presente');
    const [cargo, setCargo] = useState('');
    return <tr>
        <td style={{ width: '125px' }}>
            <InputYear value={inicio} change={setInicio} />
        </td>
        <td style={{ width: '125px' }}>
            <InputYear value={final} change={setFinal} />
        </td>
        <td>
            <InputText value={cargo} setData={setCargo} />
        </td>
        <td>
            <Button variant='success' onClick={() => {
                add({ id: next, inicio, final, cargo });
                setInicio('');
                setFinal('');
                setCargo('');
            }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}

export function TrabajosList({ trabajos, setData }: { trabajos: Trabajos[], setData: (d: Trabajos[]) => void }) {
    const mx = Math.max(...trabajos.map(s => s.id), 0);
    const change = (i: number, s: Trabajos) => {
        setData(trabajos.map((o, j) => j !== i ? o : s));
    }
    return <Table striped hover>
        <thead>
            <tr>
                <th style={{ width: '125px' }}>DESDE</th>
                <th style={{ width: '125px' }}>HASTA</th>
                <th>TITULO</th>
                <th style={{ width: '32px' }}></th>
            </tr>
        </thead>
        <tbody>
            <CreateTrabajos next={mx + 1} add={s => setData([...trabajos, s])} />
            {trabajos.map((s, i) => <tr key={i}>
                <td style={{ width: '125px' }}>
                    <InputYear value={s.inicio} change={inicio => change(i, { ...s, inicio })} />
                </td>
                <td style={{ width: '125px' }}>
                    <InputYear value={s.final} change={final => change(i, { ...s, final })} />
                </td>
                <td>
                    <InputText value={s.cargo} setData={cargo => change(i, { ...s, cargo })} />
                </td>
                <td>
                    <Button variant='danger' onClick={() => setData(trabajos.filter((s, j) => j !== i))}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </td>
            </tr>)}
        </tbody>
    </Table>
}