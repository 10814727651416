import { fechaToNumber } from "@victorequena22/utiles"
import { useContext, useState } from "react"
import { Modal, Row } from "react-bootstrap"
import { getEvento } from "../../Evento/GestionarEvento/index"
import { MetodoSelector } from "./Metodos"
import NuevoPago from "./NuevoPago"
import { DolarContext } from "../../../../Template/Dolar"
import { ParticipanteFila } from "../../Participantes/Models"
import { setPagosList } from "../../Evento/GestionarEvento/GestionPagos"

interface Props {
    open: boolean
    close: () => void
    participante: ParticipanteFila
}

export default function RegistrarPago({ open, close, participante: { id: participante, nombre } }: Props) {
    const { tasa } = useContext(DolarContext)
    const { metodos, id, precios } = getEvento();
    const [m, setMetodo] = useState(0)
    const p = precios.find(p => inRangeFechas(p.inicio, p.final));
    const precio = p ? p : precios[precios.length - 1];
    const metodo = metodos.find(b => b.id === m);
    return <Modal show={open} size={metodo ? 'xl' : undefined} centered onHide={close}>
        <Modal.Header closeButton>
            <h2 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                NUEVO PAGO PARA {nombre}
            </h2>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <h3 className="p-2"><b> {metodo ? 'Registrar Pago' : 'Seleccionar método de pago'}</b></h3>
            </Row>
            <Row>
                {metodo ?
                    <NuevoPago dolar={tasa} setMetodo={setMetodo} update={() => { setPagosList(); close(); }}
                        participante={participante} evento={id} metodo={metodo} precio={precio.precio} />
                    : <MetodoSelector metodos={metodos} setData={setMetodo} />}
            </Row>
        </Modal.Body>
    </Modal>
}

const inRangeFechas = (i: string, f: string) => {
    const inicio = parseInt((fechaToNumber(i) / 1000000) + '') + 1;
    const final = parseInt((fechaToNumber(f) / 1000000) + '') + 1;
    const dia = parseInt((fechaToNumber() / 1000000) + '');
    return (dia >= inicio) && (final >= dia)
}