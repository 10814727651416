import { useState, createContext, useEffect, useCallback } from 'react';
import { Panel, PanelUpdate, conPanel } from '../Models';
import { semaforo } from '@victorequena22/utiles';
export interface PanelType {
    panels: Panel[];
    updatePanel: () => void;
}
export const PanelContext = createContext<PanelType>({ panels: [], updatePanel: () => { } })
export default function PanelProvider({ children }: { children: any }) {
    const [panels, setPanels] = useState<Panel[]>([]);
    const [con] = useState(conPanel);
    const actualizar = useCallback(() => PanelUpdate(con, panels, setPanels), [con, panels]);
    useEffect(() => { let interval = setInterval(actualizar, 50); return () => clearInterval(interval); }, [actualizar]);
    useEffect(() => { const s = semaforo(); s.add('Panel'); return () => s.clean('Panel'); }, []);
    return <PanelContext.Provider value={{ panels, updatePanel: actualizar }}>{children}</PanelContext.Provider>
}
export const SeccionConsumer = PanelContext.Consumer;