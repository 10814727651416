import { getPrograma } from "../../index";
import Torta from "../../../../../../Generator/Charts/Torta";
import { useCallback, useMemo, useState, useEffect } from "react";
import { getParticipantes } from "../../../../Context/Participante";
import { getEstado } from "../../../../../../Components/Contry/Ver";
import TortaNull from "../../../../../../Generator/Charts/TortaDefault";
export function Estados() {
    const [values, setValues] = useState<[string, number][]>([])
    const participantes = getParticipantes();
    const lista = getPrograma().participantes;
    const par = useMemo(() => participantes.filter(p => lista.some(e => e === p.id)), [participantes, lista])
    const set = useCallback(async () => {
        const v = values;
        const p: any[] = [];
        const pushEstado = async (e: any) => {
            const estado = await getEstado(e.pais, e.estado);
            if (estado) {
                const e = estado.trimStart().trimEnd().toUpperCase();
                const i = v.findIndex(x => x[0] === e);
                if (i > -1) values[i][1]++;
                else v.push([e, 1]);
            }
        }
        par.forEach(e => p.push(pushEstado(e)))
        Promise.all(p).then(() => setValues(v))
    }, [par, values])
    console.log(!(values.length))
    useEffect(() => { set() }, [set])
    if (!(values.length)) return <TortaNull />
    return <Torta legend='' size='30rem' title={'ESTADOS'} values={values} />
}
