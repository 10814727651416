import React, { useState } from "react"
import NewRol from "./NewRol"
import { Button, Col, InputGroup, Row } from "react-bootstrap"
import EditarRol from "./EditarRol"
import ReactTooltip from "react-tooltip"
import { CardBuscador } from "@victorequena22/tabla-bootstrap"
import { conRol, Rol } from "../Aliado"
import { InputText } from "@victorequena22/component-bootstrap"
export default class ListaRoles extends CardBuscador {
    bucarLabel = ["nombre"];
    con = conRol()
    Row(p: Rol) { return <FilaRol {...p} add={this.set.bind(this)} /> }
    Title() { return <Titulo actualizar={this.set.bind(this)} />; }
    set() { this.con.lista((itens) => { this.setState({ itens }) }); }
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='calc(100% - 170PX)' label='nombre'>NOMBRE</Th>
            <Th width='170PX'>ACCIONES</Th>
        </tr>
    }
    Buscador() {
        return <>
            <ReactTooltip />
            <Row className='d-flex' style={{ paddingBottom: '15px' }}>
                <Col md={9} className='mr-auto'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                BUSCAR
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                        <InputGroup.Append>
                            <Button variant="primary"><i className="fa fa-search"></i> BUSCAR</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Button size='sm' onClick={this.set.bind(this)} data-tip="ACTUALIZAR LISTA" variant="info"
                ><i className="fa fa-refresh"></i> ACTUALIZAR</Button>
            </Row>
        </>
    }
}
function FilaRol(props: Rol & { add: (d: any) => void }) {
    const [open, setOpen] = useState(false);
    return <tr>
        <td>{props.nombre}</td>
        <td style={{ textAlign: 'right' }}>
            <EditarRol add={props.add} key={'editRol' + props.id} open={open} title={"EDITAR ROL: " + props.nombre} rol={props} close={() => { setOpen(false) }} />
            <ReactTooltip />
            <Button variant='primary' data-tip="EDITAR" onClick={() => { setOpen(true) }}>
                <i className="fa fa-edit"></i>
            </Button>
        </td>
    </tr>

}
function Titulo({ actualizar }: { actualizar: () => void }) {
    const [open, setOpen] = useState(false);
    return <>
        <NewRol actualizar={actualizar} open={open} close={() => { setOpen(false) }} />
        <h4 className='mr-auto'>LISTA DE ROLES</h4>
        <Button onClick={() => { setOpen(true) }} variant='primary'>
            <span className="glyphicon glyphicon-plus"></span> NUEVO ROL
        </Button>
    </>

}