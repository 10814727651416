import { useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ModalReport } from "../../../../../../../Components/Modals";
import { ParticipanteFila } from "../../../../../Participantes/Models";

export default function Certificado({ data }: { data: ParticipanteFila; }) {
    const { id: p } = data;
    const [open, setOpen] = useState(false);
    const { id } = useParams<{ id: any }>();
    return <>
        <ReactTooltip />
        <ModalReport open={open} close={() => setOpen(false)} title='CERTIFICADO' url={`/api/Evento/certificado/${id}/${p}`} />
        <Button className="float-right" style={{ padding: '3px 3px 3px 3px' }} variant="primary" onClick={() => setOpen(true)}>
            <i className="fa fa-eye" />
        </Button>
    </>
}