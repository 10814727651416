import { getPrograma } from "../../index";
import Torta from "../../../../../../Generator/Charts/Torta";
import { useMemo } from "react";
import TortaNull from "../../../../../../Generator/Charts/TortaDefault";
export function Encuesta() {
    const programa = getPrograma();
    const values = useMemo(() => {
        const values: [string, number][] = [];
        programa.encuesta.forEach(e => {
            const i = values.findIndex(x => x[0] === e.respuesta);
            if (i > -1) values[i][1]++;
            else values.push([e.respuesta, 1]);
        })
        return values;
    }, [programa])
    const colores = useMemo(() => {
        const colores = values.map(x => {
            switch (x[0]) {
                case 'Facebook': return { color: '#0778e9' }
                case 'Instagram': return { color: '#f14c5a' }
                case 'Correo': return { color: '#CCCCCC' }
                case 'Whatsapp': return { color: '#1bd741' }
                case 'Radio': return { color: '#737fbc' }
                case 'Referido por un amigo': return { color: '#e84d8b' }
                default: return { color: random_rgba() }
            }
        })
        return colores;
    }, [values])
    if (!(values.length)) return <TortaNull />
    return <Torta legend='' size='30rem' title={'ENCUESTA'} values={values} slices={colores} />
}

function random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgb(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')';
}