import { Row, Col, Container, Accordion } from 'react-bootstrap';
import { State, iten } from './index'
import { Itens } from './Itens'
const breack3 = ['1080px', '1320px', '1440px'];
const breack2 = [...breack3, '990px'];
export function Panel({ config, setConfig }: { setConfig: (p: Partial<State>) => void, config: State }) {
    const { color, itens, cols, size } = config;
    const Rows: JSX.Element[] = [];
    const Column = ({ itens }: { itens: iten[] }) => {
        return <Col xs={cols === 3 ? (breack3.some(s => size === s) ? 4 : 12) : cols === 2 ? (breack2.some(s => size === s) ? 6 : 12) : 12}>
            <Accordion defaultActiveKey={`${config.seleted}`}>
                {itens.map((iten, i) => <Itens setConfig={setConfig} key={i} config={config} iten={iten} />)}
            </Accordion>
        </Col>
    }
    let init = 0;
    for (let i = 0; i < cols; i++) {
        // eslint-disable-next-line no-loop-func
        Rows.push(<Column itens={itens.filter((a, c) => c >= init && (c < init + (itens.length / cols)))} />)
        init += (itens.length / cols);
    }
    return <Row style={{ background: color, margin: '0 0 0 0', padding: '10px 0 10px 0' }}>
        <Container><Row>{Rows}</Row></Container>
    </Row>
}