import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import { VerContry, VerEstado } from "../../../../Components/Contry/Ver";
import { Suscriptores } from "../../Programas/Models/Models";
import { StatusChange } from "../StatusChange";
import { useCallback } from "react";
import { getStatus } from "./Enviar";
import { ButtonGroup } from "react-bootstrap";
import { Chat } from "../Chat";
interface Props {
    s: Suscriptores;
    u: () => void;
}
export default function Fila({ s: { id, nombre, correo, telefono, pais, estado, ciudad, estatus: [bg, st] }, u }: Props) {
    const estatus = useCallback((d: any) => getStatus(id, d, u), [id, u]);
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='auto'>{telefono}</THFila>
        <THFila width='auto'>{correo}</THFila>
        <THFila width='auto'><VerContry key={pais} pais={pais} /></THFila>
        <THFila width='auto'><VerEstado key={pais + estado} pais={pais} estado={estado} /></THFila>
        <THFila width='auto'>{ciudad}</THFila>
        <THFila width='auto'><span className={`badge bg-${bg}`}>{st}</span></THFila>
        <THInput width='auto'>
            <ButtonGroup>
                <StatusChange bg={bg} status={st} update={estatus} />
                <Chat id={id} url='suscrito' />
            </ButtonGroup>
        </THInput>
    </tr>
}