import { InputText } from "@victorequena22/component-bootstrap";
import { Button, Table } from "react-bootstrap";
import { useState } from "react";
import InputYear from "./InputYear";
const max = (new Date()).getFullYear();
interface Premios { ano: string, premio: string, organizacion: string, id: number }
function CreatePremios({ add, next }: { add: (d: Premios) => void, next: number }) {
    const [ano, setAno] = useState((max) + '');
    const [premio, setPremio] = useState('');
    const [organizacion, setOrganizacion] = useState('');
    return <tr>
        <td style={{ width: '125px' }}>
            <InputYear hidePresente value={ano} change={setAno} />
        </td>
        <td>
            <InputText value={premio} setData={setPremio} />
        </td>
        <td>
            <InputText value={organizacion} setData={setOrganizacion} />
        </td>
        <td>
            <Button variant='success' onClick={() => {
                add({ id: next, ano, premio, organizacion });
                setAno('');
                setPremio('');
                setOrganizacion('');
            }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}

export function PremiosList({ premios, setData }: { premios: Premios[], setData: (d: Premios[]) => void }) {
    const mx = Math.max(...premios.map(s => s.id), 0);
    const change = (i: number, s: Premios) => {
        setData(premios.map((o, j) => j !== i ? o : s));
    }
    return <Table striped hover>
        <thead>
            <tr>
                <th style={{ width: '125px' }}>AÑO</th>
                <th>PREMIO</th>
                <th>ORGANIZACION</th>
                <th style={{ width: '32px' }}></th>
            </tr>
        </thead>
        <tbody>
            <CreatePremios next={mx + 1} add={s => setData([...premios, s])} />
            {premios.map((s, i) => <tr key={i}>
                <td style={{ width: '125px' }}>
                    <InputYear hidePresente value={s.ano} change={ano => change(i, { ...s, ano })} />
                </td>
                <td>
                    <InputText value={s.premio} setData={premio => change(i, { ...s, premio })} />
                </td>
                <td>
                    <InputText value={s.organizacion} setData={organizacion => change(i, { ...s, organizacion })} />
                </td>
                <td>
                    <Button variant='danger' onClick={() => setData(premios.filter((s, j) => j !== i))}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </td>
            </tr>)}
        </tbody>
    </Table>
}