import React from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { InputArea } from "@victorequena22/component-bootstrap";
import { ImageSelector } from '../../../Media/Selector';
import { Categoria, conCategoria } from "./Models";
interface Props {
    d: Categoria
    update?: () => void
}
export class FilaCategoria extends React.Component<Props, Categoria> {
    con = conCategoria()
    constructor(p: Props) {
        super(p)
        this.state = p.d
    }
    render() {
        let { nombre, short, codigo } = this.state
        return <>
            <tr>
                <td rowSpan={3} style={{ width: "160px" }}>
                    <ImageSelector img={short} setImg={short => this.setState({ short })} />
                </td>
                <td rowSpan={3} style={{ width: "calc(100% - 300px)" }}>
                    <ReactTooltip />
                    <InputArea style={{ height: "130px" }} maxLength={150} next={this.edit} placeholder='NOMBRE' upper setData={this.setState.bind(this)} value={nombre} update="nombre" />
                </td>
                <td style={{ width: "300px", textAlign: 'center' }}>
                    URL: {codigo}
                </td>
            </tr>
            <tr><td style={{ width: "150px", textAlign: 'center' }}>{this.getStatus()}</td></tr>
            <tr>
                <td style={{ width: "150px", textAlign: 'center' }}>
                    <ButtonGroup className="float-right btn-block">
                        <Button data-tip="GUARDAR CAMBIOS" variant="success" onClick={this.edit}>
                            <i className="fa fa-save"></i>
                        </Button>
                        {(this.props.d.estatus === 1) ?
                            <Button data-tip="ELIMINAR" variant="danger" onClick={this.cancelar} >
                                <i className="fa fa-ban"></i>
                            </Button> : <Button data-tip="ACTIVAR" variant="success" onClick={this.cancelar} >
                                <i className="fa fa-check"></i>
                            </Button>}
                    </ButtonGroup>
                </td>
            </tr>
        </>
    }
    getStatus = () => {
        if (this.props.d.estatus === 1)
            return <span className="badge badge-success">ACTIVO</span>
        return <span className="badge badge-danger">INACTIVO</span>
    }
    cancelar = () => {
        let { update, d: { id } } = this.props;
        this.con.cancelar(id, update);
    }
    edit = () => {
        let { nombre } = this.state;
        let { update, d: { id } } = this.props;
        if (nombre === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } else {
            this.con.actualizar(id, this.state, () => {
                if (update) update();
                toast('ACTUALIZADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS })
            });
        }
    }
}