
import { Button, Col, Modal } from 'react-bootstrap';
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { conMetodoPago, metodoNull, MetodoPago } from '../Models';
interface Props {
    add?: (m: MetodoPago) => void;
}
export default class NuevoMetodoPago extends Prototipo<Props> {
    constructor(p: any) {
        super(p)
        this.state = metodoNull;
    }
    render = () => {
        let { setData, error, state, create, props: { open, close } } = this;
        return (<Modal onHide={close} show={open} size='xl'>
            <Modal.Header className='bg-info' closeButton>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVO MÉTODO DE PAGO </h4>
            </Modal.Header>
            <Form data={state} setData={setData as any} error={error}>
                <Col xs={12} className='mt-2'>
                    <Button variant='success' className='float-right' onClick={create}>
                        <i className="fa fa-save"></i> Guardar
                    </Button>
                </Col>
            </Form>
        </Modal >)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                const { add, close } = this.props;
                conMetodoPago().nuevo(this.getDataSave(), (m) => {
                    this.procesar = true;
                    toast('METODO DE PAGO CREADO', { type: toast.TYPE.SUCCESS });
                    if (add) add(m);
                    close();
                }, this.setError)
            }
        } else this.forceUpdate();
    }

}