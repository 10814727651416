/* eslint-disable react-hooks/rules-of-hooks */
import { semaforo } from '@victorequena22/utiles';
import { useState, createContext, useEffect, useCallback, useContext } from 'react'
import { eventoUpdate, conEvento } from '../Evento/Models/Coneccion';
import { EventoLista } from '../Evento/Models/Model';

export interface EventosType {
    eventos: EventoLista[]
    actualizar: () => void
}
export const EventoContext = createContext<EventosType>({ eventos: [], actualizar: () => { } });
export default function EventosProvider({ children }: { children: any }) {
    const [eventos, setEventos] = useState<EventoLista[]>([]);
    const [con] = useState(conEvento());
    const actualizar = useCallback(() => eventoUpdate(con, eventos, setEventos), [con, eventos]);
    useEffect(() => {
        const s = semaforo();
        s.add('Evento');
        return () => s.clean('Evento');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        let interval = setInterval(actualizar, 50)
        return () => clearInterval(interval)
    }, [actualizar]);
    return <EventoContext.Provider value={{ eventos, actualizar }}>
        {children}
    </EventoContext.Provider>
}
export const SeccionConsumer = EventoContext.Consumer;

export const eventoToPrograma = (id: number) => {
    return useContext(EventoContext).eventos.filter(e => e.programa === id)
}
export const getEventos = () => {
    return useContext(EventoContext).eventos;
}
export const getEventosActivos = () => {
    return useContext(EventoContext).eventos.filter((e: EventoLista) => {
        const date = getMonth();
        const ini = getMonth(e.inicio);
        if (date > ini) return false;
        return true;
    });
}
function getMonth(ini?: string) {
    const date = ini ? new Date(ini) : new Date();
    const year = date.getFullYear() * 100;
    const month = date.getMonth();
    return year + month;
}