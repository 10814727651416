/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useCallback } from 'react';
import { MetodoPago,conMetodoPago } from '../Pagos/MetodosPago/Models';

export interface MetodosPagosType {
    MetodosPagos: MetodoPago[]
    actualizarMetodosPago: () => void
}
export const MetodosPagosContext = createContext<MetodosPagosType>({
    MetodosPagos: [], actualizarMetodosPago: () => { }
});
export default function MetodosPagosProvider({ children }: { children: any }) {
    const [MetodosPagos, setMetodosPagos] = useState<MetodoPago[]>([]);
    const actualizarMetodosPago = useCallback(() => { conMetodoPago().lista(setMetodosPagos); }, [MetodosPagos]);
    useEffect(() => { actualizarMetodosPago(); }, []);
    return <MetodosPagosContext.Provider value={{ MetodosPagos, actualizarMetodosPago }}>
        {children}
    </MetodosPagosContext.Provider>
}
export const SeccionConsumer = MetodosPagosContext.Consumer;