import { Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { CardConfig, CardMain, RowConfig, TitleCard, ControlNumber, InputText } from "@victorequena22/component-bootstrap";
import { AlingMenu, ListMenu, TabsMenu, TypeMenu } from "@victorequena22/slate-bootstrap";
import { BackButton, ColorButton, MarkMenu } from "@victorequena22/slate-bootstrap";
import { Guardar } from '../../../../../Panel/Component';
import { FontStyle, Inserts } from './Stale/MarkProps';
import { useContext } from 'react';
import { ColorContext } from '../../../../../../Template/Colores';

interface Props {
    save: () => void
    config: any
    setConfig: (d: any) => void
}
export default function Config({ save, setConfig, config: { nombre, top, left, right, bottom, errors } }: Props) {
    const { colores } = useContext(ColorContext)
    const width = 'calc(50% - .25rem)';
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='330px'>
            <RowConfig className='mb-2'>
                <InputGroup style={{ width: '100%' }} className='input-group-sm'>
                    <InputGroup.Prepend><InputGroup.Text>
                        NOMBRE:
                    </InputGroup.Text></InputGroup.Prepend>
                    <InputText data-tip={errors.nombre} data-type='error' isInvalid={errors.nombre} maxLength={100}
                        className='form-control-sm' setData={nombre => setConfig({ nombre })} value={nombre} />
                </InputGroup>
            </RowConfig>
            <RowConfig className='mt-2'>
                <Button data-tip="IMAGEN QUE SERA UTILIZADA COMO FONDO DEL DOCUMENTO" style={{ margin: '0 0 0 0', height: '48px', width: '125px' }}
                    as='label' className='mt-auto mr-1 d-flex' variant={errors.img ? 'danger' : 'primary'}>
                    <input style={{ display: "none" }} type="file" accept='image/*' onChange={(e) => setImage(e, data => setConfig({ data }))} />
                    <i className="fa fa-upload mr-1" style={{ fontSize: '30px' }} /><p style={{ fontSize: '10px' }}> SELECCIONAR  <br /> IMAGEN</p>
                </Button>
                <small style={{ width: 180 }} className={errors.img ? 'text-danger' : undefined}>
                    {errors.img ? errors.img : <>DIMENCIONES: 1080X834 <br /> TAMAÑO MAXIMO: 1MB</>}
                </small>
            </RowConfig>
            <TitleCard>OBLIGATORIO</TitleCard>
        </CardConfig>
        <CardConfig width='320px'>
            <RowConfig className=''>
                <ControlNumber styleGroup={{ width }} className='form-control-sm' classGroup='input-group-sm mr-2' decimal={0} min={0} step="1" before="SUPERIOR" value={top} setData={setConfig} update="top" />
                <ControlNumber styleGroup={{ width }} className='form-control-sm' classGroup='input-group-sm' decimal={0} min={0} step="1" before="INFERIOR" value={bottom} setData={setConfig} update="bottom" />
            </RowConfig>
            <RowConfig className='mt-1'>
                <ControlNumber styleGroup={{ width }} className='form-control-sm' classGroup='input-group-sm mr-2' decimal={0} min={0} step="1" before="IZQUIERDO" value={left} setData={setConfig} update="left" />
                <ControlNumber styleGroup={{ width }} className='form-control-sm' classGroup='input-group-sm' decimal={0} min={0} step="1" before="DERECHO" value={right} setData={setConfig} update="right" />
            </RowConfig>
            <Button style={{ margin: '0 0 0 0', padding: '.1rem .1rem .1rem .1rem', height: '18px', fontSize: '10px' }} block
                className='mt-1 mr-1 d-flex' variant="primary" onClick={() => setConfig({ top: 0, left: 0, right: 0, bottom: 0 })}>
                <p className='m-auto'>REINICIAR MARGENES</p>
            </Button>
            <TitleCard>MARGENES</TitleCard>
        </CardConfig>
        <CardConfig width='304px'>
            <RowConfig>
                <MarkMenu />
                <ButtonGroup className='ml-1'>
                    <ColorButton colores={colores} left={0} />
                    <BackButton colores={colores} left={0} />
                </ButtonGroup>
                <AlingMenu left={1} />
            </RowConfig>
            <RowConfig top={2}>
                <TabsMenu left={0} />
                <ListMenu left={1} />
                <TypeMenu left={1} />
                <Inserts left={1} />
            </RowConfig>
            <RowConfig top={2}>
                <FontStyle left={0} width='100%' />
            </RowConfig>
            <TitleCard>FUENTE Y TEXTO</TitleCard>
        </CardConfig>
    </CardMain>
}
function ToString(file: any, setData: (d: string) => void) {
    let reader = new FileReader()
    reader.onloadend = () => setData(reader.result as string);
    reader.readAsDataURL(file);
}
const setImage = (e: React.ChangeEvent<HTMLInputElement>, setData: (d: string) => void) => {
    let f = e.currentTarget.files;
    if (f !== null) ToString(f[0], setData);
}