import { useCallback, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ModalAlert } from "../../../../../../../Components/Modals";
import { ParticipanteFila } from "../../../../../Participantes/Models";
import { conEvento } from "../../../../Models";
import UploadCertificado from "./UploadCertificado";
import { update, getEvento } from "../../../index";

interface Props {
    data: ParticipanteFila;
}
export default function Calificar({ data }: Props) {
    const e = getEvento();
    const { id: participante, nombre, correo, telefono } = data;
    const [reprobar, setReprobar] = useState(false);
    const [aprobar, setAprobar] = useState(false);
    const { id } = useParams<{ id: any }>();
    const info = useCallback((par: string) => {
        return `<div class='col'>
            <p><b>NOMBRE: </b>${nombre} </p>
            <p><b>CORREO: </b>${correo} </p>
            <p><b>TELEFONO: </b>${telefono} </p> 
            <p>ESTA SEGURO QUE ${par}  A ESTE PARTICIPANTE</p></div>`
    }, [nombre, correo, telefono])
    return <>
        <ReactTooltip />
        <ModalAlert open={reprobar} close={() => setReprobar(false)} title='REPROBAR'
            msn={info('REPROBARA')} accion={() => {
                conEvento().reprobar({ id, participante }, update, update)
            }} />
        <ModalAlert open={aprobar} close={() => setAprobar(false)} title='APROBAR'
            msn={info('APROBAR')} accion={() => {
                conEvento().aprobar({ id, participante }, update, update)
            }} />
        <ButtonGroup className="float-right">
            <Button variant="danger" onClick={() => setReprobar(true)}>
                <i className="fa fa-ban" />
            </Button>
            {e.certificado === -1 ? <UploadCertificado user={data} actualizar={update} /> :
                <Button variant="primary" onClick={() => setAprobar(true)}>
                    <i className="fa fa-check" />
                </Button>}
        </ButtonGroup>
    </>
}