import { Button, Modal, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import { ParticipanteFila, ParticipanteNull, conParticipante } from './Models';
import { toast } from 'react-toastify';
import React from 'react';
import { ControlArea, ControlText } from "@victorequena22/component-bootstrap";
import { ContrySelector, StateSelector } from '../../../Components/Contry';
interface Props {
    open: boolean
    participante: ParticipanteFila | null
    title: string
    close: () => void
    add?: (c: ParticipanteFila) => void
}
interface State extends ParticipanteFila {
    activeError: boolean;
}
export default class FormParticipante extends React.Component<Props, State> {
    error: any = [];
    con = conParticipante();
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this);
        let state = p.participante;
        if (state === null) state = ParticipanteNull;
        this.state = { ...state, activeError: false };
    }
    render() {
        let { setData, state: s } = this
        return <Modal animation show={this.props.open} size='xl' centered onHide={this.props.close}>
            <Modal.Header className='bg-primary' closeButton>
                <h4 className='mr-auto'>{this.props.title}</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col as={FormGroup} hasValidation={this.error['nombre'] ? true : false} xs={12}>
                        <ControlText error={this.error} before="NOMBRE COMPLETO" maxLength={200}
                            value={s.nombre} setData={setData} update="nombre" />
                    </Col>
                    <Col as={FormGroup} hasValidation={this.error['telefono'] ? true : false} style={{ width: 'calc(100% - 500px)' }}>
                        <ControlText error={this.error} before="TELEFONO" maxLength={150} mask="9999-99999999"
                            value={s.telefono} setData={setData} update="telefono" />
                    </Col>
                    <Col as={FormGroup} hasValidation={this.error['correo'] ? true : false} style={{ width: 'calc(100% - 500px)' }}>
                        <ControlText error={this.error} lower before="CORREO" maxLength={150}
                            value={s.correo} setData={setData} update="correo" />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <FormLabel>DIRECCIÓN</FormLabel>
                        <Row>
                            <Col xs={3}><ContrySelector defaultValue={s.pais} change={pais => setData({ pais })} /></Col>
                            <Col xs={3}><StateSelector pais={s.pais} defaultValue={s.estado} change={estado => setData({ estado })} /></Col>
                            <Col xs={6}><ControlText maxLength={100} before='CIUDAD' value={s.ciudad} setData={setData} update='ciudad' /></Col>
                        </Row>
                        <ControlArea error={this.error} value={s.direccion}
                            className='mt-1' placeholder='DIRECCION' upper
                            setData={setData} update="direccion" maxLength={300} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.get.bind(this)} variant="primary">
                    <span className="fa fa-floppy-disk" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    }
    isInvalid(s: string) {
        if (s === "") return true
        return false
    }
    get() {
        let error: Partial<State> = {};
        let { state: s } = this
        let procesar = true;
        if (s.nombre === "") error.nombre = 'DEBE COLOCAR UN NOMBRE COMPLETO';
        if (s.telefono === "") error.telefono = 'DEBE COLOCAR UN NUMERO DE TELEFONO';
        if (s.correo === "") error.correo = 'DEBE COLOCAR UN CORREO';
        if (Object.keys(error).length > 0) { this.setError([error]); procesar = false; }
        if (procesar) {
            if (this.props.participante === null) this.con.nuevo(s, this.action, this.setError)
            else this.con.actualizar(this.props.participante.id, s, this.action, this.setError)
        }
    }
    setError = (err: any) => {
        err.forEach((err: any) => {
            if (err.sql) {
                err.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            } else {
                Object.keys(err).forEach(t => this.error[t] = err[t])
            }
        })
        this.forceUpdate();
    }
    action = (data: any) => {
        if (this.props.add) this.props.add(data);
        if (this.props.participante === null) this.setData(ParticipanteNull);
        this.props.close();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
}
