import { useCallback, useEffect, useContext } from "react";
import Slider from '@mui/material/Slider'
import { CardConfig, RowConfig, TitleCard } from '../../../Panel/Component';
import { VideoContext } from '../Context/Video';
import { timeFormat } from "@victorequena22/utiles";
export function Grabber() {
    const { start, end, time } = useContext(VideoContext);
    let video = document.getElementById('video') as HTMLVideoElement | null;
    const init = useCallback(() => {
        if (video !== null && !isNaN(video.duration)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            video = document.getElementById('video') as HTMLVideoElement;
        } else setTimeout(init, 100);
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    return <CardConfig marginNull width="140">
        <RowConfig><Time label='INICIO' time={start} /></RowConfig>
        <RowConfig><Time label='FINAL' time={end} /></RowConfig>
        <RowConfig><Time label='CORTE' time={end - start} /></RowConfig>
        <RowConfig><Time label='SEGUNDO' time={time} /></RowConfig>
        <TitleCard>CORTE</TitleCard>
    </CardConfig>
}

function Time({ label, time }: { label: string, time: number }) {
    return <>
        <b className='mr-auto'><small>{label}:</small></b>
        <small>{timeFormat(time)}</small>
    </>
}

export function Playback() {
    let video = document.getElementById('video') as HTMLVideoElement | null;
    const { start, end, time, setStart, setEnd } = useContext(VideoContext);
    return <div className='m-1 p-1 w-100'>
        <div className='m-1 p-1 w-100 d-flex justify-content-end' style={{ height: '20px', marginBottom: -10 }}>
            <b>{timeFormat(video === null ? 1 : video.duration)}</b>
        </div>
        <div className='m-1 p-1' style={{ height: '20px', marginBottom: -10 }}>
            <Slider max={video?.duration} min={0} step={.0000001} value={time}
                onChange={(e: any, d: any) => {
                    if (video !== null) video.currentTime = `${d}` as any;
                }} />
        </div>
        <div className='m-1 p-1' style={{ height: '20px', marginBottom: -10 }}>
            <Slider max={video?.duration} min={0} step={.0000001} value={[start, end]}
                onChange={(e: any, d: any) => { setStart(d[0]); setEnd(d[1]) }} />
        </div>
    </div >

}