import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import ContactaMeList from "./me";
import ContactaSubList from "./Subs";

export default function MasterContacto() {
    return (<Row style={{ minHeight: "calc(100vh - 145px)" }}>
        <Col md={3} style={{ minHeight: "calc(100vh - 145px)" }}> 
            <Card>
                <Card.Header className='bg-info'>
                    <h6>CONTACTOS</h6>
                </Card.Header>
                <ButtonGroup vertical style={{ width: '100%' }}>
                    <Link className='btn btn-default' to='/contacto/subs'>
                        SUBCRIPCIONES
                    </Link>
                    <Link className='btn btn-default' to='/contacto/me'>
                        MENSAJES RECIBIDOS
                    </Link>
                </ButtonGroup>
            </Card>
        </Col>
        <Col md={9} style={{ minHeight: "calc(100vh - 145px)" }}>
            <Route exact path="/contacto/subs"><ContactaSubList /></Route>
            <Route exact path="/contacto/me"><ContactaMeList /></Route>
        </Col>
    </Row>)
}