import { Panel } from './Panel';
import { Config } from './Config';
import { Single, StateS } from '../../ProtoPanes/Single';
import { ConConfig } from '../../../../Config/Config';
import SlateProvider from '../../Component/SlateCustom';
import { SizeViewConteiner } from '../../Component';
import ReactTooltip from 'react-tooltip';
import { getText, setText } from '../../ProtoPanes/DefaultData';
export interface S {
    img: string;
    orientacion: boolean;
    height: number;
    backgroundColor: string;
    color: string;
    backgroundColorHover: string;
    colorHover: string;
}
export type State = StateS & S;
export class Menu extends Single<S> {
    randon = Math.random();
    constructor(p: any) {
        super(p);
        this.init({
            img: '', orientacion: true, height: 40,
            backgroundColor: '#f0f0f0CC', color: '#000000',
            backgroundColorHover: '#a0a0a0CC', colorHover: '#000000',
        });
    }
    componentDidMount(): void {
        ConConfig('Menu').pull(d => {
            this.randon = Math.random();
            if (d !== null && JSON.stringify(d) !== '[]') {
                const html = setText(d.html);
                this.setData({ ...d, html })
            }
            else ConConfig('empresa').pull(({ logo }) => this.setData({ img: '/public/imagenes/' + logo }));
        });
    }
    render() {
        const { setTexto, Top, guardar, Left, state: { html, id } } = this;
        return <div style={{ height: '100%', maxHeight: 'calc(100vh - 180px)' }}>
            <SlateProvider key={`panel${id}${this.randon}`} value={html} setValue={setTexto}>
                <ReactTooltip />
                <Config save={guardar} setConfig={this.setData} config={this.state} />
                <SizeViewConteiner top={Top()} left={Left()} size={this.state.size}>
                    <Panel config={this.state} setData={this.setData} />
                </SizeViewConteiner>
            </SlateProvider>
        </div>
    }
    guardar = () => ConConfig('Menu')
        .push({ ...this.state, html: getText(this.state.html) },
            this.setState.bind(this));

}