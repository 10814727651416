import Paralax1 from '../../../../Panel/Landing/Paralax1';
import Paralax2 from '../../../../Panel/Landing/Paralax2';
import Video from '../../../../Panel/Landing/Video';
import Galeria from '../../../../Panel/Landing/Galeria';
import Carrusel from '../../../../Panel/Landing/Carrusel';
import Servicio from '../../../../Panel/Landing/Servicio';
import Servicio2 from '../../../../Panel/Landing/Servicio2';
import Preguntas from '../../../../Panel/Landing/Preguntas';
import Title from '../../../../Panel/Landing/Title';
import Imagen from '../../../../Panel/Landing/Imagen';
import Cargos from '../../../../Panel/Landing/Cargos';
import ImagenSola from '../../../../Panel/Landing/ImagenSola';
import { useParams } from 'react-router-dom';
import { getPrograma } from '../index';
import { Template2 } from '../Template';
import Eventos from '../../../../PanelProxi/Landing/Eventos';
import Programas from '../../../../PanelProxi/Landing/Programas';
import { Suscribir } from './Panels';
import Testimonio from '../../../../Panel/Landing/Testimonio';



export default function LandingProgramaCrear() {
    const { id } = getPrograma();
    return <Template2
        back={`/programa/landing/${id}`}
        icon="fas fa-browser"
        title="CREAR PANEL PARA EL PROGRRAMA">
        <Crear />
    </Template2>
}

function Crear() {
    let { type } = useParams<{ type: string }>();
    const { id } = getPrograma();
    const p = { model_id: id, model: 'programa' };
    const edit = `/programa/landing/${id}/edit/`;
    console.log(type)
    switch (type) {
        case 'cargos': return <Cargos key={'panel' + type} props={p} edit={edit} />
        case 'carrusel': return <Carrusel key={'panel' + type} props={p} edit={edit} />
        case 'galeria': return <Galeria key={'panel' + type} props={p} edit={edit} />
        case 'imagen': return <Imagen key={'panel' + type} props={p} edit={edit} />
        case 'imagenSola': return <ImagenSola key={'panel' + type} props={p} edit={edit} />
        case 'paralax1': return <Paralax1 key={'panel' + type} props={p} edit={edit} />
        case 'paralax2': return <Paralax2 key={'panel' + type} props={p} edit={edit} />
        case 'preguntas': return <Preguntas key={'panel' + type} props={p} edit={edit} />
        case 'servicio': return <Servicio key={'panel' + type} props={p} edit={edit} />
        case 'servicio2': return <Servicio2 key={'panel' + type} props={p} edit={edit} />
        case 'titulo': return <Title key={'panel' + type} props={p} edit={edit} />
        case 'video': return <Video key={'panel' + type} props={p} edit={edit} />
        case 'eventos': return <Eventos key={'panel' + type} props={p} edit={edit} />
        case 'programas': return <Programas key={'panel' + type} props={p} edit={edit} />
        case 'suscribirse': return <Suscribir key={'panel' + type} props={p} edit={edit} />
        case 'testimonio': return <Testimonio key={'panel' + type} props={p} edit={edit} />
        default: return <></>
    }
}