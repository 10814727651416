import { formatoFecha, numberFormat } from "@victorequena22/utiles";
import { useContext, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { EventoContext } from "../Context/Eventos";
import ReactTooltip from 'react-tooltip';
import ModalEvento from "../Evento/Fila/Ver";
import { eventoNull } from "../Evento/Models";
import { PagoLista } from "./Models";
import ModalPago from "./Ver/ModalVer";
import VerParticipante from "../Participantes/Ver";
import { ParticipanteContext } from "../Context/Participante";
import { ParticipanteNull } from "../Participantes/Models";

export default function Fila({ pago, update }: { pago: PagoLista, update: () => void }) {
    const { eventos } = useContext(EventoContext);
    const { participantes } = useContext(ParticipanteContext);
    const { referencia, banco, monto, nota, fecha, estatus, evento, participante, nombre } = pago;
    const e = eventos.find(e => e.id === evento);
    const p = participantes.find(p => p.id === participante);
    const [open, setOpen] = useState(false);
    const [even, setEven] = useState(false);
    const [part, setPart] = useState(false);
    const badge = ['danger', 'warning', 'success'];
    const estados = ['Rechazado', 'Pendiente', 'Aprobado'];
    return <tr>
        <td>{formatoFecha(fecha)}</td>
        <td>{banco}</td>
        <td>{referencia}</td>
        <td>{nombre}</td>
        <td>{numberFormat(monto)}</td>
        <td><span className={`badge badge-${badge[estatus]}`}>{estados[estatus]}</span></td>
        <td>{nota}</td>
        <td>
            <ReactTooltip />
            <ModalEvento evento={e ? e : eventoNull} open={even} close={() => setEven(false)} />
            <ModalPago update={update} pago={pago} open={open} close={() => setOpen(false)} />
            <VerParticipante participante={p ? p : ParticipanteNull} open={part} close={() => setPart(false)} />
            <ButtonGroup>
                <Button data-tip='VER INFORMACION DEL PAGO' onClick={() => setOpen(true)} variant='outline-dark'>
                    <i className="fas fa-eye" />
                </Button>
                <Button data-tip='VER INFORMACION DEL EVENTO' onClick={() => setEven(true)} variant='outline-primary'>
                    <i className="fas fa-file-certificate" />
                </Button>
                <Button data-tip='VER INFORMACION DEL PARTICIPANTE' onClick={() => setPart(true)} variant='outline-warning'>
                    <i className="fas fa-users-class" />
                </Button>
            </ButtonGroup>
        </td>
    </tr>
}