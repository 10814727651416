import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Cargando } from "@victorequena22/component-bootstrap";
import { conCertificados } from "../Model";
import FromCertificado from './From';
export default function CopiarCertificado() {
    let { id } = useParams<{ id: any }>();
    const [c, set] = useState<any>({});
    useEffect(() => { conCertificados().detalle(id, set); }, [id])
    if (c.id !== undefined) return <FromCertificado copi={c} />
    return <Cargando />
}