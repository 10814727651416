import { useCallback, useState } from "react";
import { ModalReport } from "../../../../../Components/Modals";
import { Button, Col } from "react-bootstrap";
import { getEvento } from "..";
import { getParticipantes } from "../../../Context/Participante";
import ParticipantesExcel from "../../../Participantes/Excel";
import { portapapeles } from "@victorequena22/utiles";
import { toast } from "react-toastify";

export function Asistencia() {
    const { id } = getEvento();
    const [open, setOpen] = useState(false);
    return <>
        <ModalReport open={open} close={() => setOpen(false)} title='CERTIFICADO' url={`/api/Participante/PDF/ListaEvento/${id}`} />
        <Button className="ml-1 p-2" variant="info" onClick={() => setOpen(true)}><i className='fas fa-users-class'></i> VER ASISTENCIA</Button>
    </>
}
export function Aprobados() {
    const { id } = getEvento();
    const [open, setOpen] = useState(false);
    return <>
        <ModalReport open={open} close={() => setOpen(false)} title='CERTIFICADO' url={`/api/Participante/PDF/Aprobados/${id}`} />
        <Button className="ml-1 mr-1 p-2" variant="success" onClick={() => setOpen(true)}><i className='fas fa-file-certificate'></i> VER APROBADOS</Button>
    </>
}
export function Excel() {
    const { participantes: p } = getEvento();
    const l = getParticipantes();
    return <ParticipantesExcel className='p-2' Participantes={l.filter((i) => p.some(e => e.id === i.id))} />
}
export function VerDemo() {
    const [open, setOpen] = useState(false);
    const { id, certificado } = getEvento();
    return <>
        <ModalReport open={open} close={() => setOpen(false)} title='CERTIFICADO' url={`/api/Evento/certificadoDemo/${certificado}/${id}`} />
        <Button className="ml-1 p-2" variant="primary" onClick={() => setOpen(true)}>
            <i className='fas fa-eye'></i> VER CERTIFICADO
        </Button>
    </>
}


export function Copi() {
    const { id } = getEvento();
    const link = `https://talentoeinnovacion.net/evento/Inscribir/${id}`;
    const copi = useCallback(() => {
        portapapeles(link)
        toast('LINK COPIADO!', { type: toast.TYPE.SUCCESS });
    }, [link])
    return <Col>
        <b>LINK:</b> {link}
        <Button className="ml-1 p-2" variant='primary' data-tip='copiar al portapapeles' onClick={copi}>
            <i className="fas fa-copy"></i>
        </Button>
    </Col>
}