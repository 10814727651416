import { Button } from 'react-bootstrap';
import { ModalBusqueda } from "@victorequena22/tabla-bootstrap";
import { conShort } from '../Models';
import { THFila } from "@victorequena22/tabla-bootstrap"
import { ShortContext } from '../Context/Shorts';
class BuscarShort extends ModalBusqueda {
    con = conShort(); buscarEn = ['clave', 'descripcion'];
    Title() { return <h4 className='mr-auto'>BUSCAR ATAJO</h4> }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='clave' width='200px'>CLAVE</Th>
            <Th label='descripcion' width='330px'>DESCRIPCION</Th>
            <Th width='calc(100% - 600px)'>CAMBIO</Th>
            <Th width='70px'> </Th>
        </tr>
    }
    Row(c: any) {
        return <tr>
            <THFila width='200px'>{c.clave}</THFila>
            <THFila width='330px'>{c.descripcion}</THFila>
            <td style={{ width: 'calc(100% - 600px)' }} dangerouslySetInnerHTML={{ __html: c.html }} />
            <td style={{ width: '70px' }}>
                <Button variant='success' onClick={() => { this.props.add(c); this.props.close(); }}>
                    <i className='fas fa-check' data-tip='Editar' />
                </Button>
            </td>
        </tr>
    }
    getItens() { return this.context.shors; }
    set() {
        this.context.updateShort()
    } context: any
}
BuscarShort.contextType = ShortContext;
export default BuscarShort