import React from 'react';
import { defaultText, BlockElement, setText, getText } from './DefaultData';
import SlateProvider from '../Component/SlateCustom'
import { SizeViewConteiner, sizeWidth } from '../Component';
import { conPanel } from '../Models';
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
export interface StateS {
    id?: number;
    size: sizeWidth;
    title: string;
    html: BlockElement[];
}
export interface Props { data?: any, props?: any, edit?: string }
export class Single<s> extends React.Component<Props, (StateS & s)> {
    con = conPanel();
    random = Math.random();
    block = true;
    init = (def: s) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = { ...def, id: undefined, size: '1440px', title: '', html: defaultText };
    }
    constructor(p: { data?: StateS & s }) {
        super(p);
        this.init({} as StateS & s);
    }
    componentDidMount(): void {
        const { data } = this.props;
        if (data !== undefined) {
            this.random = Math.random();
            const { props, data: d, title, html: h, id } = data;
            const html = h !== null ? setText(h) : [];
            this.setState({ ...props, ...d, html, title, id })
        }
    }
    render() {
        const { Config, Panel, setTexto, Top, Left, state: { id: i }, props: { data, edit } } = this;
        const to = (edit ? edit : '/panel/edit/') + i
        if (data === undefined && i !== undefined) return <Redirect to={to} />
        const { html, id } = this.getText();
        return <div key={`i${this.random}`} style={{ height: '100%', maxHeight: 'calc(100vh - 180px)' }}>
            <SlateProvider key={`panel${i}${id}${this.random}`} value={html} setValue={setTexto}>
                {Config()}
                <SizeViewConteiner key={`c${this.random}`} top={Top()} left={Left()} size={this.state.size}>
                    {Panel()}
                </SizeViewConteiner>
            </SlateProvider>
        </div>
    }
    Config = () => <></>
    Panel = () => <></>
    setTitle = (title: string) => this.setData({ title })
    setTexto = (text: BlockElement[]) => { this.setData({ html: getText(text) }); }
    Top = () => <></>
    Left = () => <></>
    setData = (d: any) => this.setState(d);
    procesar = true;
    save = async () => {
        if (this.procesar) {
            this.procesar = false;
            if (this.props.data !== undefined)
                this.con.actualizar(this.props.data.id, this.getDataSave2(), () => {
                    toast('PANEL ACTUALIZADO', { type: toast.TYPE.SUCCESS });
                    this.procesar = true;
                }, () => this.procesar = true);
            else this.con.nuevo(this.getDataSave2(), ({ id }) => {
                this.setState({ id });
                toast('PANEL CREADO', { type: toast.TYPE.SUCCESS });
                this.procesar = true;
            }, () => this.procesar = true);
        } else toast('SUBIENDO DATOS, POR FAVOR ESPERE', { type: toast.TYPE.WARNING });
    }
    getDataSave = (): any => { }
    getDataSave2 = (): any => {
        const a = this.getDataSave();
        return { ...a, page: 1 };
    }
    onBlock = (f: () => void, n = 1) => {
        if (this.block) {
            this.block = false;
            setTimeout(() => { f(); this.block = true; }, n);
        }
    }
    getText = () => {
        const { html, id } = this.state;
        return { html, id } as { html: BlockElement[], id: number };
    }
}