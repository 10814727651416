import { css } from '@emotion/css';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import { Invert } from './Utils/Invert';
export function Suscribir({ size, data }: any) {
    const { props: { input, inputText, button, buttonText } } = data;
    return <Invert data={data} size={size}>
        <div className={getCSS(input, inputText, button, buttonText)}>
            <FormGroup>
                <FormControl size='lg' placeholder="Ingresa tu nombre" />
                <FormControl size='lg' placeholder="Ingresa tu correo" />
                <Button block size='lg'>Subscribirse</Button>
            </FormGroup>
        </div>
    </Invert>
}
function getCSS(color1: string, color2: string, color3: string, color4: string) {
    return css`
    margin: 0 0 0 0 !important;
    &>div> input {
        width: 100% !important;
        border-radius: 0 !important;
        &:first-child{border-radius: .3rem .3rem 0 0 !important;}
        ${toCSS(color1, color2)}
    }
    &>div> button {
        ${toCSS(color3, color4)}
        border: 0 !important;
        border-radius: 0 0 .3rem .3rem !important;
    }
    `;
}
function toCSS(back: string, text: string) {
    return `
    background: ${back} !important;
    color: ${text} !important;
    border: 2px solid ${text} !important;
    &::placeholder{
        opacity: .4 !important;
    }`

}

