import { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { Editor } from 'slate'
import { useSlate } from 'slate-react'
import { ButtonConfig } from '../../../../../../../Components/Button';
import { FontSize } from "@victorequena22/slate-bootstrap";
import BuscarShort from './Buscar';
import { fontsFamily } from './FontsFamily';
export const FontsFamily = () => {
    const editor = useSlate();
    const marks: any = Editor.marks(editor);
    const is = marks !== null && marks.fontFamily !== undefined;
    const fontFamily = is ? marks.fontFamily : 'Arial';
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => Editor.addMark(editor, 'fontFamily', e.currentTarget.value);
    return <FormControl data-tip='ESTILO DE FUENTE' style={{ fontFamily, height: '24px', borderRadius: '.25rem 0 0 .25rem', padding: '0 0 0 0' }} className='form-control-sm' as='select' onChange={set} value={fontFamily}>
        {fontsFamily.map((c, i) => <option style={{ fontFamily: c }} key={`fuente${i}`}>{c}</option>)}
    </FormControl>
}
export const FontStyle = ({ left = 2, width = '290px' }: { width?: string; left?: number; }) => {
    const s = { height: 24 }
    return <InputGroup className={`m-0 p-0 ml-${left}`} style={{ ...s, width }}>
        <InputGroup.Prepend style={{ ...s, width: 'calc(100% - 60px)' }}>
            <FontsFamily />
        </InputGroup.Prepend>
        <InputGroup.Append style={{ ...s, width: '60px' }}>
            <FontSize />
        </InputGroup.Append>
    </InputGroup>
}
const insertText = (editor: any, text: string) => {
    Editor.insertText(editor, text)
}
export const Inserts = ({ left = 1 }: { left?: number }) => {
    const editor = useSlate();
    const [show, setShow] = useState(false);
    return <>
        <BuscarShort open={show} close={() => setShow(false)}
            add={s => insertText(editor, `{${s.clave}}`)} />
        <ButtonConfig icon='fas fa-hashtag' className={`ml-${left}`}
            tip='INSERTAR ATAJO' action={() => setShow(true)} />
    </>
}