
import { Button, Row, Col, Card } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { Evento } from '../../Models/Model';
import { Cargando } from '@victorequena22/component-bootstrap';
import { conMetodoPago } from '../../../Pagos/MetodosPago/Models';
import { conPrecios } from '../../Models';
import { getEvento } from '..';
interface Props {
    evento: Evento
}
export default function Copiar() {
    const e = getEvento();
    console.log(e)
    return e.id ? <Edit evento={e} /> : <Cargando />
}
class Edit extends Prototipo<Props> {
    count = 0;
    constructor(p: Props) {
        super(p)
        this.state = { ...p.evento, Redirect: false, cargando: false };
    }
    render = () => {
        let { setData, error, state, create } = this;
        if (state.Redirect) return <Redirect to={'/evento'} />
        if (state.cargando) return <Cargando />
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> COPIAR EVENTO </h4>
            </Card.Header>
            <Form data={state} setData={setData as any} error={error}>
                <Row className='mt-2'><Col xs={12}>
                    <Button variant='success' className='float-right' onClick={create}>
                        <i className="fa fa-save"></i> Copiar
                    </Button>
                </Col></Row>
            </Form>
        </Card >)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.setState({ cargando: true });
                this.procesar = false;
                this.con.nuevo(this.getDataSave(), this.postProsecesado, this.setError)
            }
        } else this.forceUpdate();
    }
    postProsecesado = ({ id }: Evento) => {
        this.procesar = true;
        const { metodos, precios } = this.state;
        const c = () => {
            this.count++;
            if (this.count === precios.length) {
                toast('EVENTO CREADO', { type: toast.TYPE.SUCCESS });
                this.setState({ Redirect: true });
            }
        };
        conMetodoPago().multiAddEvento(metodos.map(m => m.id), id, c)
        precios.forEach(p => conPrecios().nuevo({ ...p, evento: id }, c));
    }
}