import { Button, ButtonGroup } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { conEvento } from '../../../Models/Coneccion';
import { update, getEvento } from '../../index';
interface PropsManager {
    certificado: number;
    setErrors: (d: any) => void;
}
export default function Iniciar({ certificado, setErrors }: PropsManager) {
    const { id, estatus } = getEvento();
    const save = useCallback(() => {
        if (certificado === 0) setErrors({ certificado: 'NO SE HAN SELECCIONADO EL CERTIFICADO A ENTREGAR' });
        else conEvento().participantes(id, { certificado, estatus: 2 }, () => {
            update();
            toast('EVENTO INICIADO', { type: toast.TYPE.SUCCESS });
        }, update);
    }, [certificado, setErrors, id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { setErrors({}) }, [certificado])
    if (estatus === 1)
        return <ButtonGroup className="float-right">
            <Button variant='success' onClick={() => save()}>
                <i className="fa fa-save"></i> INICIAR EVENTO
            </Button>
        </ButtonGroup>
    return <></>
}