import { Button, Row, Col } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { Capacitacion } from '../../Models';
import { setText } from '../../../../Panel/ProtoPanes/DefaultData';
import Template from '../Template';
import { getPrograma } from '../index';
export default function Editar() {
    const p = getPrograma(); 
    return <Edit data={p} />
} 
interface Props { 
    data: Capacitacion
}
class Edit extends Prototipo<Props> {
    constructor(p: any) {
        super(p)
        const { resumen, contenido } = p.data;
        this.state = { ...p.data, resumen: setText(resumen), contenido: setText(contenido), Redirect: false };
    }
    render = () => {
        let { setData, error, state, create } = this;
        if (state.Redirect) return <Redirect to={'/programa'} />
        return <Template icon='fa fa-edit' title='ACTUALIZAR PROGRAMA '>
            <Form codigo={this.props.data.codigo} data={state} setData={setData as any} error={error}>
                <Row className='mt-2'>
                    <Col xs={12}>
                        <Button variant='success' className='float-right' onClick={create}>
                            <i className="fa fa-save"></i> Guardar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Template>
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                this.con.actualizar(this.props.data.id, this.getDataSave(), () => {
                    this.procesar = true;
                    toast('PROGRAMA ACTUALIZADO', { type: toast.TYPE.SUCCESS });
                    this.setState({ Redirect: true });
                }, this.setError)
            }
        } else this.forceUpdate();
    }
}