import { getEvento } from "../../../index";
import { ParticipanteFila } from "../../../../../Participantes/Models";
import Calificar from "./Calificar";
import Certificado from "./Certificado";
interface Props {
    data: ParticipanteFila
}
export default function Fila({ data }: Props) {
    const { nombre, correo, telefono, id } = data;
    const p = getParticipante(id);
    const { estatus } = getEvento();
    function Accion() {
        if (p) {
            if (p.status === 2 && estatus === 2) return <Calificar data={data} />
            if (p.status === 3) return <Certificado data={data} />
        }
        return <></>
    }
    return <tr className="info" >
        <td>{nombre}</td>
        <td>{correo}</td>
        <td>{telefono}</td>
        <td className="text-right">
            <Accion />
        </td>
    </tr>
}
function getParticipante(id: number) {
    return getEvento().participantes.find(p => p.id === id);
}