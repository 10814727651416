import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { conPago, PagoLista } from './Models';
import Fila from './Fila';
import { sha256 } from '@victorequena22/utiles';
import { ListButonLink } from '../../../Components/Button/ListBoton';
export default class ListaPago extends CardBuscador {
    con = conPago();
    placeholder = "BUSCAR PAGOS";
    buscarEn = ["fecha", "banco", "referencia", "monto", "nota", 'monto'];
    Title = () => <>
        <h3 className='mr-auto'><i className="fa fa-search"></i> BUSCAR PAGOS</h3>
        <ListButonLink permiso='EVENTO' tip='' variant="primary" to='/pago/metodos' >
            <i className="fa fa-credit-card"></i> METODOS DE PAGO
        </ListButonLink>
    </>
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='90px' label='fecha'>Fecha</Th>
            <Th width='150px' label='banco'>Banco</Th>
            <Th width='150px' label='referencia'>Referencia</Th>
            <Th width='auto' label='nombre'>nombre</Th>
            <Th width='120px' label='monto'>Monto</Th>
            <Th width='100px'>Estado</Th>
            <Th width='auto' label='nota'>Nota</Th>
            <Th width='80px'> </Th>
        </tr>
    }
    Row = (p: PagoLista) => <Fila key={sha256(p)} pago={p} update={this.set.bind(this)} />
    set = () => this.con.lista(itens => this.setState({ itens }));
}