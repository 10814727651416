import { Html } from '../Component/Commons';
import { css } from '@emotion/css';
import { Row, Container, Card, Col } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
export function Cargos({ data: { itens, props }, size }: any) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: s2.some(s => size === s) ? 4 : s1.some(s => size === s) ? 3 : 2,
        slidesToScroll: 1
    }
    const { color } = props
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container style={{ background: color }} className={getCSS(color)}>
            <Slider {...settings}>
                {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} />)}
            </Slider>
        </Container>
    </Row>
}
function Itens({ props: { gap }, iten: { html, props: { img, color, back } } }: any) {
    const style = { background: back, color };
    return <Col className='m-0 p-0'>
        <Card style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={img} />
            <Html style={style} className='card-body' html={html} />
        </Card>
    </Col >
}


function getCSS(color: string) {
    return css`.slick-prev:before, .slick-next:before{
        color: ${color};
    }`;
}