import { Table } from "react-bootstrap";
import Fila from "./Fila";
import { Suscriptores } from "../../Programas/Models/Models";

export default function Subcriptores({ s, u }: { s: Suscriptores[], u: () => void }) {
    return <Table>
        <thead>
            <tr>
                <th>NOMBRE</th>
                <th>TELEFONO</th>
                <th>CORREO</th>
                <th>PAIS</th>
                <th>ESTADO</th>
                <th>CIUDAD</th>
                <th>ESTATUS</th>
                <th>ACCIONES</th>
            </tr>
        </thead>
        <tbody>
            {s.map(e => <Fila u={u} s={e} />)}
        </tbody>
    </Table>
}