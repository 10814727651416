import React from "react"
import Chart from "react-google-charts"
interface State {
    values: any[]
}

export default class Visitas extends React.Component<{}, State>{
    constructor(p: any) {
        super(p);
        this.state = { values: [] };
    }
    render() {
        let minValue = 0;
        const { values } = this.state;
        if (values.length !== 0)
            values.forEach(d => {
                let v = d[1] as number;
                let c = d[2] as number;
                if (v > minValue) minValue = v;
                if (c > minValue) minValue = c;
            })
        return <Chart
            width='60rem'
            height='35rem'
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={[['FECHA', 'VISITAS'], ...values]}
            options={{
                title: "VISITAS EN LA SEMANA",
                hAxis: { title: "FECHA", titleTextStyle: { color: '#333' } },
                vAxis: { minValue },
                chartArea: { width: '85%', height: '70%' },
                legend: { position: 'none' }
            }}
            rootProps={{ 'data-testid': '1' }}
        />
    }
    send = async () => {
        try {
            const token = localStorage.getItem('Authorization');
            const response = await fetch('/api/Web/Metricas/visitas', { headers: { 'Authorization': 'Bearer ' + token } });
            const values: any[] = await response.json();
            this.setState({ values });
        } catch (error) {
            console.log(error);
            this.setState({ values: [] });
        }
    }
    componentDidMount() { this.send() }
}