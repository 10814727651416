import { setData } from '../../../../../Config/Coneccion';
import { Conexion } from "../../../../../Config/Coneccion";

class MetodoPagoConecion extends Conexion {
    clase = 'Pagos/Metodo';
    addEvento = (metodo: number, evento: number, setData: setData) => {
        this.post('/api/Pagos/Metodo/addEvento', { metodo, evento }, setData);
    }
    multiAddEvento = (metodos: number[], evento: number, setData: setData) => {
        this.post('/api/Pagos/Metodo/multiAddEvento', { metodos, evento }, setData);
    }
    removeEvento = (metodo: number, evento: number, setData: setData) => {
        this.post('/api/Pagos/Metodo/removeEvento', { metodo, evento }, setData);
    }

}
export function conMetodoPago() {
    return new MetodoPagoConecion();
}