import { Button, Row, Col } from 'react-bootstrap';
import { CardBuscador } from '@victorequena22/tabla-bootstrap';
import { conParticipante, ParticipanteFila } from './Models';
import { Fila } from './Fila';
import FormParticipante from './Form';
import { useContext } from 'react';
import { ParticipanteContext } from '../Context/Participante';
import { IE, IEL } from '../../../Config/Updater';
import { ControlText } from '@victorequena22/component-bootstrap';
import ParticipantesExcel from './Excel';

export default function ListaParticipante() {
    const { participantes } = useContext(ParticipanteContext);
    return <Tabla key={IEL(participantes) + 'participantes'} itens={participantes} />
}
class Tabla extends CardBuscador<{ itens: ParticipanteFila[] }> {
    con = conParticipante();
    buscarEn = ['nombre', 'correo', 'telefono'];
    Title = () => <>
        <FormParticipante open={this.state.modal} title='NUEVO PARTICIPANTE' participante={null}
            close={() => this.setState({ modal: false })} add={this.set.bind(this)} />
        <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR PARTICIPANTE</h4>
        <Button onClick={() => this.setState({ modal: true })}><i className="fa fa-edit"></i> NUEVO PARTICIPANTE</Button>
    </>
    Buscador() {
        return <>
            <Row className='d-flex' style={{ paddingBottom: '15px' }}>
                <Col md={9} className='mr-auto'>
                    <ControlText before='BUSCAR' setData={this.setState} update='buscar' value={this.state.buscar} />
                </Col>
                <Col md={3} className='mr-auto d-flex flex-row-reverse'>
                    <ParticipantesExcel Participantes={this.filter()} />
                </Col>
            </Row>
        </>
    }
    Row = (d: ParticipanteFila) => <Fila key={IE(d) + 'participante'} data={d} update={this.set} />
    Header() {
        let { Th } = this;
        return (<tr className="info">
            <Th width='auto' label='nombre'>NOMBRE</Th>
            <Th width='300px' label='correo'>CORREO</Th>
            <Th width='200px' label='telefono'>TELEFONO</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>)
    }
    componentDidMount(): void {
        const { itens } = this.props;
        this.setState({ itens });
    }
    componentWillReceiveProps({ itens }: any): void {
        this.setState({ itens });
    }
}
