import { useEffect, useState } from "react";
import { Cargando } from "@victorequena22/component-bootstrap";
import { conUsuario, Permisos } from "../../Usuarios/Usuario";
import { PermisoButton, TreePermiso } from "./TreePermiso";

interface Props {
    selecteds: number[],
    Click: (id: number) => void
}

export function SelectorPermisos(p: Props) {
    const [listaPermisos, setPermisos] = useState<Permisos[]>([])
    const d = { listaPermisos, ...p };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { conUsuario().listaPermisos(setPermisos); }, []);
    return listaPermisos.length ? <>
        <TreePermiso permiso='PAGOS' title='GESTTION DE PAGOS' {...d} tip=''>
            <PermisoButton permiso='PAG_METODOS' {...d} tip=''>GESTION DE METODOS DE PAGOS</PermisoButton>
            <PermisoButton permiso='PAG_CANCELAR' {...d} tip=''>CANCELAR PAGO</PermisoButton>
            <PermisoButton permiso='PAG_APROBAR' {...d} tip=''>CANCELAR PAGO</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='PARTICIPANTE' title='PARTICIPANTES' {...d} tip=''>
            <PermisoButton permiso='PAR_CREAR' {...d} tip=''>CREAR PARTICIPANTE</PermisoButton>
            <PermisoButton permiso='PAR_EDITAR' {...d} tip=''>EDITAR PARTICIPANTE</PermisoButton>
            <PermisoButton permiso='PAR_FACILITADOR' {...d} tip=''>PARTICIPANTE FACILITADOR</PermisoButton>
            <PermisoButton permiso='PAR_EMPRESA' {...d} tip=''>PARTICIPANTE EMPRESA</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='EVENTO' title='EVENTOS' {...d} tip=''>
            <PermisoButton permiso='EVE_CREAR' {...d} tip=''>CREAR EVENTO</PermisoButton>
            <PermisoButton permiso='EVE_EDITAR' {...d} tip=''>EDITAR EVENTO</PermisoButton>
            <PermisoButton permiso='EVE_BORRAR' {...d} tip=''>BORRAR EVENTO</PermisoButton>
            <PermisoButton permiso='EVE_PUBLICAR' {...d} tip=''>PUBLICAR EVENTO</PermisoButton>
            <PermisoButton permiso='EVE_PARTICIPANTES' {...d} tip=''>GESTIONAR PARTICIPANTES</PermisoButton>
            <PermisoButton permiso='EVE_CERTIFICADOS' {...d} tip=''>EDITAR Y CREAR  CERTIFICADOS</PermisoButton>
            <PermisoButton permiso='EVE_CONSIDERACIONES' {...d} tip=''>EDITAR Y CREAR CONSIDERACIONES</PermisoButton>
            <PermisoButton permiso='EVE_NORMAS' {...d} tip=''>EDITAR Y CREAR NORMAS</PermisoButton>
            <PermisoButton permiso='EVE_AVAL' {...d} tip=''>EDITAR Y CREAR AVALES</PermisoButton>
            <PermisoButton permiso='EVE_PRECIO' {...d} tip=''>GESTIONAR LA INFORMACION DE PAGO DEL EVENTO</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='FACILITADOR' title='FACILITADORES' {...d} tip=''>
            <PermisoButton permiso='FAC_CREAR' {...d} tip=''>CREAR FACILITADOR</PermisoButton>
            <PermisoButton permiso='FAC_EDITAR' {...d} tip=''>EDITAR FACILITADOR</PermisoButton>
            <PermisoButton permiso='FAC_BANEAR' {...d} tip=''>BANEAR FACILITADOR</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='PROGRAMA' title='PROGRAMAS' {...d} tip=''>
            <PermisoButton permiso='PRO_CREAR' {...d} tip=''>CREAR PROGRAMA</PermisoButton>
            <PermisoButton permiso='PRO_EDITAR' {...d} tip=''>EDITAR PROGRAMA</PermisoButton>
            <PermisoButton permiso='PRO_PUBLICAR' {...d} tip=''>PUBLICAR PROGRAMA</PermisoButton>
            <PermisoButton permiso='PRO_BORRAR' {...d} tip=''>ELIMINAR PROGRAMA</PermisoButton>
            <PermisoButton permiso='PRO_CATEGORIA' {...d} tip=''>EDITAR Y CREAR CATEGORIAS</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='PANEL' title='PAGINA WEB' {...d} tip=''>
            <PermisoButton permiso='PAN_TEMPLATE' {...d} tip=''>MOODIFICAR EL TEMPLATE DE LA PAGINA WEB</PermisoButton>
            <PermisoButton permiso='PAN_PAGINAS' {...d} tip=''>CREAR O QUITAR PAGINAS</PermisoButton>
            <PermisoButton permiso='PAN_PUBLICAR' {...d} tip=''>PUBLICAR PANEL</PermisoButton>
            <PermisoButton permiso='PAN_SHORT' {...d} tip=''>EDITAR Y CREAR LAS PALABRAS CLAVES PARA LA PAGINA WEB</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='MEDIA' title='GALERIA' {...d} tip=''>
            <PermisoButton permiso='MED_SUBIR' {...d} tip=''>SUBIR IMAGEN</PermisoButton>
            <PermisoButton permiso='MED_BORRAR' {...d} tip=''>BORRAR IMAGEN</PermisoButton>
            <PermisoButton permiso='MED_EDITAR' {...d} tip=''>EDITAR IMAGEN</PermisoButton>
            <PermisoButton permiso='MED_NOMBRAR' {...d} tip=''>NOMBRAR Y COLOCAR ETIQUETA A LA IMAGEN </PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='REPORTE' title='REPORTES' {...d} tip=''>
            PERMITE ACCEDER AL AREA DE REPORTES Y USAR LOS REPORTES DE LOS MODULOS QUE TENGA PERMISO.
        </TreePermiso>
        <TreePermiso permiso='USUARIO' title='USUARIOS DEL SISTEMA' {...d} tip=''>
            <PermisoButton permiso='USU_CLAVE' {...d} tip=''>CAMBIAR LA CLAVE  DE USUARIO</PermisoButton>
            <PermisoButton permiso='USU_NUEVO' {...d} tip=''>CREAR USUARIO</PermisoButton>
            <PermisoButton permiso='USU_EDITAR' {...d} tip=''>EDITAR INFORMACION DEL USUARIO</PermisoButton>
            <PermisoButton permiso='USU_BANEAR' {...d} tip=''>BANEAR USUARIO</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='CONFIG' title='CONFIGURACIONES' {...d} tip=''>
            <PermisoButton permiso='CON_EMPRESA' {...d} tip=''>CAMBIAR LA CONFIGURACION DE LA EMPRESA</PermisoButton>
            <PermisoButton permiso='CON_ROLES' {...d} tip=''>CAMBIAR LA CONFIGURACION DE IMPRECIONES</PermisoButton>
            <PermisoButton permiso='CON_IMPRECION' {...d} tip=''>CREAR Y EDITAR ROLES</PermisoButton>
            <PermisoButton permiso='CON_WEB' {...d} tip=''>CONFIGURACIONES DE LA WEB</PermisoButton>
        </TreePermiso>
        <TreePermiso permiso='ALIADO' title='ALIADOS' {...d} tip=''>
            <PermisoButton permiso='ALI_ROL' {...d} tip=''>CREAR Y EDITAR LOS ROLES DEL ALIADO</PermisoButton>
            <PermisoButton permiso='ALI_BANEAR' {...d} tip=''>BANEAR A UN ALIADO</PermisoButton>
            <PermisoButton permiso='ALI_CLAVE' {...d} tip=''>CAMBIAR CLAVE DEL ALIADO</PermisoButton>
        </TreePermiso>
    </> : <Cargando />;
}