import { content, imagen, posicion } from './props';
import { EditArea } from '../../../Component/SlateCustom';
import { iten } from '../index';
import { TextToHTML } from "@victorequena22/slate-bootstrap";
interface Props {
    position: number
    iten: iten;
}
export function Select({
    position: p,
    iten: { img, id, color, back, borde, bordeColor, redondius, html, position, disable } }: Props) {
    const d = content(back, color, bordeColor, borde, redondius, disable);
    const get = () => {
        if (p === 2) return "";
        else if (p === 3) return "next";
        else return "prev";
    }
    return <div className={'iten ' + get()}>
        <img style={imagen} alt="Bricks" src={img} />
        <div style={posicion[position]} className={`${d} carousel-caption`}>
            <EditArea key={'edit' + id} />
        </div>
    </div>
}
export function NoSelect({
    position: p,
    iten: { img, color, back, borde, bordeColor, redondius, html, position, disable } }: Props) {
    const d = content(back, color, bordeColor, borde, redondius, disable);
    const get = () => {
        if (p === 2) return "";
        else if (p === 3) return "next";
        else return "prev";
    }
    return <div className={'iten ' + get()}>
        <img style={imagen} alt="Bricks" src={img} />
        <div style={posicion[position]} className={`${d} carousel-caption`} >
            <TextToHTML text={html} />
        </div>
    </div>
}