import { Invert } from '../../Component/Commons';
import { State } from './index'
export function Panel({ config, width }: { config: State, width: number }) {
    const { backgroundColor, img } = config;
    return <div style={{ padding: '60px 0', background: backgroundColor }}>
        <div className="container">
            <Invert width={width} config={config}>
                <img src={img} width='100%' alt='panel a' />
            </Invert>
        </div>
    </div>
}