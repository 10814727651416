import { semaforo } from '@victorequena22/utiles';
import { useState, createContext, useEffect, useCallback } from 'react';
import { FacilitadorFila, facilitadorUpdate, conFacilitador } from '../Facilitadores/Models';
export interface FacilitadoresType {
    Facilitadores: FacilitadorFila[]
    actualizar: () => void
}
export const initFacilitadores: FacilitadoresType = {
    Facilitadores: [], actualizar: () => { }
}
export const FacilitadorContext = createContext<FacilitadoresType>(initFacilitadores);
export default function FacilitadorProvider({ children }: { children: any }) {
    const [Facilitadores, setFacilitadores] = useState<FacilitadorFila[]>([]);
    const [con] = useState(conFacilitador());
    const actualizar = useCallback(() => facilitadorUpdate(con,Facilitadores, setFacilitadores), [Facilitadores, con]);
    useEffect(() => {
        const s = semaforo();
        s.add('Facilitador');
        return () => s.clean('Facilitador');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        let interval = setInterval(actualizar, 50);
        return () => clearInterval(interval);
    }, [actualizar]);
    return <FacilitadorContext.Provider value={{ Facilitadores, actualizar }}>
        {children}
    </FacilitadorContext.Provider>
}
export const SeccionConsumer = FacilitadorContext.Consumer;