import { Button } from "react-bootstrap";
import { getEvento, update } from "../..";
import { conEvento } from "../../../Models";

export default function Finalizar() {
    const { id, estatus, participantes } = getEvento();
    const evaluar = participantes.filter(p => p.status === 2);
    if (evaluar.length === 0 && estatus === 2)
        return <Button className="float-right" variant="success"
            onClick={() => { conEvento().finalizar({ id }, update, update); }}>
            <i className="fa fa-check" /> FINALIZAR EVENTO
        </Button>
    return <></>
}