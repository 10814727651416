import { ButtonGroup, Row } from "react-bootstrap";
import { ListButonLink } from "../../../../Components/Button/ListBoton";
import { useLocation } from "react-router-dom";
import { getFacilitador } from "./index";


export function MemuEvento() {
    const { id } = getFacilitador();
    return <ButtonGroup vertical className="float-right btn-sm btn-block">
        <ButtonLink permiso='FACILITADOR' variant="info" to={`/facilitador/ver/${id}`}>
            <i className="fa fa-eye mr-2" /> INFORMACIÓN DEL FACILITADOR
        </ButtonLink>
        <ButtonLink permiso='FAC_EDITAR' variant="primary" to={`/facilitador/edit/${id}`}>
            <i className="fa fa-edit mr-2" /> EDITAR EL FACILITADOR
        </ButtonLink>
        <ButtonLink permiso='FAC_EDITAR' variant="dark" to={`/facilitador/landing/${id}`}>
            <i className="fa fa-browser mr-2" /> LANDING PERSONAL
        </ButtonLink>
    </ButtonGroup>
}
export function Title() {
    return <Title2 back={`/facilitador`} />
}
export function Title2({ back }: { back: string }) {
    const { nombre } = getFacilitador();
    return <Row className="mb-2" >
        <ListButonLink className="mb-2 float-right" permiso='FACILITADOR' tip='VOLVER' variant="primary" to={back}>
            <i className="fa fa-arrow-left" />
        </ListButonLink>
        <h3 className='ml-2 mr-auto text-info'>{nombre}</h3>
    </Row>
}

function ButtonLink({ to, children, variant, permiso }: { permiso: string, to: string, variant: string, children: any }) {
    const { pathname } = useLocation()
    return <ListButonLink className="text-left" permiso={permiso}
        variant={`${to === pathname ? '' : 'outline-'}${variant}`} to={to}>
        {children}
    </ListButonLink>
}
