import { Conexion, setData } from "../../../../Config/Coneccion";


class PagoConecion extends Conexion {
    clase = 'Pagos';
    pagosEvento = async (id: number, setData: setData) => {
        this.get('/api/Pagos/pagosEvento/' + id, setData);
    }
    aprobar = async (id: number, nota: string, setData: setData) => {
        this.post('/api/Pagos/aprobar', { id, nota }, setData);
    }
    nota = async (id: number, nota: string, setData: setData) => {
        this.post('/api/Pagos/nota', { id, nota }, setData);
    }
    reprobar = async (id: number, nota: string, setData: setData) => {
        this.post('/api/Pagos/reprobar', { id, nota }, setData);
    }

}
export function conPago() {
    return new PagoConecion();
}