import React, { useState, useContext, useCallback, useEffect } from "react"
import { Button, Card, Col, FormGroup } from 'react-bootstrap'
import "./Login.css";
import { ControlText } from "@victorequena22/component-bootstrap";
import { ConConfig } from "../../Config/Config";
import { SeccionContext } from "../../Template/Seccion";

export default function LoginC() {
    const { user } = useContext(SeccionContext) as any;
    const [correo, setCorreo] = useState('');
    const [clave, setClave] = useState('');
    const [error, setError] = useState<any>({});
    const login = useCallback(() => ConConfig('')
        .post('/api/Aliado/login', { correo, clave }, (data) => {
            if (data.error) setError(data.error)
            else {
                localStorage.setItem('Authorization', data.token);
                user();
            }
        }), [clave, correo, user]);
    useEffect(() => setError({}), [clave, correo,])
    return <div className="login-layoud" style={{ backgroundImage: 'url("/static/media/fondo.svg")' }}>
        <div className="login-box">
            <Card border="primary" className="card-outline" style={{ backgroundColor: "rgba(200, 200, 200, 0.75)" }} >
                <Card.Header className="text-center">
                    <Card.Img variant="top" src='/public/imagenes/post-1628087048.png' alt="ImgLogin" />
                </Card.Header>
                <Card.Body>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={error} value={correo} update="correo"
                            next={() => document.getElementById('clave')?.focus()}
                            before="CORREO" maxLength={150} setData={d => setCorreo(d.correo)} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={error} next={login} id='clave'
                            before="CLAVE" maxLength={150} value={clave} update="clave"
                            setData={d => setClave(d.clave)} type="password" />
                    </Col>
                    <Button onClick={login} variant="primary" block>ENTRAR</Button>
                </Card.Body>
            </Card>
        </div>
    </div>
}