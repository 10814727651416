import { css } from '@emotion/css';
import { Row, Container, Card, Col } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ToRGB, fechaToNumber } from "@victorequena22/utiles";
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { EventoContext } from '../../Capacitacion/Context/Eventos';
import { ProgramaContext, findProgram } from '../../Capacitacion/Context/Programas';
export function Eventos({ data: { props }, size }: any) {
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const { color, filtroType, filtro } = props;
    const { eventos } = useContext(EventoContext);
    const { programas } = useContext(ProgramaContext);
    const e = eventos.filter(e => e.publicacion && e.estatus && e.estatus < 3)
        .filter(e => {
            if (filtro) {
                if (filtroType === 'program') return e.programa === filtro;
                if (filtroType === 'categoria') return programas.filter(p => p.categoria === filtro).some(p => e.programa === p.id);
                if (fechaToNumber() > fechaToNumber(e.final)) return false;
            }
            return true;
        }).map(e => <Itens iten={e} props={props} />);
    const slidesToShow = s2.some(s => size === s) ? 4 : s1.some(s => size === s) ? 3 : 2;
    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: e.length < slidesToShow ? e.length : slidesToShow,
        slidesToScroll: 1
    }
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container className={getCSS(color)}>
            <Slider {...settings}>{e}</Slider>
        </Container>
    </Row>
}
function Itens({ props: { gap }, iten: { short, programa: p, id } }: any) {
    const { short: s } = findProgram(p);
    return <Col className='m-0 p-0'>
        <Card as={Link} to={`/evento/edit/${id}`} style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={short === '' ? s : short} />
        </Card>
    </Col >
}


function getCSS(color: string) {
    const { r, g, b } = ToRGB(color);
    const r1 = r >= 128 ? 0 : 255;
    const g1 = g >= 128 ? 0 : 255;
    const b1 = b >= 128 ? 0 : 255;
    return css`.slick-prev:before, .slick-next:before{
        color: rgb(${r1},${g1},${b1});
    }`;
}