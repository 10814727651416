import { InputText, RowConfig } from "@victorequena22/component-bootstrap";
import { useCallback, useState } from "react";
import { Button, InputGroup, Modal, Row } from "react-bootstrap";
import Popover from '@mui/material/Popover';
interface Props {
    bg: string;
    status: string;
    update: (d: any) => void
}

export function StatusChange({ bg: b, status: s, update: u }: Props) {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(s);
    const [bg, setBG] = useState(b);
    const estatus = useCallback(() => { u({ status, bg }); setOpen(false) }, [u, status, bg]);
    return <>
        <Button variant="info" size="sm" onClick={() => setOpen(true)}>
            <i className="fas fa-retweet" />
        </Button>
        <Modal show={open} size='lg' centered onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>CAMBIAR ESTATUS</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                NUEVO ESTATUS
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText maxLength={30} value={status} setData={setStatus} />
                        <InputGroup.Text style={{ borderRadius: 0 }}>
                            {30 - status.length}
                        </InputGroup.Text>
                        <InputGroup.Append>
                            <ButtonColor setData={setBG} color={bg} />
                        </InputGroup.Append>
                    </InputGroup>
                </Row>
                <Row className="mt-2">
                    <Button variant="success" className="ml-auto" onClick={estatus}>
                        <i className="fas fa-save" /> GUARDAR
                    </Button>
                </Row>
            </Modal.Body>
        </Modal>
    </>
}
interface colorProps {
    setData: (d: string) => void,
    color: string
}
export function ButtonColor({ setData, color: c }: colorProps) {
    const [open, setOpen] = useState<any>(null);
    const handleClick = (event: any) => setOpen(event.currentTarget);
    const handleClose = () => setOpen(null);
    return <>
        <Button onClick={handleClick} className={`bg-${c}`}>
            <b>CAMBIAR COLOR</b>
        </Button>
        <Popover open={open} anchorEl={open} onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <RowConfig style={{
                padding: '.5rem .5rem .5rem .5rem',
                width: '320px', display: 'grid', gap: '.25rem', gridAutoFlow: 'dense',
                gridTemplateColumns: 'repeat(auto-fill, minmax(25px, 1fr))'
            }}>
                {bg.map(c => <div className={`bg-${c}`} onClick={() => setData(c)} style={{ border: '1px solid black', height: '25px' }} />)}
            </RowConfig>
        </Popover>
    </>
}
const bg = ['primary', 'secondary', 'success', 'warning', 'danger', 'black', 'light', 'gray-dark', 'gray', 'indigo', 'navy', 'purple', 'fuchsia', 'pink', 'maroon', 'orange', 'lime', 'teal', 'olive']