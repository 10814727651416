import { FormControl, InputGroup } from 'react-bootstrap';
import { CardMain, ButtonColor, TitleCard, CardConfig, RowConfig, InputNumber } from "@victorequena22/component-bootstrap";
import { Guardar, SizeViewSelector, Title } from '../../../Panel/Component';
import { State } from './index';
import ReactTooltip from 'react-tooltip';
import { ChangeEvent } from 'react';
import CategoriaSelector from '../../../Capacitacion/Programas/Categorias/Selector';
import ProgramaSelector from '../../../Capacitacion/Programas/Selector';
import { getColors } from '../../../../Template/Colores';
interface Props {
    save: () => void
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, gap, color, filtro, filtroType } = config;
    const setType = (e: ChangeEvent<HTMLSelectElement>) => setConfig({ filtro: 0, filtroType: e.currentTarget.value })
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="250px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ReactTooltip />
                <ButtonColor gradiend colores={getColors()} disableAlpha tip='COLOR DE FONDO DEL PANEL' setData={color => setConfig({ color })} color={color}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <InputGroup data-tip='SEPARACION ENTRE CARTAS' className='input-group-sm ml-1' style={{ height: '24px', width: 70 }}>
                    <InputGroup.Prepend style={{ height: '24px', width: '38px' }}>
                        <InputGroup.Text style={{ height: '24px', width: '38px' }}>
                            <i style={{ borderLeft: 'solid 3px currentColor', borderRight: 'solid 3px currentColor' }} className="fas fa-arrows-h"></i>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputNumber max={50} step={1} min={0} decimal={0} style={{ height: 24 }} value={gap} setData={gap => setConfig({ gap })} />
                </InputGroup>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <CardConfig width="500px">
            <RowConfig >
                <FormControl as='select' onChange={setType} value={filtroType} >
                    <option value='none'>SIN FILTROS</option>
                    <option value='program'>POR PROGRAMA</option>
                    <option value='categoria'>POR CATEGORIA</option>
                </FormControl>
            </RowConfig>
            <RowConfig top={2} style={{ height: '42' }}>
                {filtroType === 'categoria' ? <CategoriaSelector change={filtro => setConfig({ filtro })} defaultValue={filtro} /> : <></>}
                {filtroType === 'program' ? <ProgramaSelector change={filtro => setConfig({ filtro })} defaultValue={filtro} /> : <></>}
            </RowConfig>
            <TitleCard>FILTROS</TitleCard>
        </CardConfig>
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}