import { useState, useContext } from 'react'
import { Row, Card } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { LandingContext } from './Context';
import { SizeViewConteiner, sizeWidth } from '../../../../Panel/Component';
import { MenuZise } from '../../../../Panel/Utiles';
import GestionPanel from './Utils/GestionPanel';
import Template from '../Template';
import MenuTemplate from './Utils/Menu';
import { PreviewSize } from '../../../../PanelProxi/Ver/PreviewSize';
export function Ver() {
    const { panels } = useContext(LandingContext);
    const [size, setSize] = useState<sizeWidth>('990px');
    return <Template icon="fas fa-browser" title="VER LANDING DEL FACILITADOR">
        <Card style={{ backgroundColor: '#ccc', padding: '7px 7px 7px 7px' }}>
            <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
                <MenuTemplate />
                <MenuZise setSize={setSize} size={size} />
            </Row>
        </Card>
        <ReactTooltip key='tooltip' />
        <SizeViewConteiner h1='calc(100vh - 375px)' h2='calc(100vh - 375px)' size={size} >
            {panels.filter(p => p.indice > 0 && p.estado > -1)
                .sort((a, b) => a.indice - b.indice)
                .map(p => <div id={`panel${p.id}${p.indice}`} className='w-100 m-0 p-0'>
                    <GestionPanel panel={p} />
                    <PreviewSize key={p.type + p.id} panel={p} size={size} />
                </div>)}
        </SizeViewConteiner>
    </Template>
}