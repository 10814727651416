import Preview from '../../Panel/Ver/PreviewSize';
import { Eventos } from '../SizePanel/Eventos';
import { Programas } from '../SizePanel/Programas';

export function PreviewSize({ panel, size }: { panel: any, size: string }) {
    switch (panel.type) {
        case 'eventos': return <Eventos key={'panel' + panel.id} data={panel} size={size} />
        case 'programas': return <Programas key={'panel' + panel.id} data={panel} size={size} />
        default: return <Preview key={'panel' + panel.id} panel={panel} size={size} />
    }
}