/* eslint-disable react-hooks/rules-of-hooks */
import { semaforo } from '@victorequena22/utiles';
import { useState, createContext, useEffect, useCallback, useContext } from 'react'
import { Capacitacion, CapacitacionFila, CapacitacionNull, CapacitacionUpdate, conCapacitacion } from '../Programas/Models';

export interface ProgramaType {
    programas: CapacitacionFila[]
    actualizar: () => void
}
export const ProgramaContext = createContext<ProgramaType>({
    programas: [], actualizar: () => { },
});
export default function ProgramaProvider({ children }: { children: any }) {
    const [programas, setCapacitacion] = useState<CapacitacionFila[]>([]);
    const [con] = useState(conCapacitacion());
    const actualizar = useCallback(() => CapacitacionUpdate(con, programas, setCapacitacion), [con, programas]);
    useEffect(() => {
        const s = semaforo();
        s.add('Capacitacion');
        return () => s.clean('Capacitacion');
    }, []);
    useEffect(() => {
        let interval = setInterval(actualizar, 50)
        return () => clearInterval(interval)
    }, [actualizar]);
    return <ProgramaContext.Provider value={{ programas, actualizar }}>
        {children}
    </ProgramaContext.Provider>
}
export const SeccionConsumer = ProgramaContext.Consumer;


export function findProgram(id: number | string): CapacitacionFila | Capacitacion {
    const { programas } = useContext(ProgramaContext);
    const [programa, setProgram] = useState<any>(programas.find(p => p.id === id || p.codigo === id));
    useEffect(() => { conCapacitacion().detalle(id, setProgram) }, [id]);
    if (id === 0) return CapacitacionNull;
    if (id === '0') return CapacitacionNull;
    if (id === '') return CapacitacionNull;
    return programa ? programa : CapacitacionNull;
}
export function findProgramFila(id: number | string): CapacitacionFila {
    const { programas } = useContext(ProgramaContext);
    const [programa] = useState<any>(programas.find(p => p.id === id || p.codigo === id));
    if (id === 0) return CapacitacionNull;
    if (id === '0') return CapacitacionNull;
    if (id === '') return CapacitacionNull;
    return programa ? programa : CapacitacionNull;
}