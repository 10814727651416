import { BlockElement, defaultBlock } from "@victorequena22/slate-bootstrap";
import { Conexion } from "../../../../../Config/Coneccion";

class CertificadosConecction extends Conexion {
    clase = 'Evento/certificados';
}
export function conCertificados() {
    return new CertificadosConecction();
}

export interface CertificadosFila {
    id: number;
    nombre: string;
    creacion: string;
}
export interface Certificados extends CertificadosFila {
    data?: string;
    props: Props;
}
export const certificadosNull: Certificados = {
    id: 0,
    nombre: '',
    creacion: '',
    props: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        text: defaultBlock
    }
}

export interface Props {
    top: number;
    left: number;
    right: number;
    bottom: number;
    text: BlockElement[]
}