import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Container } from 'react-bootstrap';
import { State } from './index'
import { useContext } from "react";
import { Itens } from "./Itens";
import { ProgramaContext } from "../../../Capacitacion/Context/Programas";
import { EditArea } from "../../../Panel/Component/SlateCustom";
export function Panel({ config }: { config: State }) {
    const { color, filtroType, filtro } = config;
    const { programas } = useContext(ProgramaContext);
    return <Row style={{ background: color, margin: '0 0 0 0', padding: '0 0 2rem 0' }}>
        <div style={{ display: 'none' }}><EditArea /></div>
        <Container><Row>
            {programas.filter(e => e.estatus === 2)
                .filter(e => {
                    if (filtroType !== 'none') {
                        if (filtroType === 'categoria' && filtro !== 0)
                            return e.categoria === filtro;
                    }
                    return true;
                }).map(e => <Itens iten={e} config={config} />)}
        </Row></Container>
    </Row>
}