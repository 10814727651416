import ReactTooltip from 'react-tooltip';
import { ButtonGroup } from 'react-bootstrap';
import { THFila } from "@victorequena22/tabla-bootstrap"
import { ParticipanteFila, conParticipante } from './Models';
import { ListButonClick } from '../../../Components/Button/ListBoton';
import { useContext, useState } from 'react';
import FormParticipante from './Editar';
import { ParticipanteContext } from '../Context/Participante';
interface Props {
    data: ParticipanteFila
    update: () => void
}
export function Fila({ data: d, update }: Props) {
    const { participantes } = useContext(ParticipanteContext);
    const data = participantes.find(p => d.id === p.id) as ParticipanteFila;
    const { id, nombre, correo, telefono, estatus: e } = data;
    const [open, setOpen] = useState(false);
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='300px'>{correo}</THFila>
        <THFila width='200px'>{telefono}</THFila>
        <THFila width='100px'><span className={`badge badge-${e ? 'success' : 'danger'}`}>{e ? 'ACTIVO' : 'INACTIVO'}</span></THFila>
        <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            {open ? <FormParticipante open={open} title={`EDITAR ${nombre}`} participante={data} close={() => setOpen(false)} add={() => { }} /> : <></>}
            <ButtonGroup className="float-right">
                <ListButonClick permiso='PAR_CREAR' tip="EDITAR" variant="primary" click={() => setOpen(true)}>
                    <i className="fa fa-edit" />
                </ListButonClick>
                <ReactTooltip />
                <ListButonClick permiso='PAR_BANEAR' tip={e ? "BANEAR" : "REACTIVAR"} variant={e ? 'danger' : 'success'} click={() => conParticipante().cancelar(id, update)}>
                    <i className={`fa fa-${e ? 'ban' : 'check'}`} />
                </ListButonClick>
            </ButtonGroup>
        </th>
    </tr >
}