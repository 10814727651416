import { getEvento } from "../../index";
import { Aprobados, Asistencia, Excel, VerDemo } from "../../Ver/Exports";

export function Exports() {
    const { estatus } = getEvento();
    return <>
        {estatus === 2 ? <VerDemo /> : <></>}
        <Asistencia />
        <Aprobados />
        <Excel />
    </>
}

