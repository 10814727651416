import { toast } from "react-toastify";
import { Conexion } from "../Config/Coneccion";

class ConfigConeccion extends Conexion {
    config: string;
    constructor(config: string) {
        super();
        this.config = config;
    }
    pull(setData: (d: any) => void) {
        this.get('/api/Config/get/' + this.config, setData);
    }
    push(data: any, setData: (d: any) => void) {
        this.post('/api/Config/set/' + this.config, data, (d) => {
            setData(d)
            toast('CONFIGURACION GUARDADA EXITOSAMENTE', { type: toast.TYPE.SUCCESS })
        })
    }
}
export function ConConfig(config: string) {
    return new ConfigConeccion(config);
}