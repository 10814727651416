
import { CardConfig, RowConfig, TitleCard } from '@victorequena22/component-bootstrap';
import { fontsFamily } from "@victorequena22/slate-bootstrap/dist/cjs/FontsFamily";
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { ButtonColor } from '../../../../../Panel/Component';
interface Props {
    config: any;
    setConfig: (d: any) => void
}
export default function Formulario({ config, setConfig }: Props) {
    const { colorFF, colorFC, colorBC, colorTC, colorBT, fontC, fontB, online } = config;
    return <CardConfig width='377px' >
        <RowConfig>
            <Title title='COLORES DE FONDO' width='260px'>
                <ButtonColor gradiend disableAlpha tip='FORMULARIO' invert
                    color={colorFF} setData={colorFF => setConfig({ colorFF })} >
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <ButtonColor gradiend disableAlpha tip='CAMPO DE TEXTO' invert
                    color={colorFC} setData={colorFC => setConfig({ colorFC })} >
                    <i className="fas fa-bars"></i>
                </ButtonColor>
                <ButtonColor gradiend disableAlpha tip='BOTON' invert
                    color={colorBC} setData={colorBC => setConfig({ colorBC })} >
                    <i className="fas fa-paper-plane"></i>
                </ButtonColor>
            </Title>
            <BC online={online} action={() => setConfig({ online: !online })} />
        </RowConfig>
        <RowConfig top={2}>
            <Title title='FUENTE DEL CAMPO TEXTO' width='370px'>
                <ButtonColor disableAlpha tip='COLOR' color={colorTC}
                    setData={colorTC => setConfig({ colorTC })}>
                    <i className="fas fa-font"></i>
                </ButtonColor>
                <FontsFamily fontFamily={fontC} setData={fontC => setConfig({ fontC })} />
            </Title>
        </RowConfig>
        <RowConfig top={2}>
            <Title title='FUENTE DEL BOTON' width='370px'>
                <ButtonColor disableAlpha tip='COLOR' color={colorBT}
                    setData={colorBT => setConfig({ colorBT })}>
                    <i className="fas fa-font"></i>
                </ButtonColor>
                <FontsFamily fontFamily={fontB} setData={fontB => setConfig({ fontB })} />
            </Title>
        </RowConfig>
        <TitleCard>CONFIGURACION DEL FORMULARIO</TitleCard>
    </CardConfig>
}
interface P {
    title: string,
    children: any,
    width: string
}
function Title({ title, width, children }: P) {
    return <InputGroup className='input-group-sm' style={{ width, height: '24px' }}>
        <InputGroup.Prepend style={{ height: '24px' }}>
            <InputGroup.Text>{title}</InputGroup.Text>
        </InputGroup.Prepend>
        {children}
    </InputGroup>
}
function BC({ action, active }: any) {
    return <Button size='sm' data-place='bottom' data-tip='EL PROGRAMA DE CAPACITACION ES DE MODALIDAD ONLINE' active={active}
        style={{ height: 24, paddingTop: 0 }} onClick={action} variant='outline-dark'>
        ES ONLINE <b> {active ? "SI" : "NO"}</b>
    </Button>
}
interface P2 {
    fontFamily: string,
    setData: (f: string) => void
}
const FontsFamily = ({ fontFamily, setData }: P2) => {
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => setData(e.currentTarget.value);
    return <FormControl data-tip='ESTILO DE FUENTE'
        style={{ fontFamily, height: '24px', borderRadius: '.25rem 0 0 .25rem', padding: '0 0 0 0' }}
        className='form-control-sm' as='select' onChange={set} value={fontFamily}>
        {fontsFamily.map((c, i) => <option style={{ fontFamily: c }} key={`fuente${i}`}>{c}</option>)}
    </FormControl>
}