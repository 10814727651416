import { css } from '@emotion/css';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import { Invert } from './Utils/Invert';
export function Contact({ size, data }: any) {
    const { props: { input, inputText, button, buttonText } } = data;
    return <Invert data={data} size={size}>
        <FormGroup className={getCSS(input, inputText, button, buttonText)}>
            <FormControl style={{ gridArea: 'a' }} placeholder="Ingresa su nombre" />
            <FormControl style={{ gridArea: 'b' }} placeholder="Ingresa su correo" />
            <FormControl style={{ gridArea: 'c' }} placeholder="Ingresa el asunto" />
            <FormControl style={{ gridArea: 'd' }} placeholder="Ingresa su telefono" />
            <FormControl style={{ gridArea: 'e' }} as='textarea' placeholder="Por favor, escriba su mensaje aqui" />
            <Button style={{ gridArea: 'f' }} block>Enviar</Button>
        </FormGroup>
    </Invert>
}
function getCSS(color1: string, color2: string, color3: string, color4: string) {
    return css`
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
    display: grid;
    gap: 0;
    grid-auto-flow: dense;
    grid-template-areas:    "a a b b"
                            "c c d d"
                            "e e e e"
                            "f f f f";
    &> input, &> textarea{
        width: 100% !important;
        border-radius: 0 !important;
        &:first-child{border-radius: .3rem 0 0 0 !important;}
        &:first-child + input{border-radius: 0 .3rem 0 0 !important;}
        ${toCSS(color1, color2)}
    }
    &> button {
        ${toCSS(color3, color4)}
        border: 0 !important;
        border-radius: 0 0 .3rem .3rem !important;
    }
    `;
}
function toCSS(back: string, text: string) {
    return `
    background: ${back} !important;
    color: ${text} !important;
    border: 2px solid ${text} !important;
    &::placeholder{
        opacity: .4 !important;
    }`

}