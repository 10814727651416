import { THFila } from "@victorequena22/tabla-bootstrap"
import { ParticipanteFila } from '../../../Participantes/Models';
interface Props {
    data: ParticipanteFila
    update: () => void
}
export function Fila({ data, update }: Props) {
    const {  nombre, correo, telefono, estatus: e } = data;
    return <tr>
        <THFila width='auto'>{nombre}</THFila>
        <THFila width='300px'>{correo}</THFila>
        <THFila width='200px'>{telefono}</THFila>
        <THFila width='100px'><span className={`badge badge-${e ? 'success' : 'danger'}`}>{e ? 'ACTIVO' : 'INACTIVO'}</span></THFila>
        <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            
        </th>
    </tr >
}