import { CSSProperties } from "react";
import { BockType } from "@victorequena22/slate-bootstrap";
import { BlockElement } from "../../../../../../Panel/ProtoPanes/DefaultData";

export const Element = (p: any) => <List {...p} />
const Block = ({ attributes, children, element }: any) => {
    switch (element.type) {
        case 'heading-one': return <h1 style={getCSS(element)}{...attributes}>{children}</h1>
        case 'heading-two': return <h2 style={getCSS(element)}{...attributes}>{children}</h2>
        case 'heading-tre': return <h3 style={getCSS(element)}{...attributes}>{children}</h3>
        case 'heading-for': return <h4 style={getCSS(element)}{...attributes}>{children}</h4>
        default: return <p style={getCSS(element)}{...attributes}>{children}</p>
    }
}
const List = (p: any) => {
    const { attributes, children, element } = p;
    return <Block attributes={attributes} element={element}>{children}</Block>
}

const getCSS = ({ align, tabs, type, list }: BlockElement): CSSProperties => {
    const font = getFontSize(type);
    const t2 = tabs * 30;
    const t = list === 'paragraph' ? t2 : t2 + font;
    const s = list === 'paragraph' ? {} : {
        display: 'list-item',
        listStyleType: list === 'bulleted-list' ? 'disc' : 'square'
    }
    const width = 1080 - t;
    return {
        float: 'right',
        textAlign: align,
        padding: '5px 0 5px 0',
        fontSize: `${font}px`,
        minHeight: `${font}px`,
        width: `${width}px`,
        margin: '0 0 0 0',
        ...s
    }
}
const getFontSize = (type: BockType) => {
    switch (type) {
        case 'heading-one': return 26;
        case 'heading-two': return 22;
        case 'heading-tre': return 18;
        case 'heading-for': return 14;
        default: return 10;
    }
}
