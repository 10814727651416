import { useContext, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import './Template/ReactToastify.min.css';
import Header from './Template/Header';
import Menu from './Template/Menu';
import { Card, Col } from 'react-bootstrap';
import { Cargando } from '@victorequena22/component-bootstrap';
import Login from './App/Login';
import { SeccionContext, SeccionType } from './Template/Seccion';
import ListUsuario from './App/Usuarios';
import { Media } from './App/Media';
import MasterContacto from './App/Panel/Contact/Contacto';
import MasterCapacitaciones from './App/Capacitacion';
import MasterConfig from './App/Config';
import { Ruta } from './Template/Ruta';
import ColorProvider from './Template/Colores';
import Crear from './App/PanelProxi';
import DolarProvider from './Template/Dolar';
import MenuProvider from './Template/MenuContext';
import ContextProvider from './App/Capacitacion/Context';
import Dasboard from './App/Dashboard';
import UsersProvider from './Template/UserContex';
// const Crear = lazy(() => import('./App/Panel'))
export default function App() {
  const valStorage = localStorage.getItem('valStorage');
  const date = (new Date()).getDate() + '';
  if (valStorage !== date) {
    localStorage.clear();
    localStorage.setItem('valStorage', date);
  }
  const { data: { seccion, cargando } } = useContext(SeccionContext) as SeccionType;
  if (cargando)
    return <Card style={{ height: '99.9999vh', padding: '20% 20% 20% 20%' }}>
      <Cargando />
    </Card>
  if (!seccion) return <Login />
  return <ColorProvider><DolarProvider><Router><MenuProvider><UsersProvider>
    <Header key='headerBar' />
    <Menu key={'MenuAside' + Math.random()} />
    <ContextProvider>
      <section className="content-wrapper" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 57px)' }}>
        <Col md={12} className="mt-3" ><Switch><Suspense fallback={<Cargando />}>
          <Route path={["/programa", "/facilitador", "/evento", "/participante", "/pago", "/aliado"]} component={MasterCapacitaciones} />
          <Ruta permiso='USUARIO' path="/usuarios" component={ListUsuario} />
          <Ruta permiso='PANEL' path="/panel" component={Crear} />
          <Route path="/contacto" component={MasterContacto} />
          <Ruta permiso='MEDIA' path="/media" component={Media} />
          <Route path='/config' component={MasterConfig} />
          <Route path="/" exact component={Dasboard} />
        </Suspense></Switch></Col>
      </section>
    </ContextProvider>
    <ToastContainer position='bottom-right' />
  </UsersProvider></MenuProvider></Router></DolarProvider></ColorProvider>
}