import { Col } from "react-bootstrap";
import CertificadoSelector from "../../../Parametros/Certificado/Selector";
import { getEvento } from "../../index";
interface Props {
    certificado: number;
    setCertificado: (tags: number) => void;
    errors: any
}
export default function Certificados({ certificado, setCertificado, errors }: Props) {
    const { id, estatus } = getEvento();
    if (estatus === 1)
        return <Col>
            <CertificadoSelector evento={id} change={setCertificado} defaultValue={certificado} isInvalid={errors.certificado} />
            {errors.certificado ? <small style={{ color: '#dc3545' }}>{errors.certificado}</small> : <></>}
        </Col>
    return <></>
}