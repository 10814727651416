import { Table } from "react-bootstrap";
import { getEvento } from "../../index";
import Fila from "./Fila";
import { getParticipantes } from "../../../../Context/Participante";

export default function Encuesta() {
    const evento = getEvento();
    const l = getParticipantes();
    const i = evento.encuesta.filter(e => l.some(p => p.id === e.participante));
    return <Table>
        <thead>
            <tr>
                <th>NOMBRE</th>
                <th>CORREO</th>
                <th>TELEFONO</th>
                <th>RESPUESTA</th>
            </tr>
        </thead>
        <tbody>
            {i.map(e => <Fila encuesta={e} />)}
        </tbody>
    </Table>
}