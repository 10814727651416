import { Panel } from './Panel';
import { Config } from './Config';
import { Single, StateS } from '../../ProtoPanes/Single';
import { getText } from '../../ProtoPanes/DefaultData';
export interface S {
    img: string;
    backgroundColor: string;
    fluid: boolean;
}
export type State = StateS & S;
export class ImagenSola extends Single<S> {
    constructor(p: any) {
        super(p);
        this.init({
            img: '/public/panels/default.jpg',
            fluid: true,
            backgroundColor: '#f0f0f0'
        });
    }
    Config = () => <Config save={this.save} setConfig={this.setData} title={this.state.title} setTitle={this.setTitle} config={this.state} />
    Panel = () => <Panel  config={this.state} />

    getDataSave = () => {
        const { fluid, backgroundColor, img, title, html } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ fluid, backgroundColor, img });
        return { title, html: getText(html), data, props, type: 'imagenSola' };
    }
}