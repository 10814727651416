import React from 'react';
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import { defaultBlock, setText, getText } from "@victorequena22/slate-bootstrap";
import SlateProvider from '../../../../../Panel/Component/SlateCustom';
import { Certificados, conCertificados, Props } from '../Model';
import Config from './Config';
import Preview from './Preview';
export interface State extends Props {
    id?: number;
    nombre: string;
    data?: string;
    errors: any;
    Redirect: boolean;
}
export default class FromCertificado extends React.Component<{ data?: Certificados, copi?: Certificados }, State> {
    block = true;
    random = 'false';
    constructor(p: { data?: Certificados }) {
        super(p);
        this.state = {
            Redirect: false,
            id: undefined, data: undefined, nombre: '',
            top: 0, left: 0, right: 0, bottom: 0,
            text: defaultBlock, errors: {}
        };
        setTimeout(() => {
            if (p.data !== undefined) this.init(p.data);

        }, 500);
    }
    componentDidMount() {
        const { data, copi } = this.props;
        if (data) this.init(data);
        if (copi) this.init(copi);
    }
    init(data: Certificados) {
        const { nombre, props: { top, left, right, bottom } } = data;
        const text = setText(data.props.text);
        this.random = 'false' + (Math.random());
        this.setData({ text, nombre, top, left, right, bottom })
    }
    render() {
        const { state: { text, id }, props: { data } } = this;
        if (this.state.Redirect) return <Redirect to='/evento/config/certificado' />
        return <div key={this.random} style={{ height: '100%', maxHeight: 'calc(100vh - 180px)' }}>
            <SlateProvider key={`panel${id}`} value={text} setValue={text => this.setData({ text: getText(text) })}>
                <Config config={this.state} setConfig={this.setState.bind(this)} save={this.save} />
                <Preview config={this.state} edit={data} />
            </SlateProvider>
        </div>
    }
    setData = (d: any) => this.setState(d);
    procesar = true;
    save = async () => {
        if (this.procesar) {
            this.procesar = false;
            let procesar = true;
            const errors: any = {};
            const { text, nombre, top, left, right, bottom, data } = this.state;
            if (nombre === '') {
                procesar = false;
                errors.nombre = 'DEBE COLOCAR UN NOMBRE AL CERTIFICADO';
            }
            if (this.props.data === undefined && data === undefined) {
                procesar = false
                errors.img = 'DEBE SELECCIONAR UNA IMAGEN DE FONDO';
            }
            if (procesar) {
                const posts = { nombre, data: data ? data : '', props: JSON.stringify({ top, left, right, bottom, text }) };
                if (this.props.data === undefined) conCertificados().nuevo(posts, ({ id }) => {
                    this.success();
                });
                else conCertificados().actualizar(this.props.data.id, posts, () => { this.success(); });
            } else {
                this.setData({ errors });
                this.procesar = true;
            }
        } else {
            toast('SUBIENDO DATOS, POR FAVOR ESPERE', { type: toast.TYPE.WARNING });
        }
    }
    success = () => {
        this.procesar = true;
        this.setData({ Redirect: true })
        toast('GUARDADO', { type: toast.TYPE.SUCCESS });
    }
    componentDidUpdate(p: any, { nombre, data, errors: e }: Readonly<State>): void {
        const errors: any = {};
        if (nombre !== this.state.nombre && e.nombre) {
            errors.nombre = undefined;
        }
        if (data !== this.state.data && e.img) {
            errors.img = undefined;
        }
        if (JSON.stringify(errors) !== JSON.stringify(e))
            this.setData({ errors });
    }
}
