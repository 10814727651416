import { useEffect, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { conPago, Pago } from "../../../Pagos/Models";
import { getParticipantes } from "../../../Context/Participante";
import { Asistencia } from "../Ver/Exports";
import ParticipantesExcel from "../../../Participantes/Excel";
import { getEvento } from "../index";
import Template from "../Template";
import Tabla from "./Tabla";
export var setPagosList = () => { }
export default function GestionPagos() {
    const evento = getEvento();
    const participantes = getParticipantes();
    const [pagos, setPagos] = useState<Pago[]>([]);
    setPagosList = () => { conPago().pagosEvento(evento.id, setPagos); };
    useEffect(setPagosList, [])
    const lista = evento.participantes;
    const par = participantes.filter(p => pagos.some(a => a.participante === p.id) || lista.some(e => e.id === p.id));
    return <Template icon="fa fa-tag" title="PAGOS REGISTRADOS">
        <Row>
            <Col className="text-right">
                <ButtonGroup>
                    <Asistencia />
                    <ParticipantesExcel Participantes={par}>
                        EXPORTAR EN EXEL
                    </ParticipantesExcel>
                </ButtonGroup>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col>
                <b className="text-danger"> PARTICIPATES NO ESTAN EN EL EVENTO:</b> {par.filter(p => lista.every(e => e.id !== p.id)).length}
            </Col>
            <Col>
                <b className="text-warning">PARTICIPATES PREINSCRITOS:</b>  {lista.filter(p => p.status === 1).length}
            </Col>
            <Col>
                <b className="text-success">PARTICIPATES INSCRITOS:</b>  {lista.filter(p => p.status > 1 || p.status === 0).length}
            </Col>
        </Row>
        <Row className="mt-2">
            <Tabla itens={par} pagos={pagos} />
        </Row>
    </Template>
}


