import { Panel } from './Panel';
import { Config } from './Config';
import { Single, StateS } from '../../ProtoPanes/Single';
import { ConConfig } from '../../../../Config/Config';
import SlateProvider from '../../Component/SlateCustom'
import { SizeViewConteiner } from '../../Component';
import { defaultText, getText, setText } from '../../ProtoPanes/DefaultData';
import ReactTooltip from 'react-tooltip';
export interface S {
    img: string;
    backgroundColor: string;
    left: any[]
    center: any[]
    right: any[]
    edit: string
    imgPosicion: number
}
export type State = StateS & S;
export class Footer extends Single<S> {
    randon = Math.random();
    constructor(p: any) {
        super(p);
        this.init({
            img: '', backgroundColor: '#f0f0f0', edit: 'left', imgPosicion: 1,
            left: defaultText, center: defaultText, right: defaultText,
        });
    }
    componentDidMount(): void {
        ConConfig('footer').pull(d => {
            this.randon = Math.random();
            if (d !== null && JSON.stringify(d) !== '[]') this.setData(d);
            else ConConfig('empresa').pull(({ logo }) => this.setData({ img: '/public/imagenes/' + logo }));
        });
    }
    render() {
        const { html, id } = this.getText();
        return <div style={{ height: '100%', maxHeight: 'calc(100vh - 180px)' }}>
            <SlateProvider key={`stale${id}`} value={html} setValue={text => this.setText({ text })}>
                <ReactTooltip />
                <Config save={this.guardar} setConfig={this.setData} config={this.state} />
                <SizeViewConteiner size={this.state.size}>
                    <Panel key='footerPreviewEdit' config={this.state} setData={this.setData} />
                </SizeViewConteiner>
            </SlateProvider>
        </div>
    }
    getText = (): any => {
        const { edit, left, right, center } = this.state;
        switch (edit) {
            case 'left': return { html: setText(left), id: 'left' + this.randon };
            case 'right': return { html: setText(right), id: 'right' + this.randon };
            default: return { html: setText(center), id: 'center' + this.randon };
        }
    }
    setText = (p: { text: any[] }) => {
        const { edit } = this.state;
        switch (edit) {
            case 'left': this.setData({ left: getText(p.text) }); break;
            case 'right': this.setData({ right: getText(p.text) }); break;
            default: this.setData({ center: getText(p.text) }); break;
        }
    }
    guardar = () => {
        ConConfig('footer').push({
            ...this.state,
            left: getText(this.state.left),
            right: getText(this.state.right),
            center: getText(this.state.center)
        }, this.setState.bind(this));
    }
}