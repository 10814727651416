import { numberFormat } from '@victorequena22/utiles';
import { useContext, useState, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Dolar from './DolarData';
import { DolarContext, DolarType } from './Dolar';

export default function Tasa() {
    const [open, setOpen] = useState(false);
    const { tasa } = useContext(DolarContext) as DolarType;
    return <li className="nav-item">
        <ModalTasa open={open} close={() => setOpen(false)} />
        {useMemo(() => <Button variant="success" className='mr-2'
            style={{ border: '1px solid #fff' }} onClick={() => setOpen(true)}>
            TASA ACTUAL: <b>{numberFormat(tasa)}</b>
        </Button>, [tasa])}
    </li>
}

function ModalTasa({ open, close }: { open: boolean, close: () => void }) {
    return <Modal size='xl' show={open} onHide={close} centered className=' modal-warning' >
        <Modal.Header closeButton>
            <h4 className="m-0 font-weight-bold mr-auto align-self-center">
                <span><b>GRAFICA DEL DOLAR</b></span>
            </h4>
        </Modal.Header>
        <Modal.Body >
            <Dolar />
        </Modal.Body>
    </Modal>
}