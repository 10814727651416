import { Ver } from './Ver';
import ShortsNuevo from '../Panel/Shorts/Nuevo';
import { Route } from "react-router-dom";
import Selector from './Ver/Selector';
import ListShorts from '../Panel/Shorts';
import PanelProvider from '../Panel/Context/Panel';
import ShortProvider from '../Panel/Context/Shorts';
import { Edit } from '../Panel/Shorts/Nuevo/Edit';
import { EditarPanel } from './Ver/Editar';
import Crear from '../Panel/Ver/Crear';
import PageProvider from './PageContext';
import { Eventos } from './Custom/Eventos';
import { Programas } from './Custom/Programas';
export default function Panels() {
    return <PageProvider><PanelProvider><ShortProvider>
        <Route path="/panel/edit/:id" exact><EditarPanel key={`panel${Math.random()}`} /></Route>
        <Route path="/panel/:type" exact><Crear /></Route>
        <Route path="/panel/eventos" exact><Eventos /></Route>
        <Route path="/panel/programas" exact><Programas /></Route>
        <Route path="/panel/crear" exact><Selector /></Route>
        <Route path="/panel/short" exact><ListShorts /></Route>
        <Route path="/panel/short/nueva" exact><ShortsNuevo /></Route>
        <Route path="/panel/short/edit/:id" exact component={Edit} />
        <Route path="/panel" exact component={Ver} />
    </ShortProvider></PanelProvider></PageProvider>
}