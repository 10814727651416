import { Button, Row, Col, Card } from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { CapacitacionNull } from '../../Models';
export default class Nuevo extends Prototipo<any> {
    constructor(p: any) {
        super(p)
        this.state = { ...CapacitacionNull, Redirect: false };
    }
    render = () => {
        let { setData, error, state, create } = this;
        if (state.Redirect) return <Redirect to={'/programa'} />
        return (<Card>
            <Card.Header className='bg-info'>
                <Row>
                    <Link to="/programa" className="btn btn-primary mr-2">
                        <span className="fa fa-arrow-circle-left"></span> VOLVER
                    </Link>
                    <h4 className='mr-auto'> NUEVO PROGRAMA </h4>
                </Row>
            </Card.Header>
            <Card.Body style={{ minHeight: 'calc(100vh - 210px)' }}>
                <Form data={state} setData={setData as any} error={error}>
                    <Row className='mt-2'><Col xs={12}>
                        <Button variant='success' className='float-right' onClick={create}>
                            <i className="fa fa-save"></i> Guardar
                        </Button>
                    </Col></Row>
                </Form>
            </Card.Body>
        </Card >)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                this.con.nuevo(this.getDataSave(), () => {
                    this.procesar = true;
                    toast('PROGRAMA CREADO', { type: toast.TYPE.SUCCESS });
                    this.setState({ Redirect: true });
                }, this.setError)
            }
        } else this.forceUpdate();
    }
}