import { Conexion, setData } from '../../../../Config/Coneccion';
export interface Precios {
    id: number;
    evento: number;
    nombre: string;
    inicio: string;
    final: string;
    precio: number;
    estatus: number;
}

class PreciosConeccion extends Conexion {
    clase = 'Evento/Precios';
    async listaEvento(evento: number, setData: setData) {
        this.get('/api/Evento/Precios/lista2/' + evento, setData);
    }
    async delete(id: number, setData: setData) {
        this.get('/api/Evento/Precios/delete/' + id, setData);
    }
    async statusChage(id: number, estatus: number, setData: setData) {
        this.post('/api/Evento/Precios/estatusChage/' + id, { estatus }, setData);
    }

}

export function conPrecios() {
    return new PreciosConeccion();

}