import { CSSProperties } from "react";

export const Leaf = ({ attributes, children, leaf }: any) => {
    const { color, fontFamily, fontSize, bold, italic, underline, backgroundColor } = leaf;
    const font = fontSize ? `${fontSize / 4}px` : '5px'
    const style: CSSProperties = {
        color,
        backgroundColor: backgroundColor ? backgroundColor : '#00000000',
        borderRadius: font,
        paddingLeft: backgroundColor ? font : undefined,
        paddingRight: backgroundColor ? font : undefined,
        fontFamily: fontFamily ? fontFamily : 'FormaDJRTextRegularTesting',
        fontSize: fontSize ? `${fontSize}px` : undefined,
        minHeight: fontSize ? `${fontSize * 1.5}px` : undefined,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textDecoration: underline ? 'underline' : 'none',
    };
    return <span style={style} {...attributes}>{children}</span>
}