import React from "react"
import Chart from "react-google-charts"
import { Row } from "react-bootstrap";
import TortaNull from "../../../Generator/Charts/TortaDefault";
import { PieProps } from "../../../Generator/Charts/Torta";

export interface rank {
    cantidad: number,
    estatus: number
}
export default class Pais extends React.Component<{}, PieProps>{
    constructor(p: any) {
        super(p);
        this.state = { title: '', values: [] };
    }
    render() {
        const { title, values } = this.state;
        return <Row>
            <div style={{ textAlign: 'center' }}>
                {(values.length > 0) ? <Chart
                    width='25rem'
                    height='15rem'
                    chartType="PieChart"
                    loader={<TortaNull />}
                    data={[['PAIS', 'CANT'], ...values]}
                    options={{ title }}
                    rootProps={{ 'data-testid': '3' }}
                /> : <TortaNull />}
                <h5>PAISES</h5>
            </div>
        </Row>
    }
    componentDidMount() {
        this.send()
    }
    async send() {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/Web/Metricas/pais', { headers: { 'Authorization': 'Bearer ' + token } });
            let values = await response.json();
            this.setState({ values });
        } catch (error) {
            console.log(error);
            this.setState({ values: [] });
        }
    }
}