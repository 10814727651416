import { Categoria } from "../../../Categorias/Models";
import keys from "./Keys";
class keyCategoria extends keys {
    c: Categoria;
    constructor(c: Categoria) {
        super();
        this.c = c;
    }
    getKeys() {
        this.addKeyToString('categoria_codigo', 'EL CODIGO DE LA CATEGORIA', this.c.codigo);
        this.addKeyToString('categoria_nombre', 'EL NOMBRE DE LA CATEGORIA', this.c.nombre);
        return this.keys;
    }
}
export function getKeyCategoria(c: Categoria) {
    return new keyCategoria(c);
}