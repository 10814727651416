import { Row } from "react-bootstrap";
import Seled from "../../../../Panel/Utiles/SelectedCreatePAnel";
import { getPrograma } from "../index";
import Template from "../Template";



export default function MenuCreacion() {
    const { id } = getPrograma();
    return <Template icon="fas fa-browser" title="CREAR PANEL PARA EL PROGRAMA">
        <Row><h3 className='ml-2 text-info'>Paneles por defecto</h3></Row>
        <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
            <Seled to={`/programa/landing/${id}/crear/carrusel`} src="/static/panels/var/1.jpg" title="CARRUSEL BANNER" />
            <Seled to={`/programa/landing/${id}/crear/paralax1`} src="/static/panels/pax/3.jpg" title="PARALAX A" />
            <Seled to={`/programa/landing/${id}/crear/paralax2`} src="/static/panels/pax/1.jpg" title="PARALAX B" />
            <Seled to={`/programa/landing/${id}/crear/titulo`} src="/static/panels/pax/9.jpg" title="TITULO" />
            <Seled to={`/programa/landing/${id}/crear/video`} src="/static/panels/gal/5.jpg" title="VIDEO" />
            <Seled to={`/programa/landing/${id}/crear/imagen`} src="/static/panels/gal/5.jpg" title="IMAGEN" />
            <Seled to={`/programa/landing/${id}/crear/imagenSola`} src="/static/panels/default.jpg" title="IMAGEN SOLA" />
            <Seled to={`/programa/landing/${id}/crear/galeria`} src="/static/panels/gal/1.jpg" title="GALERIA" />
            <Seled to={`/programa/landing/${id}/crear/cargos`} src="/static/panels/var/4.jpg" title="CARRUSEL CARTA" />
            <Seled to={`/programa/landing/${id}/crear/servicio`} src="/static/panels/ser/1.jpg" title="CARTA CON ICONO A" />
            <Seled to={`/programa/landing/${id}/crear/servicio2`} src="/static/panels/ser/5.jpg" title="CARTA CON ICONO B" />
            <Seled to={`/programa/landing/${id}/crear/preguntas`} src="/static/panels/var/5.jpg" title="PREGUNTAS" />
            <Seled to={`/programa/landing/${id}/crear/testimonio`} src="/static/panels/var/testimonio.jpg" title="TESTIMONIOS" />
        </Row><hr />
        <Row><h3 className='ml-2 text-info'>Mis Paneles</h3></Row>
        <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
            <Seled to={`/programa/landing/${id}/crear/eventos`} src="/static/panels/var/4.jpg" title="EVENTOS" />
            <Seled to={`/programa/landing/${id}/crear/programas`} src="/static/panels/var/4.jpg" title="PROGRAMAS" />
            <Seled to={`/programa/landing/${id}/crear/suscribirse`} src="/static/panels/var/sub2.png" title="SUSCRIBIRSE" />
        </Row>
    </Template>
}