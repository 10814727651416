import { fechaToNumber, formatoFecha, formatoHora } from "@victorequena22/utiles";
import { InputNumber } from "@victorequena22/component-bootstrap";
import { useCallback, useEffect, useState } from "react"
import { Button, InputGroup } from "react-bootstrap";
import Area from "../Generator/Charts/Area";
import { ConConfig } from "../Config/Config";
import { toast } from "react-toastify";
import { permisoSome } from "./Seccion";
type dolar = { fecha: string, valor: number };

export default function Dolar() {
    const p = permisoSome(['DOLARES']);
    const [random, setRandom] = useState(Math.random());
    const [valor, setValor] = useState(Math.random());
    const [fecha, setFecha] = useState('');
    const [dolares, setDolares] = useState<dolar[]>([]);
    const [disable, setDisable] = useState(true);
    const send = useCallback(() => {
        let valor = 0, fecha = '1999-01-01';
        setDisable(false);
        ConConfig('').get('/api/Dolares/formatter/last', (dolares: dolar[]) => {
            dolares.forEach(d => {
                if (fechaToNumber(d.fecha) > fechaToNumber(fecha)) {
                    valor = d.valor; fecha = d.fecha;
                }
            })
            setRandom(Math.random());
            setDolares(dolares);
            setValor(valor);
            setFecha(fecha);
            toast('LA TASA ACTUALIZADO', { type: toast.TYPE.SUCCESS });
        }, error => {
            console.log(error);
            setDolares([]);
        });
    }, [])
    const actualizar = useCallback(() => {
        if (valor !== 0) {
            setDisable(true);
            ConConfig('').post('/api/Dolares/set', { valor }, send);
        }
        else toast('LA TASA DE CAMBIO NO PUEDE SER 0', { type: toast.TYPE.ERROR });
        send();
    }, [valor, send])
    const disabled = useCallback(() => {
        if (disable) return true;
        return !p;
    }, [disable, p])
    useEffect(() => send(), [send])
    return <div className="Dolar">
        <div style={{ display: "flex" }}>
            <InputGroup style={{ width: "calc(100% - 250px)" }}>
                <InputGroup.Prepend>
                    <InputGroup.Text>TASA DE CAMBIO</InputGroup.Text>
                </InputGroup.Prepend>
                <InputNumber key={`valor${random}}`} min={0} value={valor} setData={setValor} disabled={disabled()} />
                {p ? <InputGroup.Append>
                    <Button variant='primary' onClick={actualizar}>
                        <i className="fa fa-refresh"></i> ACTUALIZAR
                    </Button>
                </InputGroup.Append> : <></>}
            </InputGroup>
            <div style={{ fontSize: '12px', width: "250px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> {formatoFecha(fecha)}<br />
                <strong>HORA:</strong> {formatoHora(fecha)}
            </div>
        </div>
        <Area key={`dolar${random}`} values={dolares.map(d => [formatoFecha(d.fecha) + ' ' + formatoHora(d.fecha), d.valor])}
            label={['FECHA Y HORA', 'PRECIO']}
            title="FLUCTUACIONES DEL DOLAR" hAxisTitle="FECHA Y HORA" />
    </div>
}