import { useEffect, useState } from 'react'
import { conPanel } from '../../Panel/Models';
import { useParams } from "react-router-dom";
import Editar from '../../Panel/Ver/Editar';
import { Eventos } from '../Custom/Eventos';
import { Programas } from '../Custom/Programas';
export function EditarPanel() {
    let { id } = useParams<{ id: any }>();
    const [panel, setPanel] = useState<any>([])
    useEffect(() => { conPanel().detalle(id, setPanel); }, [id]) 
    switch (panel.type) {
        case 'eventos': return <Eventos key={'panel' + id} data={panel} />
        case 'programas': return <Programas key={'panel' + id} data={panel} />
        default: return <Editar key={'panel' + id} panel={panel} />
    }
} 