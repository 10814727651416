import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { ConConfig } from '../../../Config/Config';
import Formulario from './Formulario';
export default class Reporte extends React.Component<any, {
    margen_sup: number,
    margen_inf: number,
    margen_izq: number,
    margen_der: number,
    version: string,
    tipo_papel: string,
    encabezado: boolean,
    piepagina: boolean
}> {
    con = ConConfig('reporte');
    state = {
        margen_sup: 15,
        margen_inf: 15,
        margen_izq: 15,
        margen_der: 15,
        version: 'nuevo',
        encabezado: true,
        piepagina: true,
        tipo_papel: 'LETTER'
    };
    render() {
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE REPORTES </h4>
            </Card.Header>
            <Card.Body>
                <Formulario version='reporte' data={this.state} setData={setState} />
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}