import React from 'react';
import { Button, ButtonGroup, Col, InputGroup, Card, FormGroup, Row } from 'react-bootstrap';
import { ControlNumber } from "@victorequena22/component-bootstrap";
import { ConConfig } from '../../../Config/Config';
interface Props {
    data: any;
    version: string
    setData: (d: any) => void
}
export default class Formulario extends React.Component<Props, { verciones: { value: 1, nombre: string }[] }> {
    state: { verciones: { value: 1, nombre: string }[] } = { verciones: [] };
    render() {
        let { data: { margen_sup, margen_inf, margen_izq, margen_der, tipo_papel, encabezado, piepagina, version }, setData } = this.props;
        return (<Card>
            <Card.Header className='bg-info'>
                <h5>CONFIGURACION DE IMPRESIÓN </h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col as={FormGroup} xs={12}>
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>TIPO DE PAGINA</InputGroup.Text></InputGroup.Prepend>
                            <select onChange={this.setTipoPapel.bind(this)} className="form-control" value={tipo_papel}>
                                <option value="LETTER">CARTA</option>
                                <option value="LEGAL">OFICIO</option>
                            </select>
                        </InputGroup>
                    </Col>
                    {(this.state.verciones.length > 0) ? <Col xs={12} as={FormGroup}>
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <span style={{ width: '100px', position: 'relative', backgroundColor: "#eee" }} className="form-control">VERSION</span>
                            <select style={{ width: 'calc(100% - 100px)', position: 'relative' }} onChange={this.setVersion.bind(this)}
                                className="form-control" value={version}>
                                {this.state.verciones.map(v => {
                                    return <option value={v.value}>{v.nombre}</option>
                                })}
                            </select>
                        </InputGroup>
                    </Col> : <></>}
                    <Col md={12} as={FormGroup}>
                        <ButtonGroup style={{ width: '100%' }}>
                            <Button style={{ width: '50%' }} onClick={() => { setData({ encabezado: !encabezado }) }} variant={encabezado ? 'success' : 'danger'}>
                                COLOCAR ENCABEZADO: {encabezado ? 'SI' : 'NO'}
                            </Button>
                            <Button style={{ width: '50%' }} onClick={() => { setData({ piepagina: !piepagina }) }} variant={piepagina ? 'success' : 'danger'}>
                                COLOCAR PIE DE PAGINA: {piepagina ? 'SI' : 'NO'}
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col md={6} as={FormGroup}>
                        <ControlNumber min={1} step="1" before="MARGEN SUPERIOR" value={margen_sup} setData={setData} update="margen_sup" />
                    </Col>
                    <Col md={6} as={FormGroup}>
                        <ControlNumber min={1} step="1" before="MARGEN INFERIOR" value={margen_inf} setData={setData} update="margen_inf" />
                    </Col>
                    <Col md={6} as={FormGroup}>
                        <ControlNumber min={1} step="1" before="MARGEN IZQUIERDO" value={margen_izq} setData={setData} update="margen_izq" />
                    </Col>
                    <Col md={6} as={FormGroup}>
                        <ControlNumber min={1} step="1" before="MARGEN DERECHO" value={margen_der} setData={setData} update="margen_der" />
                    </Col>
                </Row>
            </Card.Body>
        </Card>)
    }
    setTipoPapel(e: React.ChangeEvent<HTMLSelectElement>) {
        let value = e.currentTarget.value as string;
        this.props.setData({ tipo_papel: value });
    }
    setVersion(e: React.ChangeEvent<HTMLSelectElement>) {
        let value = e.currentTarget.value as string;
        this.props.setData({ version: value });
    }
    componentDidMount() {
        ConConfig('versiones').pull(data => {
            this.setState({ verciones: data[this.props.version] })
            this.forceUpdate()
        })
    }
}