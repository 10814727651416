import { Capacitacion } from "../../../Models"
import keys from "./Keys";
class keyPrograma extends keys {
    c: Capacitacion;
    constructor(c: Capacitacion) {
        super();
        this.c = c;
    }
    getKeys() {
        this.addKey('programa_horas', 'LAS HORAS ACADEMICAS', this.c.horas);
        this.addKeyToString('programa_codigo', 'EL CODIGO DEL PROGRAMA', this.c.codigo);
        this.addKeyToString('programa_nombre', 'EL NOMBRE DEL PROGRAMA', this.c.nombre);
        this.addKeyToString('programa_descripcion', 'EL DESCRIPCION DEL PROGRAMA', this.c.descripcion);
        this.addKeyToString('programa_resumen', 'EL RESUMEN DEL PROGRAMA', this.getBlock(this.c.resumen));
        this.addKeyToString('programa_contenido', 'EL CONTENIDO DEL PROGRAMA', this.getBlock(this.c.contenido));
        colores.map(c => this.addKey(`suscribirse_${c}`, 'BOTON QUE LLEVA A EL FORMULARIO', getSuscribir(c)))
        colores.map(c => this.addKey(`registrare_${c}`, 'BOTON QUE LLEVA A EL FORMULARIO', getRedistrate(c)))
        return this.keys;
    }
}
export function getKeyPrograma(c: Capacitacion) {
    return new keyPrograma(c);
}
type color = 'azul' | 'naranja' | 'maron' | 'dorado' | 'blanco' | 'GClaro' | 'GOscuro';
const colores: color[] = ['azul', 'naranja', 'maron', 'dorado', 'blanco', 'GClaro', 'GOscuro'];
function getSuscribir(s: color) {
    return `<a class="btn btn-suscribir ${s}" href="#Suscribir">
 <b>Completa el formulario</b> <i class="fas fa-chevron-right ml-5"></i>
 </a>`
}
function getRedistrate(s: color) {
    return `<a class="btn btn-suscribir ${s}" href="#Suscribir">
 <b>Regístrate ahora</b> <i class="fas fa-chevron-right ml-5"></i>
 </a>`
}