
import { Button, Row, Col, Card } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Form, Prototipo } from './Form';
import { toast } from 'react-toastify';
import { facilitadorNull } from '../../Models';
export default class Nuevo extends Prototipo<any> {
    constructor(p: any) {
        super(p)
        this.state = { ...facilitadorNull, Redirect: false };
    }
    render = () => {
        let { setData, error, state, create } = this;
        if (state.Redirect) return <Redirect to={'/facilitador'} />
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVO FACILITADOR </h4>
            </Card.Header>
            <Form data={state} setData={setData as any} error={error}>
                <Row className='mt-2'><Col xs={12}>
                    <Button variant='success' className='float-right' onClick={create}>
                        <i className="fa fa-save"></i> Guardar
                    </Button>
                </Col></Row>
            </Form>
        </Card >)
    }
    create = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                this.con.nuevo(this.getDataSave(), () => {
                    this.procesar = true;
                    toast('PERFIL CREADO', { type: toast.TYPE.SUCCESS });
                    this.setState({ Redirect: true });
                }, this.setError)
            }
        } else this.forceUpdate();
    }
}