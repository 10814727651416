import { Row, Tab, Tabs } from "react-bootstrap";
import Encuesta from "./Encuesta";
import Subcriptores from "./Subcriptores";
import Interesados from "./Interesados";
import Graficos from "./Graficos";
import Template from "../Template";
import InteresadosEvento from "./InteresadosEvento";
import SubcriptoresEvento from "./SubcriptoresEvento";
export default function Estadisticas() {
    return <Template icon="fa fa-chart-bar" title="ESTADISTICAS DEL EVENTO">
        <Tabs defaultActiveKey="graficos" id="Participantes" className='taps-Participantes'>
            <Tab eventKey="graficos" title="GRAFICOS">
                <Row className="p-3"><Graficos /></Row>
            </Tab>
            <Tab eventKey="encuesta" title="ENCUESTA">
                <Row className="p-3"><Encuesta /></Row>
            </Tab>
            <Tab eventKey="interesados" title="REGISTRADOS">
                <Row className="p-3"><InteresadosEvento /></Row>
            </Tab>
            <Tab eventKey="suscriptores" title="INTERESADOS">
                <Row className="p-3"><SubcriptoresEvento /></Row>
            </Tab>
            <Tab eventKey="regp" title="REGISTRADOS PROGRAMA">
                <Row className="p-3"><Interesados /></Row>
            </Tab>
            <Tab eventKey="intp" title="INTERESADOS PROGRAMA">
                <Row className="p-3"><Subcriptores /></Row>
            </Tab>
        </Tabs>
    </Template>
}