import { ChangeEvent, useEffect, useState, useCallback, useMemo } from 'react';
import { FormControl } from 'react-bootstrap';
import { CountryList, dataContry } from '../../Config/countrystatecity';

interface Props {
    change: (r: string, phone?: number) => void,
    defaultValue: string,
    width?: string
    isInvalid?: boolean
    online?: boolean
}
export function ContrySelector({ change, defaultValue, width, isInvalid, online }: Props) {
    const [dataList, setData] = useState<CountryList[]>([]);
    const set = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const contry = dataList.find(d => d.iso2 === e.currentTarget.value)
        if (contry !== undefined) { change(contry.iso2, contry.phone); }
        else { change(e.currentTarget.value); }
    }, [change, dataList]);
    const cargar = useCallback(async () => {
        const data = await dataContry().getCountryList();
        if (data && data !== null) setData(data);
    }, [])
    // eslint-disable-next-line
    useEffect(() => { cargar() }, []);
    return useMemo(() => <FormControl as='select' style={{ width }} onChange={set} value={defaultValue} isInvalid={isInvalid}>
        <option value=''>SELECIONAR PAIS</option>
        {online ? <option value='ONLI'>ONLINE</option> : <></>}
        {dataList.map(p => <option key={p.iso2} value={p.iso2}>{p.name}</option>)}
    </FormControl>, [defaultValue, dataList, width, isInvalid, set, online]);
}