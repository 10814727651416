import { getEvento } from "../../index";
import Torta from "../../../../../../Generator/Charts/Torta";
import { useMemo } from "react";
import { getParticipantes } from "../../../../Context/Participante";
export function Ciudad() {
    const participantes = getParticipantes();
    const lista = getEvento().participantes;
    const par = useMemo(() => participantes.filter(p => lista.some(e => e.id === p.id)), [participantes, lista])
    const values = useMemo(() => {
        const values: [string, number][] = [];
        par.forEach(e => {
            const ciudad = e.ciudad.trimStart().trimEnd().toUpperCase();
            const i = values.findIndex(x => x[0] === ciudad);
            if (i > -1) values[i][1]++;
            else values.push([ciudad, 1]);
        })
        return values;
    }, [par])

    return <Torta legend='' size='30rem' title={'CIUDAD'} values={values} />
}
