import React from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import { InputNumber, InputText } from "@victorequena22/component-bootstrap";
import { ButtonConfig } from './Buttons';


export function Title({ setData, title, width = '355px' }: { setData: (d: string) => void, title: string, width?: string }) {
    return <InputGroup data-tip='NOMBRE CON EL QUE SALDRA EN EL MENU' className='input-group-sm' style={{ width, height: '24px' }}>
        <InputGroup.Prepend style={{ height: '24px', width: '110px' }}>
            TITULO MENU:
        </InputGroup.Prepend>
        <InputText style={{ height: '24px' }} className='form-control-sm' setData={setData} value={title} />
    </InputGroup>
}
export function SetSlider({ setData, value, max, min }: { setData: (d: number) => void, value: number, max?: number, min?: number }) {
    const setDataSelected = (e: React.ChangeEvent<HTMLSelectElement>) => setData(parseInt(e.currentTarget.value));
    return <InputGroup style={{ height: 24, width: '100%' }}>
        <InputGroup.Prepend style={{ width: 43 }}>
            <InputNumber decimal={0} style={{ height: 24 }} value={value} setData={setData} />
        </InputGroup.Prepend>
        <InputGroup.Append style={{ height: 24, width: 'calc(100% - 43px)' }}>
            <InputGroup.Text style={{ height: 24, width: '100%' }}>
                <FormControl onChange={setDataSelected} value={value}
                    type="range" max={max} min={min} />
            </InputGroup.Text>
        </InputGroup.Append>
    </InputGroup>
}
export function AddItens({ add, tip, className = 'ml-1' }: { add: () => void, tip?: string, className?: string }) {
    return <ButtonConfig tip={tip} action={add}
        className={className} icon='fas fa-plus' />
}