import { Button, Col, Row } from "react-bootstrap";
import { getEvento } from "../../index";
interface Props {
    select: string
    setSelect: (s: string) => void
}
export default function Filtros(p: Props) {
    const { estatus } = getEvento();
    return <Row className='m-3'><Col>
        <BTN option="all" {...p}>TODOS</BTN>
        {estatus === 2 ? <BTN option="eva" {...p}>EVALUAR</BTN> : <></>}
        <BTN option="app" {...p}>APROBADOS</BTN>
        <BTN option="rep" {...p}>REPROBADOS</BTN>
    </Col></Row>
}
interface PropsBTN extends Props {
    option: string
    children: any
}
function BTN({ select, setSelect, option, children }: PropsBTN) {
    const variant = select === option ? 'dark' : 'outline-dark'
    return <Button className="ml-1" variant={variant} onClick={() => setSelect(option)}>
        {children}
    </Button>
}